import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';
import { usePosterSeasonsQuery } from '../../generated/graphql';

interface SeasonSelectProps {
    value: string[]
    onChange: any
    showLabel?: boolean
    width?: number | string
    inputProps?: any

}

const SeasonSelect: FC<SeasonSelectProps> = props => {

    const { value,
        onChange,
        inputProps = { disableUnderline: true },
        showLabel = true,
        width = 200
    } = props


    const { data } = usePosterSeasonsQuery()

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value }, } = event;
        onChange("season", value)
    };

    const findById = (id: string) => {
        return data?.posterSeasons.nodes.find(season => season?.id === id)
    }

    const render = (selected: string[]) => {
        const first = selected[0]
        const fabricType = findById(first)
        return selected.length === 1 ? fabricType?.name : fabricType?.name + '...'
    }

    return (
        <FormControl sx={{ m: 1, width }}>
            {showLabel && <InputLabel>Season</InputLabel>}
            <Select
                multiple
                value={value}
                onChange={handleChange}
                input={<Input {...inputProps} />}
                renderValue={render}
            >
                {data?.posterSeasons?.nodes.map(node => (
                    <MenuItem key={node?.id} value={node?.id}>
                        <Checkbox checked={value.includes(node?.id)} />
                        <ListItemText primary={node?.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SeasonSelect
