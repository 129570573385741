import { FastField } from "formik"
import { FC } from "react"
import FieldText from "../FieldText/FieldText"

interface InputNumberFieldProps {
    name: string
    label?: string
    size?: "small" | "medium"
    sx?: {}
}

const InputNumberField: FC<InputNumberFieldProps> = props => {
    const { name, label = name, size = "medium", sx } = props
    return (
        <FastField
            component={FieldText}
            name={name}
            label={label}
            type="number"
            size={size}
            sx={sx}
        />
    )
}

export default InputNumberField
