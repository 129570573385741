import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Card, Grid, IconButton } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { FC, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSetPasswordMutation } from '../../generated/graphql';
import { useGtXs } from '../../hooks/useGtXs';
import AuthLayout from '../AuthLayout/AuthLayout';
import MobileLayout from '../MobileLayout/MobileLayout';
import styles from './SetPassword.module.scss';

interface SetPasswordValues {
    email: string,
    password: string,
    token: string
}

interface PasswordVisibilityProps {
    fieldType: any
    setFieldType: any
}

const PasswordVisibility: FC<PasswordVisibilityProps> = props => {
    const { fieldType, setFieldType } = props

    const toogle = () => {
        if (fieldType === "password") {
            setFieldType("test")
        } else {
            setFieldType("password")
        }
    }

    return (
        <IconButton onClick={toogle}>
            {fieldType === "password" ? <VisibilityOff /> : <Visibility />}
        </IconButton>
    )
}

const MobileLogin: FC<any> = props => {
    const { handleSubmit, initialValues, errorMessage } = props
    const [fieldType, setFieldType] = useState<string>("password")
    return (
        <MobileLayout>
            <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {({ isSubmitting }) => (
                    <Form style={{ height: "100%" }}>
                        <Grid container direction="column" justifyContent="center" className={styles.MobileLogin}>
                            <div>{errorMessage}</div>
                            <div className={styles.UsernameWrapper}>
                                <Field
                                    component={TextField}
                                    label="Email"
                                    name="email"
                                    required
                                    fullWidth
                                    autoFocus
                                    autoComplete="current-username"
                                />
                            </div>

                            <Grid container direction="column" alignItems="space-around" className={styles.PasswordWrapper}>
                                <div>
                                    <Field
                                        component={TextField}
                                        label="Password"
                                        name="password"
                                        type={fieldType}
                                        required
                                        fullWidth
                                        autoComplete="current-password"
                                        InputProps={{
                                            endAdornment: <PasswordVisibility fieldType={fieldType} setFieldType={setFieldType} />
                                        }}

                                    />
                                </div>
                                <Grid container justifyContent="flex-end" className={styles.ForgotPassword}>
                                    <p><Link to="/forgot-password">Forgot Password?</Link></p>
                                </Grid>
                            </Grid>

                            <Grid container direction="column" alignItems="space-around">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    SET NEW PASSWORD
                                </Button>

                                <Grid container justifyContent="center" className={styles.Registration}>
                                    <p>Need an account? <Link to="/registration">Sign Up</Link></p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

        </MobileLayout>
    )
}

const DesktopLogin: FC<any> = props => {
    const { handleSubmit, initialValues, errorMessage } = props
    const [fieldType, setFieldType] = useState<string>("password")
    return (
        <AuthLayout>
            <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {({ isSubmitting }) => (
                    <Form>
                        <Card className={styles.LoginCard}>
                            <Grid container direction="column" justifyContent="space-around" style={{ height: "100%", padding: 50 }}>
                                <div>{errorMessage}</div>
                                <div>
                                    <Field
                                        component={TextField}
                                        label="Email"
                                        name="email"
                                        required
                                        fullWidth
                                        autoFocus
                                        autoComplete="current-username"
                                    />
                                </div>

                                <Grid container direction="column" alignItems="space-around">
                                    <div>
                                        <Field
                                            component={TextField}
                                            label="Password"
                                            name="password"
                                            type={fieldType}
                                            required
                                            fullWidth
                                            autoComplete="current-password"
                                            InputProps={{
                                                endAdornment: <PasswordVisibility fieldType={fieldType} setFieldType={setFieldType} />
                                            }}
                                        />
                                    </div>
                                </Grid>

                                <Grid container direction="column" alignItems="space-around">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        SET NEW PASSWORD
                                    </Button>
                                </Grid>

                            </Grid>
                        </Card>
                    </Form>
                )}
            </Formik>

        </AuthLayout >
    );
}



const SetPassword = () => {

    const [setPassword] = useSetPasswordMutation()
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate()
    const location = useLocation()
    const initialValues: SetPasswordValues = { email: "", password: "", token: location.search.slice(1) }
    const gtXs = useGtXs()

    const handleSubmit = (values: SetPasswordValues) => {
        setPassword({ variables: { ...values } }).then(response => {
            // só pode retornar string ou dar erro
            if (response.data?.setPassword) {
                navigate("/feedback", { state: { title: "Password Set", message: "Your new password has been set, you can now login.", timeout: 5000 } })
            }
        }).catch(() => setErrorMessage("Password set failed. Please try again."))
    }

    if (gtXs) {
        return <DesktopLogin handleSubmit={handleSubmit} initialValues={initialValues} errorMessage={errorMessage} />
    }

    return <MobileLogin handleSubmit={handleSubmit} initialValues={initialValues} errorMessage={errorMessage} />

};

export default SetPassword;
