import { Close } from '@mui/icons-material';
import { Button, Drawer, Grid, IconButton } from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CompositionSelect from '../CompositionSelect/CompositionSelect';
import FabricTypeSelect from '../FabricTypeSelect/FabricTypeSelect';
import { LayoutContext, useFilterCount } from '../Layout/LayoutProvider';
import SeasonSelect from '../SeasonSelect/SeasonSelect';
import styles from './FiltersDrawer.module.scss';

interface FiltersDrawerProps {
    filters: any
    setFilter: any
    func: any
    count?: number
    viewMeshes: boolean
}

const FiltersDrawer: FC<FiltersDrawerProps> = props => {
    const { setFilter, func, count, viewMeshes, filters: { composition, fabricType, season } } = props
    const { setFilterCount } = useFilterCount()
    const navigate = useNavigate()

    useEffect(() => {
        let count = 0
        if (composition.length) { count++ }
        if (fabricType.length) { count++ }
        if (season.length) { count++ }
        setFilterCount(count)
    }, [count, composition.length, fabricType.length, setFilterCount]);

    const clearFilters = () => {
        const route = viewMeshes ? '/meshes' : '/finishes'
        navigate(route)
    }

    return (
        <LayoutContext.Consumer>
            {value =>
                <Drawer open={value.isOpen} onClose={() => false} anchor="top" className={styles.FiltersDrawer}>
                    <div className={styles.FiltersDrawerContent}>
                        <Grid container className={styles.Wrapper}>
                            <Grid container justifyContent="flex-end">
                                <IconButton onClick={() => value.setIsOpen(false)}>
                                    <Close />
                                </IconButton>
                            </Grid>
                            <Grid container direction="column" className={styles.Filters}>
                                {viewMeshes ? <h3>Fabric Type</h3> : <h3>Finish Type</h3>}


                                <FabricTypeSelect value={fabricType} onChange={setFilter} func={func} inputProps={{ disableUnderline: false }} width="90%" showLabel={false} />
                                {viewMeshes ? <>
                                    <h3>Composition</h3>
                                    <CompositionSelect value={composition} onChange={setFilter} inputProps={{ disableUnderline: false }} width="90%" showLabel={false} />
                                </> : null}

                                <h3>Season</h3>
                                <SeasonSelect value={season} onChange={setFilter} inputProps={{ disableUnderline: false }} width="90%" showLabel={false} />
                            </Grid>
                            <Grid container className={styles.Actions} justifyContent="flex-end">
                                <Button onClick={clearFilters} variant="text" size="small">Clear</Button>
                                <Button onClick={() => value.setIsOpen(false)} variant="contained" size="small">See Results ({count})</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>}
        </LayoutContext.Consumer>
    );
};

export default FiltersDrawer;
