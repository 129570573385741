import { Card, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Config from '../../services/config';
import AuthLayout from '../AuthLayout/AuthLayout';
import styles from './Feedback.module.scss';

const Feedback = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const defaultTitle = "Thank you"
    const defaultMessage = "If your account is associated with this email address you will receive an email to reset your password."

    // @ts-ignore
    const { title = defaultTitle, message = defaultMessage, redirectTo = Config.PROTECT_DEFAULT_REDIRECT_ROUTE, timeout = 0 } = location?.state || {}


    useEffect(() => {
        if (timeout) {
            setTimeout(() => navigate(redirectTo), timeout)
        }
    }, [navigate, redirectTo, timeout]);

    return (
        <AuthLayout>
            <Card className={styles.Feedback}>
                <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                    <h3>{title}</h3>
                    <p>{message}</p>
                </Grid>
            </Card>
        </AuthLayout>
    )
}

export default Feedback;
