import { Fade, Grid } from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrayParam, NumberParam } from 'use-query-params';
import { PosterTypes, usePostersQuery } from '../../generated/graphql';
import { useFilters } from '../../hooks/useFilters';
import { useAuth } from '../../services/auth';
import Config from '../../services/config';
import { EmptyView } from '../Empty/Empty';
import FiltersBar from '../FiltersBar/FiltersBar';
import Layout from '../Layout/Layout';
import Loader from '../Loader/Loader';
import Pagination from '../Pagination/Pagination';
import PosterTile from '../PosterTile/PosterTile';
import styles from './Posters.module.scss';
import { postersQuery } from './PostersQuery';

interface EmptyPostersProps {
    message: string
    loading: boolean
    nodes: any
}

const EmptyPosters: FC<EmptyPostersProps> = props => {
    const { message, nodes, loading } = props
    if (nodes?.length === 0 && !loading) {
        return <EmptyView message={message} />
    }
    return null
}


const Posters = () => {

    const [filters, setFilter] = useFilters([
        "search",
        { name: "page", variant: NumberParam, defaultValue: 1 },
        { name: "fabricType", variant: ArrayParam, defaultValue: [] },
        { name: "composition", variant: ArrayParam, defaultValue: [] },
        { name: "season", variant: ArrayParam, defaultValue: [] },
    ])

    const { logout } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    // @ts-ignore
    const { search, page, fabricType, composition, season } = filters

    const finishings = location.pathname === '/finishes'
    const posterType = finishings ? PosterTypes.Finishing : PosterTypes.Mesh
    const emptyMessage = finishings ? "No finish found matching search criteria." : "No mesh found matching search criteria."

    const { data, loading, error } = usePostersQuery({ ...postersQuery(search, page, fabricType, composition, posterType, season), fetchPolicy: "no-cache" })

    // quando o utilizador chega aqui com um token expirado
    // é preciso redirecionar para login, idealmente com uma mensagem
    // @todo - migrar isto para um local mais adequado
    if (error && error.message === 'Unauthorized') {
        logout().then(() =>
            navigate(Config.START_ROUTE)
        )
    }

    return (
        <Layout>
            <div>
                <FiltersBar setFilter={setFilter} filters={filters} count={data?.posters?.totalCount} />
                <Loader loading={loading} />
                <Fade in={!loading}>

                    <Grid container className={styles.Posters}>
                        <EmptyPosters nodes={data?.posters?.nodes} loading={loading} message={emptyMessage} />
                        {data?.posters.nodes.map(node => <PosterTile key={node.id} poster={node} />)}
                    </Grid>

                </Fade >

                <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                    <Pagination collection={data?.posters} page={page} setPage={setFilter} />
                </div>
            </div >
        </Layout>
    )
}


export default Posters;
