import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styles from './FoursourceHome.module.scss'

const FoursourceHome = () => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/create-quote")
    }

    return (
        <Grid container justifyContent="center" alignContent="center" className={styles.Home}>
            <Card>
                <CardContent style={{ padding: 75 }}>
                    <Grid container direction="column">
                        <div style={{ marginBottom: 50 }}>
                            <Typography variant="h3" align="center">
                                Welcome to our sample request<br /> platform.
                            </Typography>
                        </div>
                        <Button onClick={handleClick} color="primary" variant="contained" size="large">
                            Make a Request
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </Grid >
    )
}

export default FoursourceHome
