import { useApolloClient } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";
import { useMeLazyQuery } from "../generated/graphql";
import Config from "./config";


export interface AuthUser {
    id: string,
    name: string,
    email: string,
    userType: string
}

const authContext = createContext<any>(undefined)

const AuthProvider = ({ children }: { children: any }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// hooks
export const useAuth = () => useContext(authContext)

const useProvideAuth = () => {
    const [user, setUser] = useState<AuthUser | boolean>(false);
    const [getMe, { data }] = useMeLazyQuery()
    const client = useApolloClient();

    const login = async (email: string, password: string) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        };

        return fetch(Config.AUTH_ENDPOINT, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    localStorage.setItem(Config.AUTH_TOKEN_KEY, data.token);
                    getMe()
                    return true
                }
                return false
            })
    };

    const logout = () => {
        localStorage.removeItem(Config.AUTH_TOKEN_KEY);
        setUser(false)
        return client.cache.reset()
    };

    useEffect(() => {
        getMe()

        if (data?.me) {
            setUser({
                id: data.me.id,
                name: data?.me?.name,
                email: data.me.email,
                userType: data?.me?.type?.id
            })
        } else {
            setUser(false)
        }
    }, [data, getMe]);

    return {
        user,
        login,
        logout
    };
}


export default AuthProvider
