import { Grid } from "@mui/material";
import { FC } from "react";
import Header from "../Header/Header";
import { CircloHtmlHead } from "../HtmlHead/HtmlHead";
import { LayoutProps } from "../Layout/Layout";
import LayoutProvider from "../Layout/LayoutProvider";
import styles from './MobileLayout.module.scss';

const MobileLayout: FC<LayoutProps> = props => {
    const { children } = props
    return (
        <LayoutProvider>
            <Grid container direction="column" alignItems="space-between" className={styles.MobileLayout}>
                <CircloHtmlHead />
                <Header />
                {children}
            </Grid>
        </LayoutProvider>
    )
}

export default MobileLayout;
