const Config = {
    // @ts-ignore
    GRAPHQL_API: window._env_ ? window._env_.GRAPHQL_API : '',
    // @ts-ignore
    MEDIA_URL: window._env_ ? window._env_.MEDIA_URL : '',
    JWT_AUTH_HEADER_PREFIX: "Bearer",
    AUTH_TOKEN_KEY: "auth.token",
    START_ROUTE: "/",
    // @ts-ignore
    AUTH_ENDPOINT: window._env_ ? window._env_.GRAPHQL_API + "auth/login/" : '',
    PROTECT_DEFAULT_REDIRECT_ROUTE: "/login",
    PAGE_SIZE: 12,
    FORM_VALIDATION_POLICY: "ALL", // ALL or ONLY_IF_TOUCHED  - REPLACE MAGIC STRING
    PEDROSA_SITE: "http://pedrosapt.com/"
};

export const REQUIRED_FIELD = 'Required Field.'

export const defaultInputSx = () => {
    return { width: 434 }
}


export default Config;
