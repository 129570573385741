import { Grid } from '@mui/material';
import { FC, ReactNode } from 'react';
import pedrosaLogo from '../../assets/imgs/foursource/pedrosa_logo.png';
import { useGtXs } from '../../hooks/useGtXs';
import FoursourceFooter from '../FoursourceFooter/FoursourceFooter';
import { FoursourceHtmlHead } from '../HtmlHead/HtmlHead';
import LinkToSite from '../LinkToSite/LinkToSite';
import styles from './FoursourceLayout.module.scss';

interface FoursouceLayoutProps {
    children: ReactNode
}

const FoursouceLayout: FC<FoursouceLayoutProps> = props => {
    const { children } = props

    const gtXs = useGtXs()

    const span = gtXs ? 8 : 12

    return (
        <Grid container className={styles.FoursouceLayout}>
            <FoursourceHtmlHead />
            {gtXs && <Grid item container xs={4} className={styles.Brand} >
                <Grid container justifyContent="center" alignItems="center">
                    <LinkToSite>
                        <img src={pedrosaLogo} alt="pedrosa logo" />
                    </LinkToSite>
                </Grid>
                <div className={styles.Footer}>
                    <LinkToSite>
                        <span>wwww.pedrosapt.com</span>
                    </LinkToSite>
                </div>
            </Grid>}
            <Grid item container xs={span} >
                <Grid container direction="column" justifyContent="space-between" alignItems="center">
                    <div className={styles.Content}>
                        {children}
                    </div>
                    <FoursourceFooter />
                </Grid>
            </Grid>
        </Grid >
    )
};

export default FoursouceLayout;
