import { Grid } from '@mui/material';
import { Pagination as MuiPagination } from '@mui/material/';
import { FC } from 'react';
import { DecodedValueMap } from 'use-query-params';
import Config from '../../services/config';
import styles from './Pagination.module.scss';

interface PaginationProps {
    collection: any
    page: number
    setPage: DecodedValueMap<{}>
    showFirstButton?: boolean
    showLastButton?: boolean
    color?: 'primary' | 'secondary' | 'standard'
}

export const getPaginationCount = (totalCount?: number) => {
    return totalCount ? Math.ceil(totalCount / Config.PAGE_SIZE) : 1
}

/**
 * A collection tem que implementar uma OffsetPaging paging strategy
 *
 */
const Pagination: FC<PaginationProps> = props => {
    const {
        collection,
        page,
        setPage,
        showFirstButton = false,
        showLastButton = false,
        color = "primary"
    } = props

    if (collection && !collection?.totalCount) {
        console.error("Collection total count property is mandatory.")
        return null
    }

    const count = getPaginationCount(collection?.totalCount)

    const handleChange = (_: any, page: number) => {
        // @ts-ignore
        setPage("page", page)
    }

    if (!collection?.nodes?.length) {
        return null
    }

    return (
        <Grid container justifyContent="center" className={styles.PaginationWrapper}>
            <MuiPagination count={count} page={page} onChange={handleChange} showFirstButton={showFirstButton} showLastButton={showLastButton} color={color} />
        </Grid>
    )
}

export default Pagination
