import { Grid } from '@mui/material';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import styles from './SecondBar.module.scss';

interface SecondBarProps {
    children: ReactNode,
    size?: "REGULAR" | "TALL"
}

const SecondBar: FC<SecondBarProps> = ({ children, size }) => {
    const classes = clsx(styles.SecondBar, size === "TALL" ? styles.Tall : styles.Regular)
    return (
        <Grid container className={classes} alignItems="center">
            {children}
        </Grid >
    )
}

export default SecondBar;
