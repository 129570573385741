import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import logo from '../../assets/imgs/circlo/logo.webp';
import { useGtXs } from '../../hooks/useGtXs';
import { CircloHtmlHead } from '../HtmlHead/HtmlHead';
import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
    children: ReactNode
}

export default function AuthLayout(props: AuthLayoutProps) {
    const { children } = props

    const gtXs = useGtXs()

    const span = gtXs ? 8 : 12

    return (
        <Grid container className={styles.AuthLayout}>
            <CircloHtmlHead />
            {gtXs && <Grid item container xs={4} className={styles.Brand} >
                <Grid container justifyContent="center" alignItems="center">
                    <a href="https://www.circlo.pt" target="_blank" rel="noreferrer">
                        <img src={logo} alt="circlo logo" />
                    </a>
                </Grid>
                <div className={styles.Footer}>
                    <a href="https://www.circlo.pt"
                        style={{ color: "#ffffff" }}
                        target="_blank" rel="noreferrer">
                        www.circlo.pt
                    </a>
                </div>
            </Grid>}
            <Grid item container xs={span}>
                <Grid container justifyContent="center" alignItems="center">
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}
