import { Menu as MenuIcon } from '@mui/icons-material';
import { Drawer, Grid, IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/auth';
import { LayoutContext, useSidemenu } from '../Layout/LayoutProvider';
import styles from './Sidemenu.module.scss';

const Sidemenu = () => {

    const navigate = useNavigate()
    const context = useSidemenu()
    const auth = useAuth()

    const isTechUser = () => {
        return auth?.user?.userType === 'TECH'
    }

    const handle = (route: string) => {
        context.setIsSidemenuOpen(false)
        navigate(route)
    }

    const handleClose = () => {
        context.setIsSidemenuOpen(false)
    }

    return (
        <LayoutContext.Consumer>
            {value =>
                <Drawer open={value.isSidemenuOpen} onClose={handleClose}>
                    <Grid className={styles.SideMenuHeader} container justifyContent="flex-start" alignItems="center">
                        <IconButton onClick={() => value.setIsSidemenuOpen(false)}>
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Grid className={styles.Sidemenu}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handle("/meshes")}>
                                    <ListItemText primary={"Meshes"} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handle("/finishes")}>
                                    <ListItemText primary={"Finishes"} />
                                </ListItemButton>
                            </ListItem>
                            {isTechUser() && <ListItem disablePadding>
                                <ListItemButton onClick={() => handle("/create-user")}>
                                    <ListItemText primary={"Create User"} />
                                </ListItemButton>
                            </ListItem>}
                        </List>
                    </Grid>
                </Drawer>}
        </LayoutContext.Consumer >
    );
};

export default Sidemenu;
