import { FC } from "react"
import CompanyInformation from "../CreateQuote/CompanyInformation/CompanyInformation"
import PersonalInformation from "../CreateQuote/PersonalInformation/PersonalInformation"
import RequestInformation from "../CreateQuote/RequestInformation/RequestInformation"
import styles from './StepContent.module.scss'

interface StepContentProps {
    active: number
}

const StepContent: FC<StepContentProps> = props => {
    const { active } = props

    return (
        <div className={styles.Wrapper}>
            {active === 0 && <PersonalInformation />}
            {active === 1 && <CompanyInformation />}
            {active === 2 && <RequestInformation />}
        </div>
    )
}

export default StepContent
