import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { FC } from 'react';
import styles from './StepIndicator.module.scss'

interface StepIndicatorProps {
    active: number
}

const StepIndicator: FC<StepIndicatorProps> = props => {
    const { active } = props
    return (
        <div className={styles.StepIndicator}>
            <Stepper activeStep={active}>
                <Step key={1}>
                    <StepLabel>Personal Information</StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel>Company Information</StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel>Request Information</StepLabel>
                </Step>
            </Stepper>
        </div>
    )
}

export default StepIndicator
