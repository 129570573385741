interface SingleError {
    field: string
    message: string
}

export const non_field_error = "non_field_error"

// mensagens geradas pelo class validator na api
export const isAlphanumeric = "apenas se pode usar números e letras"
export const isUppercase = "só se pode usar maiúsculas"

export const translate = (message: string) => {
    const translations = {
        "must contain only letters and numbers": isAlphanumeric,
        "must be uppercase": isUppercase,
        'duplicate key value violates unique constraint "api_user_email_key"': "já existe utilizador com este email"
    }
    // @ts-ignore
    return translations[message] || message
}

export const extensionsMessageParser = (extensionsMessage: string) => {
    const elements = extensionsMessage.split(" ")
    const fullField = elements[0]
    const field = fullField.split(".").slice(-1)[0]
    const message = translate(elements.slice(1).join(" "))
    return { field, message }
}


const extractExtsMsgs = (graphQLError: any) => {
    return graphQLError?.extensions?.response?.message
}

const addToErrors = (errors: {}, result: SingleError) => {
    const { field, message } = result
    if (errors.hasOwnProperty(field)) {
        // @ts-ignore
        errors[field].push(message)
    } else {
        // @ts-ignore
        errors[field] = [result.message]
    }
}

const parseGraphQLErrors = (graphQLErrors: any[]) => {
    const errors = {}
    graphQLErrors.forEach((graphQLError: any) => {
        const extensionMessages = extractExtsMsgs(graphQLError)
        // field errors
        if (extensionMessages) {
            extensionMessages.forEach((extensionMessage: any) => {
                const result = extensionsMessageParser(extensionMessage)
                addToErrors(errors, result)
            })
        } else {
            // non field errors
            let message = graphQLError.message
            message = translate(message)
            addToErrors(errors, { field: non_field_error, message })
        }
    })
    return errors
}

// tipologia de erros
// graphqlerror
// networkerror
// só estamos a lidar com graphqlQLErrors de momento
export const errorResponseParser = (response: any) => {
    const { graphQLErrors } = response

    if (graphQLErrors?.length) {
        return parseGraphQLErrors(graphQLErrors)
    }
}
