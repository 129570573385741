import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import gql from 'graphql-tag';
import Config from "./config";

const query = gql`
  query {
      isMenuOpen
  }
`;

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(Config.AUTH_TOKEN_KEY);
    const prefix = Config.JWT_AUTH_HEADER_PREFIX;
    return {
        headers: {
            ...headers,
            'authorization': token ? `${prefix} ${token}` : "",
        }
    };
});

const uploadLink = createUploadLink({
    uri: Config.GRAPHQL_API
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    // @ts-ignore
    link: authLink.concat(uploadLink),
    resolvers: {
        Mutation: {
            loginUser: (_root, variables, { cache }) => {
                const { token } = variables;
                // cache.writeData({ data: { token } });
                localStorage.setItem(Config.AUTH_TOKEN_KEY, token);
                return true
            },
            logoutUser: (_root, _, { cache, }) => {
                localStorage.removeItem(Config.AUTH_TOKEN_KEY);
                cache.reset()
                return null;
            },
            setMenu: (_root, variables, { cache }) => {
                const state = variables.state;;
                cache.writeQuery({ query, data: { isMenuOpen: state } })
            }

        },
    }
});


client.writeQuery({ query, data: { isMenuOpen: false } })


export default client;
