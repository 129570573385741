import { useSnackbar } from "notistack";

const useToast = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const successToast = (message: string = "success") => {
        return enqueueSnackbar(message, { variant: "success" });
    }

    const errorToast = (message: string = "error") => {
        return enqueueSnackbar(message, { variant: "error" });
    }

    const toast = enqueueSnackbar
    const close = closeSnackbar

    return { successToast, errorToast, toast, close }
}

export default useToast
