import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, CircularProgress, Grid } from "@mui/material";
import { useFormikContext } from 'formik';
import { FC } from "react";
import { Quote } from '../../generated/graphql';
import styles from './StepControls.module.scss';

interface StepControlProps {
    active: number
    count: number
    previous: () => void
    next: () => void
}

interface BackButtonProps {
    active: number
    previous: () => void
}

interface NextButtonProps {
    next: () => void
}

const BackButton: FC<BackButtonProps> = props => {
    const { active, previous } = props

    if (active === 0) {
        return <div>&nbsp;</div>
    }

    return (
        <Button onClick={previous} startIcon={<ArrowBackIosIcon />} size="large" className={styles.ControlButton}>
            Back
        </Button>
    )
}

const NextButton: FC<NextButtonProps> = props => {
    const { next } = props
    return (
        <Button onClick={next} endIcon={<ArrowForwardIosIcon />} size="large" className={styles.ControlButton}>
            Next
        </Button>
    )
}

const SubmitButton = () => {
    const context = useFormikContext<Quote>()
    const { isSubmitting } = context

    return !isSubmitting ? (
        <Button variant="contained" size="large" type="submit" disabled={isSubmitting}>
            submit
        </Button>
    ) : <CircularProgress color="primary" />
}

const StepControls: FC<StepControlProps> = props => {

    const { active, count, previous, next } = props

    return (
        <div className={styles.StepControls}>
            <Grid container justifyContent="space-between" className={styles.Wrapper}>
                <BackButton active={active} previous={previous} />
                {active !== count - 1 && <NextButton next={next} />}
                {active === count - 1 && <SubmitButton />}
            </Grid>
        </div>
    )
}

export default StepControls
