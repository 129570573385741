import { Typography } from "@mui/material"
import { defaultInputSx } from "../../../services/config"
import InputTextField from "../../InputTextField/InputTextField"
import formStyles from '../CreateQuote.module.scss'

const PersonalInformation = () => {

    const sx = defaultInputSx()

    return (
        <div className={formStyles.FormStep}>
            <Typography variant="h3" color="primary">
                personal information
            </Typography>
            <div className={formStyles.Form}>
                <div className={formStyles.FormLine}>
                    <InputTextField name="name" autoFocus={true} size="small" sx={sx} />
                </div>
                <div className={formStyles.FormLine}>
                    <InputTextField name="jobTitle" label="job title" size="small" sx={sx} />
                </div>
                <div className={formStyles.FormLine}>
                    <InputTextField name="email" size="small" sx={sx} />
                    <InputTextField name="phone" size="small" sx={sx} />
                </div>
            </div>

        </div >
    )
}

export default PersonalInformation
