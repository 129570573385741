import { Close } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface AutoTextFieldProps {
    style?: object,
    value: any,
    name: any,
    onSave: any,
    interval?: number,
    fullWidth?: boolean,
    clearable?: boolean,
    placeholder: string
    InputProps?: any,
    variant?: 'filled' | 'standard' | 'outlined'

}

const Clearable = ({ onClear }: { onClear: any }) => {
    return (
        <IconButton onClick={onClear}>
            <Close fontSize="small" />
        </IconButton>
    )
}


const AutoTextField: FC<AutoTextFieldProps> = props => {

    const {
        style,
        onSave,
        value,
        name,
        fullWidth,
        clearable,
        InputProps,
        variant = 'outlined',
        interval = 2000,
        ...rest
    } = props

    const [lastData, setLastData] = useState(null);
    const [data, setData] = useState(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const timer = window.setTimeout(() => {
            // @ts-ignore
            if (lastData[name] !== data[name]) {
                onSave(data);
                setLastData(data);
            }
        }, interval);
        return () => window.clearTimeout(timer);
    }, [data]);

    const handleChange = (event: any) => {
        // @ts-ignore
        setData({ [name]: event.target.value });
    };

    const handleClear = () => {
        // @ts-ignore
        setData({ [name]: '' })
    }

    if (!data) {
        // @ts-ignore
        setData({ [name]: value });
        // @ts-ignore
        setLastData({ [name]: value });
    }

    // @ts-ignore
    const v = data && data[name] ? data[name] : ''

    const endAdornment = clearable && value ? <Clearable onClear={handleClear} /> : null

    return (
        <TextField value={v}
            style={style}
            name={name}
            fullWidth={fullWidth}
            variant={variant}
            onChange={handleChange} {...rest}
            InputProps={{
                endAdornment,
                ...InputProps
            }}
        />
    );
};

export default AutoTextField;
