import { Grid } from '@mui/material';
import styles from './Footer.module.scss';

export default function Footer() {
    return (
        <Grid container className={styles.Footer} justifyContent="center" >
            <p>
                <a href="https://www.circlo.pt" style={{ color: "#ffffff" }} target="_blank" rel="noreferrer">www.circlo.pt</a>
            </p>
        </Grid>
    )
}
