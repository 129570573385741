import { Grid } from "@mui/material";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { useSnackbar } from "notistack";
import { useCreateOneUserMutation, User } from "../../generated/graphql";
import { errorResponseParser } from "../../utils/errors";
import InputTextField from "../InputTextField/InputTextField";
import Layout from "../Layout/Layout";
import NonFieldErrors from "../NonFieldErrors/NonFieldErrors";
import SubmitButton from "../SubmitButton/SubmitButton";
import styles from './CreateUser.module.scss';
import formInit from "./CreateUserFormInit";

const CreateUser = () => {

    const [create] = useCreateOneUserMutation()
    const { schema, initial } = formInit()
    const { enqueueSnackbar } = useSnackbar();


    const notify = () => {
        enqueueSnackbar('Utilizador criado.', { variant: "success" });
    }

    const handleSubmit = async (values: FormikValues, helpers: FormikHelpers<Partial<User>>) => {
        const { name, email, password } = values
        create({ variables: { input: { user: { name, email, password, userType: "CLIENT" } } } }).then(response => {
            if (response?.data?.createOneUser?.id) {
                notify()
                helpers.resetForm()
            }
        }).catch(response => {
            // @ts-ignore
            helpers.setErrors(errorResponseParser(response))
        })
    }

    return (
        <Layout>
            <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema}>
                {({ errors }) => {
                    return (
                        <Form className={styles.Form}>
                            <Grid container justifyContent="center">
                                <div className={styles.Wrapper}>
                                    <div>
                                        <h3>Criar Utilizador</h3>
                                    </div>
                                    <NonFieldErrors />
                                    <div className={styles.Field}>
                                        <InputTextField name="name" label="nome" />
                                    </div>
                                    <div>
                                        <InputTextField name="email" label="email" />
                                    </div>
                                    <div>
                                        <InputTextField name="password" label="password" />
                                    </div>
                                    <div>
                                        <SubmitButton />
                                    </div>
                                </div>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </Layout >
    )
}

export default CreateUser;


// <NonFieldErrors />
