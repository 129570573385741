import { Backup } from '@mui/icons-material';
import { Button, Tooltip } from "@mui/material";
import { FastField } from 'formik';
import { FC } from "react";
import { useDropzone } from "react-dropzone";

interface InputFileFieldProps {
    name: string
    multiple?: boolean
}

const InputFileField: FC<InputFileFieldProps> = props => {
    const { name, multiple = true } = props

    return (
        <FastField
            name={name}
            multiple={multiple}
            component={FileSelector}
        />
    )
}

interface FileSelectorProps {
    field: any
    form: any
    tooltip?: string
}


interface SelectedFilesMessageProps {
    files: File[]
}

const SelectedFilesMessage: FC<SelectedFilesMessageProps> = props => {
    const { files } = props

    if (!files?.length) return null

    const inflection = files?.length === 1 ? "file" : "files"

    return (
        <span> ({files?.length} {inflection} selected)</span>
    )
}


const FileSelector: FC<FileSelectorProps> = props => {
    const { form, field, tooltip = field?.name } = props

    const onDrop = (acceptedFiles: any) => {
        const value = field.value ? [...field.value, ...acceptedFiles] : acceptedFiles
        form?.setFieldValue(field.name, value)
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <div>
            <span{...getRootProps()}>
                <input {...getInputProps()} />
                <Tooltip title={tooltip}>
                    <Button startIcon={<Backup />}>Upload Files</Button>
                </Tooltip>
            </span>
            <SelectedFilesMessage files={field?.value} />
        </div>
    )

}

export default InputFileField;
