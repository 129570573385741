import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ConnectionCursor: any;
  DateTime: any;
  JSONObject: any;
  Upload: any;
};

export type AddAliasesToBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddCheckinSampleStatesToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddChecklistItemsToChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMeshConsumptionsToMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMeshLocationsToLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMeshLocationsToMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSampleMeshesToMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSampleMeshesToSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSamplePhotosToSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSupplierAliasesToSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTicketCommentsToTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTicketFilesToTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTicketFilesToTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddUsersToUserTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type Alias = {
  __typename?: 'Alias';
  alias: Scalars['String'];
  brand: Brand;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type AliasAggregateGroupBy = {
  __typename?: 'AliasAggregateGroupBy';
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type AliasAvgAggregate = {
  __typename?: 'AliasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type AliasConnection = {
  __typename?: 'AliasConnection';
  /** Array of nodes. */
  nodes: Array<Alias>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AliasCountAggregate = {
  __typename?: 'AliasCountAggregate';
  alias?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type AliasDeleteFilter = {
  alias?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<AliasDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AliasDeleteFilter>>;
};

export type AliasDeleteResponse = {
  __typename?: 'AliasDeleteResponse';
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AliasFilter = {
  alias?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<AliasFilter>>;
  brand?: InputMaybe<AliasFilterBrandFilter>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AliasFilter>>;
};

export type AliasFilterBrandFilter = {
  and?: InputMaybe<Array<AliasFilterBrandFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isActive?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AliasFilterBrandFilter>>;
};

export type AliasMaxAggregate = {
  __typename?: 'AliasMaxAggregate';
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type AliasMinAggregate = {
  __typename?: 'AliasMinAggregate';
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type AliasSort = {
  direction: SortDirection;
  field: AliasSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AliasSortFields {
  Alias = 'alias',
  Id = 'id'
}

export type AliasSumAggregate = {
  __typename?: 'AliasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type AliasUpdateFilter = {
  alias?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<AliasUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AliasUpdateFilter>>;
};

export type AutocompleteMesh = {
  __typename?: 'AutocompleteMesh';
  brand?: Maybe<Brand>;
  color: Scalars['String'];
  composition?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  meshKind?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  weightRequired?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  aliases?: Maybe<Array<Alias>>;
  avatar: Scalars['String'];
  commercial?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  stylist?: Maybe<User>;
  updatedAt: Scalars['DateTime'];
};


export type BrandAliasesArgs = {
  filter?: InputMaybe<AliasFilter>;
  sorting?: InputMaybe<Array<AliasSort>>;
};

export type BrandAggregateGroupBy = {
  __typename?: 'BrandAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type BrandAvgAggregate = {
  __typename?: 'BrandAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** Array of edges. */
  edges: Array<BrandEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type BrandCountAggregate = {
  __typename?: 'BrandCountAggregate';
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type BrandDeleteFilter = {
  and?: InputMaybe<Array<BrandDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isActive?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BrandDeleteFilter>>;
};

export type BrandDeleteResponse = {
  __typename?: 'BrandDeleteResponse';
  avatar?: Maybe<Scalars['String']>;
  commercial?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  stylist?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Brand */
  node: Brand;
};

export type BrandFilter = {
  and?: InputMaybe<Array<BrandFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isActive?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BrandFilter>>;
};

export type BrandInput = {
  avatar: Scalars['String'];
  commercial: UserInput;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
  stylist: UserInput;
  updatedAt: Scalars['DateTime'];
};

export type BrandMaxAggregate = {
  __typename?: 'BrandMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BrandMinAggregate = {
  __typename?: 'BrandMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BrandSort = {
  direction: SortDirection;
  field: BrandSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BrandSortFields {
  Id = 'id',
  IsActive = 'isActive',
  Name = 'name'
}

export type BrandSumAggregate = {
  __typename?: 'BrandSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BrandUpdateFilter = {
  and?: InputMaybe<Array<BrandUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isActive?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BrandUpdateFilter>>;
};

export type Checklist = {
  __typename?: 'Checklist';
  checklistItems?: Maybe<Array<ChecklistItem>>;
  createdAt: Scalars['DateTime'];
  event: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userType: UserType;
};


export type ChecklistChecklistItemsArgs = {
  filter?: InputMaybe<ChecklistItemFilter>;
  sorting?: InputMaybe<Array<ChecklistItemSort>>;
};

export type ChecklistAggregateGroupBy = {
  __typename?: 'ChecklistAggregateGroupBy';
  event?: Maybe<Scalars['String']>;
};

export type ChecklistConnection = {
  __typename?: 'ChecklistConnection';
  /** Array of edges. */
  edges: Array<ChecklistEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ChecklistCountAggregate = {
  __typename?: 'ChecklistCountAggregate';
  event?: Maybe<Scalars['Int']>;
};

export type ChecklistEdge = {
  __typename?: 'ChecklistEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Checklist */
  node: Checklist;
};

export type ChecklistFilter = {
  and?: InputMaybe<Array<ChecklistFilter>>;
  event?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ChecklistFilter>>;
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChecklistItemAggregateGroupBy = {
  __typename?: 'ChecklistItemAggregateGroupBy';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistItemConnection = {
  __typename?: 'ChecklistItemConnection';
  /** Array of edges. */
  edges: Array<ChecklistItemEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ChecklistItemCountAggregate = {
  __typename?: 'ChecklistItemCountAggregate';
  name?: Maybe<Scalars['Int']>;
};

export type ChecklistItemEdge = {
  __typename?: 'ChecklistItemEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ChecklistItem */
  node: ChecklistItem;
};

export type ChecklistItemFilter = {
  and?: InputMaybe<Array<ChecklistItemFilter>>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ChecklistItemFilter>>;
};

export type ChecklistItemMaxAggregate = {
  __typename?: 'ChecklistItemMaxAggregate';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistItemMinAggregate = {
  __typename?: 'ChecklistItemMinAggregate';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistItemSort = {
  direction: SortDirection;
  field: ChecklistItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ChecklistItemSortFields {
  Name = 'name'
}

export type ChecklistMaxAggregate = {
  __typename?: 'ChecklistMaxAggregate';
  event?: Maybe<Scalars['String']>;
};

export type ChecklistMinAggregate = {
  __typename?: 'ChecklistMinAggregate';
  event?: Maybe<Scalars['String']>;
};

export type ChecklistSort = {
  direction: SortDirection;
  field: ChecklistSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ChecklistSortFields {
  Event = 'event'
}

export type CommonIssue = {
  __typename?: 'CommonIssue';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type CommonIssueAggregateGroupBy = {
  __typename?: 'CommonIssueAggregateGroupBy';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonIssueConnection = {
  __typename?: 'CommonIssueConnection';
  /** Array of edges. */
  edges: Array<CommonIssueEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type CommonIssueCountAggregate = {
  __typename?: 'CommonIssueCountAggregate';
  key?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type CommonIssueEdge = {
  __typename?: 'CommonIssueEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the CommonIssue */
  node: CommonIssue;
};

export type CommonIssueFilter = {
  and?: InputMaybe<Array<CommonIssueFilter>>;
  key?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CommonIssueFilter>>;
  value?: InputMaybe<StringFieldComparison>;
};

export type CommonIssueMaxAggregate = {
  __typename?: 'CommonIssueMaxAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonIssueMinAggregate = {
  __typename?: 'CommonIssueMinAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonIssueSort = {
  direction: SortDirection;
  field: CommonIssueSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CommonIssueSortFields {
  Key = 'key',
  Value = 'value'
}

export type Configuration = {
  __typename?: 'Configuration';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type ConfigurationAggregateGroupBy = {
  __typename?: 'ConfigurationAggregateGroupBy';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ConfigurationConnection = {
  __typename?: 'ConfigurationConnection';
  /** Array of nodes. */
  nodes: Array<Configuration>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConfigurationCountAggregate = {
  __typename?: 'ConfigurationCountAggregate';
  key?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type ConfigurationFilter = {
  and?: InputMaybe<Array<ConfigurationFilter>>;
  key?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ConfigurationFilter>>;
  value?: InputMaybe<StringFieldComparison>;
};

export type ConfigurationMaxAggregate = {
  __typename?: 'ConfigurationMaxAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ConfigurationMinAggregate = {
  __typename?: 'ConfigurationMinAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ConfigurationSort = {
  direction: SortDirection;
  field: ConfigurationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ConfigurationSortFields {
  Key = 'key',
  Value = 'value'
}

export type CreateAlias = {
  alias?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateBrand = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  priority?: InputMaybe<Scalars['Int']>;
};

export type CreateIssue = {
  description: Scalars['String'];
  order: Scalars['String'];
  reference: Scalars['String'];
  sample: Scalars['String'];
};

export type CreateLocation = {
  name: Scalars['String'];
};

export type CreateManyAliasesInput = {
  /** Array of records to create */
  aliases: Array<CreateAlias>;
};

export type CreateManyBrandsInput = {
  /** Array of records to create */
  brands: Array<CreateBrand>;
};

export type CreateManyLocationsInput = {
  /** Array of records to create */
  locations: Array<CreateLocation>;
};

export type CreateManyMeshLocationsInput = {
  /** Array of records to create */
  meshLocations: Array<CreateMeshLocation>;
};

export type CreateManyNotificationsInput = {
  /** Array of records to create */
  notifications: Array<CreateNotification>;
};

export type CreateManyPosterCompositionsInput = {
  /** Array of records to create */
  posterCompositions: Array<CreatePosterComposition>;
};

export type CreateManyPosterFabricTypesInput = {
  /** Array of records to create */
  posterFabricTypes: Array<CreatePosterFabricType>;
};

export type CreateManyPosterSeasonsInput = {
  /** Array of records to create */
  posterSeasons: Array<CreatePosterSeason>;
};

export type CreateManyPostersInput = {
  /** Array of records to create */
  posters: Array<CreatePoster>;
};

export type CreateManyPurchaseOrderTypesInput = {
  /** Array of records to create */
  purchaseOrderTypes: Array<CreatePurchaseOrderType>;
};

export type CreateManySampleMeshesInput = {
  sampleMeshes: Array<CreateSampleMesh>;
};

export type CreateManySampleStatesInput = {
  /** Array of records to create */
  sampleStates: Array<CreateSampleState>;
};

export type CreateManySampleTypesInput = {
  /** Array of records to create */
  sampleTypes: Array<CreateSampleType>;
};

export type CreateManySamplesInput = {
  /** Array of records to create */
  samples: Array<CreateSample>;
};

export type CreateManySupplierAliasesInput = {
  /** Array of records to create */
  supplierAliases: Array<CreateSupplierAlias>;
};

export type CreateManySuppliersInput = {
  /** Array of records to create */
  suppliers: Array<CreateSupplier>;
};

export type CreateManyUserTypesInput = {
  /** Array of records to create */
  userTypes: Array<CreateUserType>;
};

export type CreateManyWashTypesInput = {
  /** Array of records to create */
  washTypes: Array<CreateWashType>;
};

export type CreateMesh = {
  brand: Scalars['ID'];
  color?: InputMaybe<Scalars['String']>;
  colorObservations?: InputMaybe<Scalars['String']>;
  composition?: InputMaybe<Scalars['String']>;
  debitedToClient?: InputMaybe<Scalars['Boolean']>;
  inspectionDate: Scalars['DateTime'];
  lengthAfterWash?: InputMaybe<Scalars['Float']>;
  lengthBeforeWash?: InputMaybe<Scalars['Float']>;
  lengthShrinkageObservations?: InputMaybe<Scalars['String']>;
  meshKind?: InputMaybe<Scalars['String']>;
  meshSupplier?: InputMaybe<Scalars['ID']>;
  meshSupplierDocument?: InputMaybe<Scalars['String']>;
  purchaseOrder?: InputMaybe<Scalars['String']>;
  purchaseOrderType: Scalars['ID'];
  quantityReceived: Scalars['Float'];
  quantitySent?: InputMaybe<Scalars['Float']>;
  quantityUnit: Scalars['String'];
  receptionDate: Scalars['DateTime'];
  spiralityDeviation?: InputMaybe<Scalars['Float']>;
  spiralityObservations?: InputMaybe<Scalars['String']>;
  spoolCount?: InputMaybe<Scalars['Int']>;
  supplier: Scalars['ID'];
  supplierDocument?: InputMaybe<Scalars['String']>;
  washType?: InputMaybe<Scalars['ID']>;
  weightReceived?: InputMaybe<Scalars['Float']>;
  weightRequired?: InputMaybe<Scalars['Float']>;
  widthAfterWash?: InputMaybe<Scalars['Float']>;
  widthBeforeWash?: InputMaybe<Scalars['Float']>;
  widthReceived?: InputMaybe<Scalars['Float']>;
  widthRequired?: InputMaybe<Scalars['Float']>;
  widthShrinkageObservations?: InputMaybe<Scalars['String']>;
};

export type CreateMeshConsumption = {
  mesh: Scalars['Float'];
  purpose: Scalars['String'];
  quantity: Scalars['Float'];
  unit: Scalars['String'];
};

export type CreateMeshLocation = {
  location: Scalars['ID'];
  mesh: Scalars['ID'];
};

export type CreateNotification = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<NotificationKinds>;
  message?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<NotificationPriorities>;
  tab?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateOneAliasInput = {
  /** The record to create */
  alias: CreateAlias;
};

export type CreateOneBrandInput = {
  /** The record to create */
  brand: CreateBrand;
};

export type CreateOneIssueInput = {
  issue: CreateIssue;
};

export type CreateOneLocationInput = {
  /** The record to create */
  location: CreateLocation;
};

export type CreateOneMeshConsumptionInput = {
  meshConsumption: CreateMeshConsumption;
};

export type CreateOneMeshInput = {
  mesh: CreateMesh;
};

export type CreateOneMeshLocationInput = {
  /** The record to create */
  meshLocation: CreateMeshLocation;
};

export type CreateOneNotificationInput = {
  /** The record to create */
  notification: CreateNotification;
};

export type CreateOnePosterCompositionInput = {
  /** The record to create */
  posterComposition: CreatePosterComposition;
};

export type CreateOnePosterFabricTypeInput = {
  /** The record to create */
  posterFabricType: CreatePosterFabricType;
};

export type CreateOnePosterInput = {
  /** The record to create */
  poster: CreatePoster;
};

export type CreateOnePosterSeasonInput = {
  /** The record to create */
  posterSeason: CreatePosterSeason;
};

export type CreateOnePurchaseOrderTypeInput = {
  /** The record to create */
  purchaseOrderType: CreatePurchaseOrderType;
};

export type CreateOneQuoteInput = {
  quote: CreateQuote;
};

export type CreateOneSampleInput = {
  /** The record to create */
  sample: CreateSample;
};

export type CreateOneSampleMeshInput = {
  sampleMesh: CreateSampleMesh;
};

export type CreateOneSamplePhotoInput = {
  samplePhoto: CreateSamplePhoto;
};

export type CreateOneSampleStateInput = {
  /** The record to create */
  sampleState: CreateSampleState;
};

export type CreateOneSampleTypeInput = {
  /** The record to create */
  sampleType: CreateSampleType;
};

export type CreateOneSupplierAliasInput = {
  /** The record to create */
  supplierAlias: CreateSupplierAlias;
};

export type CreateOneSupplierInput = {
  /** The record to create */
  supplier: CreateSupplier;
};

export type CreateOneTicketCommentInput = {
  ticketComment: CreateTicketComment;
};

export type CreateOneTicketInput = {
  ticket: CreateTicket;
};

export type CreateOneUserArgs = {
  user: CreateOneUserInput;
};

export type CreateOneUserEventInput = {
  userEvent: CreateUserEvent;
};

export type CreateOneUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  userType: Scalars['ID'];
};

export type CreateOneUserTypeInput = {
  /** The record to create */
  userType: CreateUserType;
};

export type CreateOneWashTypeInput = {
  /** The record to create */
  washType: CreateWashType;
};

export type CreatePoster = {
  composition: Scalars['String'];
  cover: Scalars['Upload'];
  meshKind: Scalars['String'];
  photo: Scalars['Upload'];
  reference: Scalars['String'];
  specifications: Scalars['Upload'];
  story: Scalars['String'];
  weight: Scalars['Float'];
};

export type CreatePosterComposition = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreatePosterFabricType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  posterType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreatePosterSeason = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreatePurchaseOrderType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateQuote = {
  address: Scalars['String'];
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  brand: Scalars['String'];
  categories: Array<Scalars['String']>;
  city: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  estimatedQuantityYear?: InputMaybe<Scalars['Float']>;
  jobTitle: Scalars['String'];
  name: Scalars['String'];
  orderQuantityColor?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  productGroup: Array<Scalars['String']>;
  requestType: Array<Scalars['String']>;
  specification: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CreateSample = {
  clientReference?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  decorationsReceivedAt?: InputMaybe<Scalars['String']>;
  fastTrack?: InputMaybe<Scalars['Boolean']>;
  foreseenDate?: InputMaybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: InputMaybe<Scalars['String']>;
  hasConfectionAccessories?: InputMaybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: InputMaybe<Scalars['String']>;
  hasMesh?: InputMaybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: InputMaybe<Scalars['String']>;
  hasPackingAccessories?: InputMaybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  intendedDate?: InputMaybe<Scalars['DateTime']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  observations?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  orderReference?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  shippingDate?: InputMaybe<Scalars['String']>;
  sketch?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateSampleMesh = {
  mesh: Scalars['Float'];
  sample: Scalars['Float'];
};

export type CreateSamplePhoto = {
  sample: Scalars['ID'];
  upload: Scalars['Upload'];
};

export type CreateSampleState = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  sampleStateTypeId?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateSampleType = {
  created?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['DateTime']>;
};

export type CreateSupplier = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isMeshSupplier?: InputMaybe<Scalars['Boolean']>;
  isSupplier?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateSupplierAlias = {
  externalId: Scalars['String'];
  supplier: Scalars['ID'];
};

export type CreateTicket = {
  description: Scalars['String'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  ticketFiles?: InputMaybe<Array<Scalars['Upload']>>;
};

export type CreateTicketComment = {
  content: Scalars['String'];
  ticket: Scalars['Int'];
  ticketFiles?: InputMaybe<Array<Scalars['Upload']>>;
};

export type CreateUserEvent = {
  data?: InputMaybe<Scalars['JSONObject']>;
  name: Scalars['String'];
};

export type CreateUserType = {
  created?: InputMaybe<Scalars['DateTime']>;
  doing?: InputMaybe<Array<SampleInputType>>;
  doingCount?: InputMaybe<Scalars['Int']>;
  done?: InputMaybe<Array<SampleInputType>>;
  doneCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  todo?: InputMaybe<Array<SampleInputType>>;
  todoCount?: InputMaybe<Scalars['Int']>;
  updated?: InputMaybe<Scalars['DateTime']>;
};

export type CreateWashType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']>;
};

export type DashPeriodStat = {
  __typename?: 'DashPeriodStat';
  current: Scalars['Float'];
  reference: Scalars['Float'];
};

export type DashPeriodStatInput = {
  current: Scalars['Float'];
  reference: Scalars['Float'];
};

export type DashUserStat = {
  __typename?: 'DashUserStat';
  day: DashPeriodStat;
  week: DashPeriodStat;
};

export type DashUserStatInput = {
  day: DashPeriodStatInput;
  week: DashPeriodStatInput;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type DeleteManyAliasesInput = {
  /** Filter to find records to delete */
  filter: AliasDeleteFilter;
};

export type DeleteManyBrandsInput = {
  /** Filter to find records to delete */
  filter: BrandDeleteFilter;
};

export type DeleteManyMeshLocationsInput = {
  /** Filter to find records to delete */
  filter: MeshLocationDeleteFilter;
};

export type DeleteManyMeshesInput = {
  /** Filter to find records to delete */
  filter: MeshDeleteFilter;
};

export type DeleteManyNotificationsInput = {
  /** Filter to find records to delete */
  filter: NotificationDeleteFilter;
};

export type DeleteManyPosterCompositionsInput = {
  /** Filter to find records to delete */
  filter: PosterCompositionDeleteFilter;
};

export type DeleteManyPosterFabricTypesInput = {
  /** Filter to find records to delete */
  filter: PosterFabricTypeDeleteFilter;
};

export type DeleteManyPosterSeasonsInput = {
  /** Filter to find records to delete */
  filter: PosterSeasonDeleteFilter;
};

export type DeleteManyPostersInput = {
  /** Filter to find records to delete */
  filter: PosterDeleteFilter;
};

export type DeleteManyPurchaseOrderTypesInput = {
  /** Filter to find records to delete */
  filter: PurchaseOrderTypeDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteManySampleMeshesInput = {
  /** Filter to find records to delete */
  filter: SampleMeshDeleteFilter;
};

export type DeleteManySampleStatesInput = {
  /** Filter to find records to delete */
  filter: SampleStateDeleteFilter;
};

export type DeleteManySampleTypesInput = {
  /** Filter to find records to delete */
  filter: SampleTypeDeleteFilter;
};

export type DeleteManySamplesInput = {
  /** Filter to find records to delete */
  filter: SampleDeleteFilter;
};

export type DeleteManySupplierAliasesInput = {
  /** Filter to find records to delete */
  filter: SupplierAliasDeleteFilter;
};

export type DeleteManyUserEventsInput = {
  /** Filter to find records to delete */
  filter: UserEventDeleteFilter;
};

export type DeleteManyUserTypesInput = {
  /** Filter to find records to delete */
  filter: UserTypeDeleteFilter;
};

export type DeleteManyWashTypesInput = {
  /** Filter to find records to delete */
  filter: WashTypeDeleteFilter;
};

export type DeleteOneAliasInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneBrandInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMeshConsumptionInput = {
  id: Scalars['ID'];
};

export type DeleteOneMeshInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMeshLocationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneNotificationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePosterCompositionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePosterFabricTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePosterInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePosterSeasonInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePurchaseOrderTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSampleInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSampleMeshInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSampleStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSampleTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSupplierAliasInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneUserEventInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneUserTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneWashTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type ExecuteMeshTransfers = {
  meshTransfers: Array<Scalars['ID']>;
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  iLike?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  neq?: InputMaybe<Scalars['ID']>;
  notILike?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
  notLike?: InputMaybe<Scalars['ID']>;
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int'];
  upper: Scalars['Int'];
};

export type Issue = {
  __typename?: 'Issue';
  description: Scalars['String'];
  id: Scalars['Float'];
  order: Scalars['String'];
  reference: Scalars['String'];
  sample: Scalars['String'];
};

export type JsonObjectFilterComparison = {
  eq?: InputMaybe<Scalars['JSONObject']>;
  gt?: InputMaybe<Scalars['JSONObject']>;
  gte?: InputMaybe<Scalars['JSONObject']>;
  iLike?: InputMaybe<Scalars['JSONObject']>;
  in?: InputMaybe<Array<Scalars['JSONObject']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['JSONObject']>;
  lt?: InputMaybe<Scalars['JSONObject']>;
  lte?: InputMaybe<Scalars['JSONObject']>;
  neq?: InputMaybe<Scalars['JSONObject']>;
  notILike?: InputMaybe<Scalars['JSONObject']>;
  notIn?: InputMaybe<Array<Scalars['JSONObject']>>;
  notLike?: InputMaybe<Scalars['JSONObject']>;
};

export type Location = {
  __typename?: 'Location';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  meshLocations: Array<MeshLocation>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type LocationMeshLocationsArgs = {
  filter?: InputMaybe<MeshLocationFilter>;
  sorting?: InputMaybe<Array<MeshLocationSort>>;
};

export type LocationAggregateGroupBy = {
  __typename?: 'LocationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationAvgAggregate = {
  __typename?: 'LocationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  /** Array of edges. */
  edges: Array<LocationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type LocationCountAggregate = {
  __typename?: 'LocationCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Location */
  node: Location;
};

export type LocationFilter = {
  and?: InputMaybe<Array<LocationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  meshLocations?: InputMaybe<LocationFilterMeshLocationFilter>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LocationFilter>>;
};

export type LocationFilterMeshLocationFilter = {
  and?: InputMaybe<Array<LocationFilterMeshLocationFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<LocationFilterMeshLocationFilter>>;
};

export type LocationMaxAggregate = {
  __typename?: 'LocationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationMinAggregate = {
  __typename?: 'LocationMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationSort = {
  direction: SortDirection;
  field: LocationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LocationSortFields {
  Id = 'id',
  Name = 'name'
}

export type LocationSumAggregate = {
  __typename?: 'LocationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type Mesh = {
  __typename?: 'Mesh';
  brand: Brand;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  debitedToClient: Scalars['Boolean'];
  id: Scalars['Float'];
  inspectedBy: User;
  inspectionDate: Scalars['DateTime'];
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  meshConsumptions?: Maybe<Array<MeshConsumption>>;
  meshKind?: Maybe<Scalars['String']>;
  meshLocations?: Maybe<Array<MeshLocation>>;
  meshSupplier?: Maybe<Supplier>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  purchaseOrderType?: Maybe<PurchaseOrderType>;
  quantityReceived: Scalars['Float'];
  quantitySent?: Maybe<Scalars['Float']>;
  quantityUnit: Scalars['String'];
  receptionDate: Scalars['DateTime'];
  sampleMeshes?: Maybe<Array<SampleMesh>>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  spoolCount?: Maybe<Scalars['Int']>;
  supplier: Supplier;
  supplierDocument?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  washType?: Maybe<WashType>;
  weightReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
};


export type MeshMeshConsumptionsArgs = {
  filter?: InputMaybe<MeshConsumptionFilter>;
  sorting?: InputMaybe<Array<MeshConsumptionSort>>;
};


export type MeshMeshLocationsArgs = {
  filter?: InputMaybe<MeshLocationFilter>;
  sorting?: InputMaybe<Array<MeshLocationSort>>;
};


export type MeshSampleMeshesArgs = {
  filter?: InputMaybe<SampleMeshFilter>;
  sorting?: InputMaybe<Array<SampleMeshSort>>;
};

export type MeshAggregateGroupBy = {
  __typename?: 'MeshAggregateGroupBy';
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meshKind?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Int']>;
  supplierDocument?: Maybe<Scalars['String']>;
  weightReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
};

export type MeshAvgAggregate = {
  __typename?: 'MeshAvgAggregate';
  currentQuantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
};

export type MeshConnection = {
  __typename?: 'MeshConnection';
  /** Array of nodes. */
  nodes: Array<Mesh>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MeshConsumption = {
  __typename?: 'MeshConsumption';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  mesh: Mesh;
  purpose: Scalars['String'];
  quantity: Scalars['Float'];
  unit: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type MeshConsumptionAggregateGroupBy = {
  __typename?: 'MeshConsumptionAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
};

export type MeshConsumptionAvgAggregate = {
  __typename?: 'MeshConsumptionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type MeshConsumptionConnection = {
  __typename?: 'MeshConsumptionConnection';
  /** Array of edges. */
  edges: Array<MeshConsumptionEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type MeshConsumptionCountAggregate = {
  __typename?: 'MeshConsumptionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['Int']>;
};

export type MeshConsumptionEdge = {
  __typename?: 'MeshConsumptionEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the MeshConsumption */
  node: MeshConsumption;
};

export type MeshConsumptionFilter = {
  and?: InputMaybe<Array<MeshConsumptionFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<MeshConsumptionFilter>>;
  purpose?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  unit?: InputMaybe<StringFieldComparison>;
};

export type MeshConsumptionMaxAggregate = {
  __typename?: 'MeshConsumptionMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
};

export type MeshConsumptionMinAggregate = {
  __typename?: 'MeshConsumptionMinAggregate';
  id?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
};

export type MeshConsumptionSort = {
  direction: SortDirection;
  field: MeshConsumptionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MeshConsumptionSortFields {
  Id = 'id',
  Purpose = 'purpose',
  Quantity = 'quantity',
  Unit = 'unit'
}

export type MeshConsumptionSumAggregate = {
  __typename?: 'MeshConsumptionSumAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type MeshConsumptionUpdateFilter = {
  and?: InputMaybe<Array<MeshConsumptionUpdateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<MeshConsumptionUpdateFilter>>;
  purpose?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  unit?: InputMaybe<StringFieldComparison>;
};

export type MeshCountAggregate = {
  __typename?: 'MeshCountAggregate';
  color?: Maybe<Scalars['Int']>;
  colorObservations?: Maybe<Scalars['Int']>;
  composition?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  currentQuantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  meshKind?: Maybe<Scalars['Int']>;
  meshSupplierDocument?: Maybe<Scalars['Int']>;
  purchaseOrder?: Maybe<Scalars['Int']>;
  quantityReceived?: Maybe<Scalars['Int']>;
  quantitySent?: Maybe<Scalars['Int']>;
  quantityUnit?: Maybe<Scalars['Int']>;
  spiralityDeviation?: Maybe<Scalars['Int']>;
  spoolCount?: Maybe<Scalars['Int']>;
  supplierDocument?: Maybe<Scalars['Int']>;
  weightReceived?: Maybe<Scalars['Int']>;
  weightRequired?: Maybe<Scalars['Int']>;
  widthReceived?: Maybe<Scalars['Int']>;
  widthRequired?: Maybe<Scalars['Int']>;
};

export type MeshDeleteFilter = {
  and?: InputMaybe<Array<MeshDeleteFilter>>;
  color?: InputMaybe<StringFieldComparison>;
  colorObservations?: InputMaybe<StringFieldComparison>;
  composition?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currentQuantity?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  meshKind?: InputMaybe<StringFieldComparison>;
  meshSupplierDocument?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshDeleteFilter>>;
  purchaseOrder?: InputMaybe<StringFieldComparison>;
  quantityReceived?: InputMaybe<NumberFieldComparison>;
  quantitySent?: InputMaybe<NumberFieldComparison>;
  quantityUnit?: InputMaybe<StringFieldComparison>;
  spiralityDeviation?: InputMaybe<NumberFieldComparison>;
  spoolCount?: InputMaybe<IntFieldComparison>;
  supplierDocument?: InputMaybe<StringFieldComparison>;
  weightReceived?: InputMaybe<NumberFieldComparison>;
  weightRequired?: InputMaybe<NumberFieldComparison>;
  widthReceived?: InputMaybe<NumberFieldComparison>;
  widthRequired?: InputMaybe<NumberFieldComparison>;
};

export type MeshDeleteResponse = {
  __typename?: 'MeshDeleteResponse';
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  debitedToClient?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  inspectionDate?: Maybe<Scalars['DateTime']>;
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  receptionDate?: Maybe<Scalars['DateTime']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  spoolCount?: Maybe<Scalars['Int']>;
  supplierDocument?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weightReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
};

export type MeshFilter = {
  and?: InputMaybe<Array<MeshFilter>>;
  brand?: InputMaybe<MeshFilterBrandFilter>;
  color?: InputMaybe<StringFieldComparison>;
  colorObservations?: InputMaybe<StringFieldComparison>;
  composition?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currentQuantity?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  inspectedBy?: InputMaybe<MeshFilterUserFilter>;
  meshKind?: InputMaybe<StringFieldComparison>;
  meshSupplier?: InputMaybe<MeshFilterSupplierFilter>;
  meshSupplierDocument?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshFilter>>;
  purchaseOrder?: InputMaybe<StringFieldComparison>;
  purchaseOrderType?: InputMaybe<MeshFilterPurchaseOrderTypeFilter>;
  quantityReceived?: InputMaybe<NumberFieldComparison>;
  quantitySent?: InputMaybe<NumberFieldComparison>;
  quantityUnit?: InputMaybe<StringFieldComparison>;
  spiralityDeviation?: InputMaybe<NumberFieldComparison>;
  spoolCount?: InputMaybe<IntFieldComparison>;
  supplier?: InputMaybe<MeshFilterSupplierFilter>;
  supplierDocument?: InputMaybe<StringFieldComparison>;
  washType?: InputMaybe<MeshFilterWashTypeFilter>;
  weightReceived?: InputMaybe<NumberFieldComparison>;
  weightRequired?: InputMaybe<NumberFieldComparison>;
  widthReceived?: InputMaybe<NumberFieldComparison>;
  widthRequired?: InputMaybe<NumberFieldComparison>;
};

export type MeshFilterBrandFilter = {
  and?: InputMaybe<Array<MeshFilterBrandFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isActive?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshFilterBrandFilter>>;
};

export type MeshFilterPurchaseOrderTypeFilter = {
  and?: InputMaybe<Array<MeshFilterPurchaseOrderTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshFilterPurchaseOrderTypeFilter>>;
};

export type MeshFilterSupplierFilter = {
  and?: InputMaybe<Array<MeshFilterSupplierFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isMeshSupplier?: InputMaybe<BooleanFieldComparison>;
  isSupplier?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshFilterSupplierFilter>>;
};

export type MeshFilterUserFilter = {
  and?: InputMaybe<Array<MeshFilterUserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshFilterUserFilter>>;
};

export type MeshFilterWashTypeFilter = {
  and?: InputMaybe<Array<MeshFilterWashTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshFilterWashTypeFilter>>;
};

export type MeshLocation = {
  __typename?: 'MeshLocation';
  id: Scalars['Float'];
  location: Location;
  mesh: Mesh;
};

export type MeshLocationAggregateGroupBy = {
  __typename?: 'MeshLocationAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationAvgAggregate = {
  __typename?: 'MeshLocationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationConnection = {
  __typename?: 'MeshLocationConnection';
  /** Array of edges. */
  edges: Array<MeshLocationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type MeshLocationCountAggregate = {
  __typename?: 'MeshLocationCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type MeshLocationDeleteFilter = {
  and?: InputMaybe<Array<MeshLocationDeleteFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<MeshLocationDeleteFilter>>;
};

export type MeshLocationDeleteResponse = {
  __typename?: 'MeshLocationDeleteResponse';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationEdge = {
  __typename?: 'MeshLocationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the MeshLocation */
  node: MeshLocation;
};

export type MeshLocationFilter = {
  and?: InputMaybe<Array<MeshLocationFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  mesh?: InputMaybe<MeshLocationFilterMeshFilter>;
  or?: InputMaybe<Array<MeshLocationFilter>>;
};

export type MeshLocationFilterMeshFilter = {
  and?: InputMaybe<Array<MeshLocationFilterMeshFilter>>;
  color?: InputMaybe<StringFieldComparison>;
  colorObservations?: InputMaybe<StringFieldComparison>;
  composition?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currentQuantity?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  meshKind?: InputMaybe<StringFieldComparison>;
  meshSupplierDocument?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshLocationFilterMeshFilter>>;
  purchaseOrder?: InputMaybe<StringFieldComparison>;
  quantityReceived?: InputMaybe<NumberFieldComparison>;
  quantitySent?: InputMaybe<NumberFieldComparison>;
  quantityUnit?: InputMaybe<StringFieldComparison>;
  spiralityDeviation?: InputMaybe<NumberFieldComparison>;
  spoolCount?: InputMaybe<IntFieldComparison>;
  supplierDocument?: InputMaybe<StringFieldComparison>;
  weightReceived?: InputMaybe<NumberFieldComparison>;
  weightRequired?: InputMaybe<NumberFieldComparison>;
  widthReceived?: InputMaybe<NumberFieldComparison>;
  widthRequired?: InputMaybe<NumberFieldComparison>;
};

export type MeshLocationMaxAggregate = {
  __typename?: 'MeshLocationMaxAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationMinAggregate = {
  __typename?: 'MeshLocationMinAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationSort = {
  direction: SortDirection;
  field: MeshLocationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MeshLocationSortFields {
  Id = 'id'
}

export type MeshLocationSumAggregate = {
  __typename?: 'MeshLocationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationUpdateFilter = {
  and?: InputMaybe<Array<MeshLocationUpdateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<MeshLocationUpdateFilter>>;
};

export type MeshMaxAggregate = {
  __typename?: 'MeshMaxAggregate';
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meshKind?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Int']>;
  supplierDocument?: Maybe<Scalars['String']>;
  weightReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
};

export type MeshMinAggregate = {
  __typename?: 'MeshMinAggregate';
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  meshKind?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Int']>;
  supplierDocument?: Maybe<Scalars['String']>;
  weightReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
};

export type MeshSort = {
  direction: SortDirection;
  field: MeshSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MeshSortFields {
  Color = 'color',
  ColorObservations = 'colorObservations',
  Composition = 'composition',
  CreatedAt = 'createdAt',
  CurrentQuantity = 'currentQuantity',
  Id = 'id',
  MeshKind = 'meshKind',
  MeshSupplierDocument = 'meshSupplierDocument',
  PurchaseOrder = 'purchaseOrder',
  QuantityReceived = 'quantityReceived',
  QuantitySent = 'quantitySent',
  QuantityUnit = 'quantityUnit',
  SpiralityDeviation = 'spiralityDeviation',
  SpoolCount = 'spoolCount',
  SupplierDocument = 'supplierDocument',
  WeightReceived = 'weightReceived',
  WeightRequired = 'weightRequired',
  WidthReceived = 'widthReceived',
  WidthRequired = 'widthRequired'
}

export type MeshSumAggregate = {
  __typename?: 'MeshSumAggregate';
  currentQuantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
};

export type MeshTransfer = {
  __typename?: 'MeshTransfer';
  createdAt: Scalars['DateTime'];
  destination?: Maybe<Location>;
  id: Scalars['ID'];
  mesh: Mesh;
  state: MeshTransferStates;
  type: MeshTransferTypes;
  updatedAt: Scalars['DateTime'];
};

export type MeshTransferAggregateGroupBy = {
  __typename?: 'MeshTransferAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<MeshTransferStates>;
  type?: Maybe<MeshTransferTypes>;
};

export type MeshTransferAvgAggregate = {
  __typename?: 'MeshTransferAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshTransferConnection = {
  __typename?: 'MeshTransferConnection';
  /** Array of nodes. */
  nodes: Array<MeshTransfer>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MeshTransferCountAggregate = {
  __typename?: 'MeshTransferCountAggregate';
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type MeshTransferFilter = {
  and?: InputMaybe<Array<MeshTransferFilter>>;
  destination?: InputMaybe<MeshTransferFilterLocationFilter>;
  id?: InputMaybe<IdFilterComparison>;
  mesh?: InputMaybe<MeshTransferFilterMeshFilter>;
  or?: InputMaybe<Array<MeshTransferFilter>>;
  state?: InputMaybe<MeshTransferStatesFilterComparison>;
  type?: InputMaybe<MeshTransferTypesFilterComparison>;
};

export type MeshTransferFilterLocationFilter = {
  and?: InputMaybe<Array<MeshTransferFilterLocationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshTransferFilterLocationFilter>>;
};

export type MeshTransferFilterMeshFilter = {
  and?: InputMaybe<Array<MeshTransferFilterMeshFilter>>;
  color?: InputMaybe<StringFieldComparison>;
  colorObservations?: InputMaybe<StringFieldComparison>;
  composition?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currentQuantity?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  meshKind?: InputMaybe<StringFieldComparison>;
  meshSupplierDocument?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshTransferFilterMeshFilter>>;
  purchaseOrder?: InputMaybe<StringFieldComparison>;
  quantityReceived?: InputMaybe<NumberFieldComparison>;
  quantitySent?: InputMaybe<NumberFieldComparison>;
  quantityUnit?: InputMaybe<StringFieldComparison>;
  spiralityDeviation?: InputMaybe<NumberFieldComparison>;
  spoolCount?: InputMaybe<IntFieldComparison>;
  supplierDocument?: InputMaybe<StringFieldComparison>;
  weightReceived?: InputMaybe<NumberFieldComparison>;
  weightRequired?: InputMaybe<NumberFieldComparison>;
  widthReceived?: InputMaybe<NumberFieldComparison>;
  widthRequired?: InputMaybe<NumberFieldComparison>;
};

export type MeshTransferMaxAggregate = {
  __typename?: 'MeshTransferMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<MeshTransferStates>;
  type?: Maybe<MeshTransferTypes>;
};

export type MeshTransferMinAggregate = {
  __typename?: 'MeshTransferMinAggregate';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<MeshTransferStates>;
  type?: Maybe<MeshTransferTypes>;
};

export type MeshTransferSort = {
  direction: SortDirection;
  field: MeshTransferSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MeshTransferSortFields {
  Id = 'id',
  State = 'state',
  Type = 'type'
}

export enum MeshTransferStates {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Pending = 'PENDING'
}

export type MeshTransferStatesFilterComparison = {
  eq?: InputMaybe<MeshTransferStates>;
  gt?: InputMaybe<MeshTransferStates>;
  gte?: InputMaybe<MeshTransferStates>;
  iLike?: InputMaybe<MeshTransferStates>;
  in?: InputMaybe<Array<MeshTransferStates>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<MeshTransferStates>;
  lt?: InputMaybe<MeshTransferStates>;
  lte?: InputMaybe<MeshTransferStates>;
  neq?: InputMaybe<MeshTransferStates>;
  notILike?: InputMaybe<MeshTransferStates>;
  notIn?: InputMaybe<Array<MeshTransferStates>>;
  notLike?: InputMaybe<MeshTransferStates>;
};

export type MeshTransferSumAggregate = {
  __typename?: 'MeshTransferSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export enum MeshTransferTypes {
  Picking = 'PICKING',
  Purge = 'PURGE'
}

export type MeshTransferTypesFilterComparison = {
  eq?: InputMaybe<MeshTransferTypes>;
  gt?: InputMaybe<MeshTransferTypes>;
  gte?: InputMaybe<MeshTransferTypes>;
  iLike?: InputMaybe<MeshTransferTypes>;
  in?: InputMaybe<Array<MeshTransferTypes>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<MeshTransferTypes>;
  lt?: InputMaybe<MeshTransferTypes>;
  lte?: InputMaybe<MeshTransferTypes>;
  neq?: InputMaybe<MeshTransferTypes>;
  notILike?: InputMaybe<MeshTransferTypes>;
  notIn?: InputMaybe<Array<MeshTransferTypes>>;
  notLike?: InputMaybe<MeshTransferTypes>;
};

export type MeshTransferUpdateFilter = {
  and?: InputMaybe<Array<MeshTransferUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<MeshTransferUpdateFilter>>;
  state?: InputMaybe<MeshTransferStatesFilterComparison>;
  type?: InputMaybe<MeshTransferTypesFilterComparison>;
};

export type MeshUpdateFilter = {
  and?: InputMaybe<Array<MeshUpdateFilter>>;
  color?: InputMaybe<StringFieldComparison>;
  colorObservations?: InputMaybe<StringFieldComparison>;
  composition?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currentQuantity?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  meshKind?: InputMaybe<StringFieldComparison>;
  meshSupplierDocument?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MeshUpdateFilter>>;
  purchaseOrder?: InputMaybe<StringFieldComparison>;
  quantityReceived?: InputMaybe<NumberFieldComparison>;
  quantitySent?: InputMaybe<NumberFieldComparison>;
  quantityUnit?: InputMaybe<StringFieldComparison>;
  spiralityDeviation?: InputMaybe<NumberFieldComparison>;
  spoolCount?: InputMaybe<IntFieldComparison>;
  supplierDocument?: InputMaybe<StringFieldComparison>;
  weightReceived?: InputMaybe<NumberFieldComparison>;
  weightRequired?: InputMaybe<NumberFieldComparison>;
  widthReceived?: InputMaybe<NumberFieldComparison>;
  widthRequired?: InputMaybe<NumberFieldComparison>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAliasesToBrand: Brand;
  addCheckinSampleStatesToUser: User;
  addChecklistItemsToChecklist: Checklist;
  addMeshConsumptionsToMesh: Mesh;
  addMeshLocationsToLocation: Location;
  addMeshLocationsToMesh: Mesh;
  addSampleMeshesToMesh: Mesh;
  addSampleMeshesToSample: Sample;
  addSamplePhotosToSample: Sample;
  addSupplierAliasesToSupplier: Supplier;
  addTicketCommentsToTicket: Ticket;
  addTicketFilesToTicket: Ticket;
  addTicketFilesToTicketComment: TicketComment;
  addUsersToUserType: UserType;
  createManyAliases: Array<Alias>;
  createManyBrands: Array<Brand>;
  createManyLocations: Array<Location>;
  createManyMeshLocations: Array<MeshLocation>;
  createManyNotifications: Array<Notification>;
  createManyPosterCompositions: Array<PosterComposition>;
  createManyPosterFabricTypes: Array<PosterFabricType>;
  createManyPosterSeasons: Array<PosterSeason>;
  createManyPosters: Array<Poster>;
  createManyPurchaseOrderTypes: Array<PurchaseOrderType>;
  createManySampleMeshes: Array<SampleMesh>;
  createManySampleStates: Array<SampleState>;
  createManySampleTypes: Array<SampleType>;
  createManySamples: Array<Sample>;
  createManySupplierAliases: Array<SupplierAlias>;
  createManySuppliers: Array<Supplier>;
  createManyUserTypes: Array<UserType>;
  createManyWashTypes: Array<WashType>;
  createOneAlias: Alias;
  createOneBrand: Brand;
  createOneIssue: Issue;
  createOneLocation: Location;
  createOneMesh: Mesh;
  createOneMeshConsumption: MeshConsumption;
  createOneMeshLocation: MeshLocation;
  createOneNotification: Notification;
  createOnePoster: Poster;
  createOnePosterComposition: PosterComposition;
  createOnePosterFabricType: PosterFabricType;
  createOnePosterSeason: PosterSeason;
  createOnePurchaseOrderType: PurchaseOrderType;
  createOneQuote: Quote;
  createOneSample: Sample;
  createOneSampleMesh: SampleMesh;
  createOneSamplePhoto: SamplePhoto;
  createOneSampleState: SampleState;
  createOneSampleType: SampleType;
  createOneSupplier: Supplier;
  createOneSupplierAlias: SupplierAlias;
  createOneTicket: Ticket;
  createOneTicketComment: TicketComment;
  createOneUser: User;
  createOneUserEvent: UserEvent;
  createOneUserType: UserType;
  createOneWashType: WashType;
  deleteManyAliases: DeleteManyResponse;
  deleteManyBrands: DeleteManyResponse;
  deleteManyMeshLocations: DeleteManyResponse;
  deleteManyMeshes: DeleteManyResponse;
  deleteManyNotifications: DeleteManyResponse;
  deleteManyPosterCompositions: DeleteManyResponse;
  deleteManyPosterFabricTypes: DeleteManyResponse;
  deleteManyPosterSeasons: DeleteManyResponse;
  deleteManyPosters: DeleteManyResponse;
  deleteManyPurchaseOrderTypes: DeleteManyResponse;
  deleteManySampleMeshes: DeleteManyResponse;
  deleteManySampleStates: DeleteManyResponse;
  deleteManySampleTypes: DeleteManyResponse;
  deleteManySamples: DeleteManyResponse;
  deleteManySupplierAliases: DeleteManyResponse;
  deleteManyUserEvents: DeleteManyResponse;
  deleteManyUserTypes: DeleteManyResponse;
  deleteManyWashTypes: DeleteManyResponse;
  deleteOneAlias: AliasDeleteResponse;
  deleteOneBrand: BrandDeleteResponse;
  deleteOneMesh: MeshDeleteResponse;
  deleteOneMeshConsumption: MeshConsumption;
  deleteOneMeshLocation: MeshLocationDeleteResponse;
  deleteOneNotification: NotificationDeleteResponse;
  deleteOnePoster: PosterDeleteResponse;
  deleteOnePosterComposition: PosterCompositionDeleteResponse;
  deleteOnePosterFabricType: PosterFabricTypeDeleteResponse;
  deleteOnePosterSeason: PosterSeasonDeleteResponse;
  deleteOnePurchaseOrderType: PurchaseOrderTypeDeleteResponse;
  deleteOneSample: SampleDeleteResponse;
  deleteOneSampleMesh: SampleMeshDeleteResponse;
  deleteOneSampleState: SampleStateDeleteResponse;
  deleteOneSampleType: SampleTypeDeleteResponse;
  deleteOneSupplierAlias: SupplierAliasDeleteResponse;
  deleteOneUserEvent: UserEventDeleteResponse;
  deleteOneUserType: UserTypeDeleteResponse;
  deleteOneWashType: WashTypeDeleteResponse;
  executeMeshTransfers: Array<MeshTransfer>;
  removeBrandFromAlias: Alias;
  removeDestinationFromMeshTransfer: MeshTransfer;
  removeMeshConsumptionsFromMesh: Mesh;
  removeMeshFromMeshTransfer: MeshTransfer;
  removeMeshLocationsFromMesh: Mesh;
  removeSampleMeshesFromMesh: Mesh;
  removeSupplierAliasesFromSupplier: Supplier;
  removeSupplierFromSupplierAlias: SupplierAlias;
  sendRequestAccessEmail: Scalars['Boolean'];
  sendResetPasswordEmail: Scalars['Boolean'];
  setAliasesOnBrand: Brand;
  setBrandOnAlias: Alias;
  setBrandOnMesh: Mesh;
  setBrandOnSample: Sample;
  setCheckinSampleStatesOnUser: User;
  setCheckinUserOnSampleState: SampleState;
  setChecklistItemsOnChecklist: Checklist;
  setCommercialOnBrand: Brand;
  setConfectionOverseerOnSample: Sample;
  setCurrentStateOnSample: Sample;
  setDestinationOnMeshTransfer: MeshTransfer;
  setInspectedByOnMesh: Mesh;
  setLocationOnMeshLocation: MeshLocation;
  setMeshConsumptionsOnMesh: Mesh;
  setMeshLocationsOnLocation: Location;
  setMeshLocationsOnMesh: Mesh;
  setMeshOnMeshConsumption: MeshConsumption;
  setMeshOnMeshLocation: MeshLocation;
  setMeshOnMeshTransfer: MeshTransfer;
  setMeshOnSampleMesh: SampleMesh;
  setMeshSupplierOnMesh: Mesh;
  setPassword: Scalars['String'];
  setPurchaseOrderTypeOnMesh: Mesh;
  setSampleMeshesOnMesh: Mesh;
  setSampleMeshesOnSample: Sample;
  setSampleOnSampleMesh: SampleMesh;
  setSampleOnSamplePhoto: SamplePhoto;
  setSamplePhotosOnSample: Sample;
  setSampleStateTypeOnSampleState: SampleState;
  setSampleTypeOnSample: Sample;
  setStylistOnBrand: Brand;
  setSupplierAliasesOnSupplier: Supplier;
  setSupplierOnMesh: Mesh;
  setSupplierOnSupplierAlias: SupplierAlias;
  setTicketCommentsOnTicket: Ticket;
  setTicketFilesOnTicket: Ticket;
  setTicketFilesOnTicketComment: TicketComment;
  setTypeOnUser: User;
  setUserOnMeshConsumption: MeshConsumption;
  setUserOnSampleMesh: SampleMesh;
  setUserOnTicket: Ticket;
  setUserOnTicketComment: TicketComment;
  setUserOnUserEvent: UserEvent;
  setUserTypeOnChecklist: Checklist;
  setUsersOnUserType: UserType;
  setWashTypeOnMesh: Mesh;
  updateManyAliases: UpdateManyResponse;
  updateManyBrands: UpdateManyResponse;
  updateManyMeshConsumptions: UpdateManyResponse;
  updateManyMeshLocations: UpdateManyResponse;
  updateManyMeshTransfers: UpdateManyResponse;
  updateManyMeshes: UpdateManyResponse;
  updateManyNotifications: UpdateManyResponse;
  updateManyPosterCompositions: UpdateManyResponse;
  updateManyPosterFabricTypes: UpdateManyResponse;
  updateManyPosterSeasons: UpdateManyResponse;
  updateManyPosters: UpdateManyResponse;
  updateManyPurchaseOrderTypes: UpdateManyResponse;
  updateManySampleMeshes: UpdateManyResponse;
  updateManySampleStates: UpdateManyResponse;
  updateManySampleTypes: UpdateManyResponse;
  updateManySamples: UpdateManyResponse;
  updateManySuppliers: UpdateManyResponse;
  updateManyUserEvents: UpdateManyResponse;
  updateManyUserTypes: UpdateManyResponse;
  updateManyWashTypes: UpdateManyResponse;
  updateOneAlias: Alias;
  updateOneBrand: Brand;
  updateOneMesh: Mesh;
  updateOneMeshConsumption: MeshConsumption;
  updateOneMeshLocation: MeshLocation;
  updateOneMeshTransfer: MeshTransfer;
  updateOneNotification: Notification;
  updateOnePoster: Poster;
  updateOnePosterComposition: PosterComposition;
  updateOnePosterFabricType: PosterFabricType;
  updateOnePosterSeason: PosterSeason;
  updateOnePurchaseOrderType: PurchaseOrderType;
  updateOneSample: Sample;
  updateOneSampleMesh: SampleMesh;
  updateOneSampleState: SampleState;
  updateOneSampleType: SampleType;
  updateOneSupplier: Supplier;
  updateOneUserEvent: UserEvent;
  updateOneUserType: UserType;
  updateOneWashType: WashType;
};


export type MutationAddAliasesToBrandArgs = {
  input: AddAliasesToBrandInput;
};


export type MutationAddCheckinSampleStatesToUserArgs = {
  input: AddCheckinSampleStatesToUserInput;
};


export type MutationAddChecklistItemsToChecklistArgs = {
  input: AddChecklistItemsToChecklistInput;
};


export type MutationAddMeshConsumptionsToMeshArgs = {
  input: AddMeshConsumptionsToMeshInput;
};


export type MutationAddMeshLocationsToLocationArgs = {
  input: AddMeshLocationsToLocationInput;
};


export type MutationAddMeshLocationsToMeshArgs = {
  input: AddMeshLocationsToMeshInput;
};


export type MutationAddSampleMeshesToMeshArgs = {
  input: AddSampleMeshesToMeshInput;
};


export type MutationAddSampleMeshesToSampleArgs = {
  input: AddSampleMeshesToSampleInput;
};


export type MutationAddSamplePhotosToSampleArgs = {
  input: AddSamplePhotosToSampleInput;
};


export type MutationAddSupplierAliasesToSupplierArgs = {
  input: AddSupplierAliasesToSupplierInput;
};


export type MutationAddTicketCommentsToTicketArgs = {
  input: AddTicketCommentsToTicketInput;
};


export type MutationAddTicketFilesToTicketArgs = {
  input: AddTicketFilesToTicketInput;
};


export type MutationAddTicketFilesToTicketCommentArgs = {
  input: AddTicketFilesToTicketCommentInput;
};


export type MutationAddUsersToUserTypeArgs = {
  input: AddUsersToUserTypeInput;
};


export type MutationCreateManyAliasesArgs = {
  input: CreateManyAliasesInput;
};


export type MutationCreateManyBrandsArgs = {
  input: CreateManyBrandsInput;
};


export type MutationCreateManyLocationsArgs = {
  input: CreateManyLocationsInput;
};


export type MutationCreateManyMeshLocationsArgs = {
  input: CreateManyMeshLocationsInput;
};


export type MutationCreateManyNotificationsArgs = {
  input: CreateManyNotificationsInput;
};


export type MutationCreateManyPosterCompositionsArgs = {
  input: CreateManyPosterCompositionsInput;
};


export type MutationCreateManyPosterFabricTypesArgs = {
  input: CreateManyPosterFabricTypesInput;
};


export type MutationCreateManyPosterSeasonsArgs = {
  input: CreateManyPosterSeasonsInput;
};


export type MutationCreateManyPostersArgs = {
  input: CreateManyPostersInput;
};


export type MutationCreateManyPurchaseOrderTypesArgs = {
  input: CreateManyPurchaseOrderTypesInput;
};


export type MutationCreateManySampleMeshesArgs = {
  input: CreateManySampleMeshesInput;
};


export type MutationCreateManySampleStatesArgs = {
  input: CreateManySampleStatesInput;
};


export type MutationCreateManySampleTypesArgs = {
  input: CreateManySampleTypesInput;
};


export type MutationCreateManySamplesArgs = {
  input: CreateManySamplesInput;
};


export type MutationCreateManySupplierAliasesArgs = {
  input: CreateManySupplierAliasesInput;
};


export type MutationCreateManySuppliersArgs = {
  input: CreateManySuppliersInput;
};


export type MutationCreateManyUserTypesArgs = {
  input: CreateManyUserTypesInput;
};


export type MutationCreateManyWashTypesArgs = {
  input: CreateManyWashTypesInput;
};


export type MutationCreateOneAliasArgs = {
  input: CreateOneAliasInput;
};


export type MutationCreateOneBrandArgs = {
  input: CreateOneBrandInput;
};


export type MutationCreateOneIssueArgs = {
  input: CreateOneIssueInput;
};


export type MutationCreateOneLocationArgs = {
  input: CreateOneLocationInput;
};


export type MutationCreateOneMeshArgs = {
  input: CreateOneMeshInput;
};


export type MutationCreateOneMeshConsumptionArgs = {
  input: CreateOneMeshConsumptionInput;
};


export type MutationCreateOneMeshLocationArgs = {
  input: CreateOneMeshLocationInput;
};


export type MutationCreateOneNotificationArgs = {
  input: CreateOneNotificationInput;
};


export type MutationCreateOnePosterArgs = {
  input: CreateOnePosterInput;
};


export type MutationCreateOnePosterCompositionArgs = {
  input: CreateOnePosterCompositionInput;
};


export type MutationCreateOnePosterFabricTypeArgs = {
  input: CreateOnePosterFabricTypeInput;
};


export type MutationCreateOnePosterSeasonArgs = {
  input: CreateOnePosterSeasonInput;
};


export type MutationCreateOnePurchaseOrderTypeArgs = {
  input: CreateOnePurchaseOrderTypeInput;
};


export type MutationCreateOneQuoteArgs = {
  input: CreateOneQuoteInput;
};


export type MutationCreateOneSampleArgs = {
  input: CreateOneSampleInput;
};


export type MutationCreateOneSampleMeshArgs = {
  input: CreateOneSampleMeshInput;
};


export type MutationCreateOneSamplePhotoArgs = {
  input: CreateOneSamplePhotoInput;
};


export type MutationCreateOneSampleStateArgs = {
  input: CreateOneSampleStateInput;
};


export type MutationCreateOneSampleTypeArgs = {
  input: CreateOneSampleTypeInput;
};


export type MutationCreateOneSupplierArgs = {
  input: CreateOneSupplierInput;
};


export type MutationCreateOneSupplierAliasArgs = {
  input: CreateOneSupplierAliasInput;
};


export type MutationCreateOneTicketArgs = {
  input: CreateOneTicketInput;
};


export type MutationCreateOneTicketCommentArgs = {
  input: CreateOneTicketCommentInput;
};


export type MutationCreateOneUserArgs = {
  input: CreateOneUserArgs;
};


export type MutationCreateOneUserEventArgs = {
  input: CreateOneUserEventInput;
};


export type MutationCreateOneUserTypeArgs = {
  input: CreateOneUserTypeInput;
};


export type MutationCreateOneWashTypeArgs = {
  input: CreateOneWashTypeInput;
};


export type MutationDeleteManyAliasesArgs = {
  input: DeleteManyAliasesInput;
};


export type MutationDeleteManyBrandsArgs = {
  input: DeleteManyBrandsInput;
};


export type MutationDeleteManyMeshLocationsArgs = {
  input: DeleteManyMeshLocationsInput;
};


export type MutationDeleteManyMeshesArgs = {
  input: DeleteManyMeshesInput;
};


export type MutationDeleteManyNotificationsArgs = {
  input: DeleteManyNotificationsInput;
};


export type MutationDeleteManyPosterCompositionsArgs = {
  input: DeleteManyPosterCompositionsInput;
};


export type MutationDeleteManyPosterFabricTypesArgs = {
  input: DeleteManyPosterFabricTypesInput;
};


export type MutationDeleteManyPosterSeasonsArgs = {
  input: DeleteManyPosterSeasonsInput;
};


export type MutationDeleteManyPostersArgs = {
  input: DeleteManyPostersInput;
};


export type MutationDeleteManyPurchaseOrderTypesArgs = {
  input: DeleteManyPurchaseOrderTypesInput;
};


export type MutationDeleteManySampleMeshesArgs = {
  input: DeleteManySampleMeshesInput;
};


export type MutationDeleteManySampleStatesArgs = {
  input: DeleteManySampleStatesInput;
};


export type MutationDeleteManySampleTypesArgs = {
  input: DeleteManySampleTypesInput;
};


export type MutationDeleteManySamplesArgs = {
  input: DeleteManySamplesInput;
};


export type MutationDeleteManySupplierAliasesArgs = {
  input: DeleteManySupplierAliasesInput;
};


export type MutationDeleteManyUserEventsArgs = {
  input: DeleteManyUserEventsInput;
};


export type MutationDeleteManyUserTypesArgs = {
  input: DeleteManyUserTypesInput;
};


export type MutationDeleteManyWashTypesArgs = {
  input: DeleteManyWashTypesInput;
};


export type MutationDeleteOneAliasArgs = {
  input: DeleteOneAliasInput;
};


export type MutationDeleteOneBrandArgs = {
  input: DeleteOneBrandInput;
};


export type MutationDeleteOneMeshArgs = {
  input: DeleteOneMeshInput;
};


export type MutationDeleteOneMeshConsumptionArgs = {
  input: DeleteOneMeshConsumptionInput;
};


export type MutationDeleteOneMeshLocationArgs = {
  input: DeleteOneMeshLocationInput;
};


export type MutationDeleteOneNotificationArgs = {
  input: DeleteOneNotificationInput;
};


export type MutationDeleteOnePosterArgs = {
  input: DeleteOnePosterInput;
};


export type MutationDeleteOnePosterCompositionArgs = {
  input: DeleteOnePosterCompositionInput;
};


export type MutationDeleteOnePosterFabricTypeArgs = {
  input: DeleteOnePosterFabricTypeInput;
};


export type MutationDeleteOnePosterSeasonArgs = {
  input: DeleteOnePosterSeasonInput;
};


export type MutationDeleteOnePurchaseOrderTypeArgs = {
  input: DeleteOnePurchaseOrderTypeInput;
};


export type MutationDeleteOneSampleArgs = {
  input: DeleteOneSampleInput;
};


export type MutationDeleteOneSampleMeshArgs = {
  input: DeleteOneSampleMeshInput;
};


export type MutationDeleteOneSampleStateArgs = {
  input: DeleteOneSampleStateInput;
};


export type MutationDeleteOneSampleTypeArgs = {
  input: DeleteOneSampleTypeInput;
};


export type MutationDeleteOneSupplierAliasArgs = {
  input: DeleteOneSupplierAliasInput;
};


export type MutationDeleteOneUserEventArgs = {
  input: DeleteOneUserEventInput;
};


export type MutationDeleteOneUserTypeArgs = {
  input: DeleteOneUserTypeInput;
};


export type MutationDeleteOneWashTypeArgs = {
  input: DeleteOneWashTypeInput;
};


export type MutationExecuteMeshTransfersArgs = {
  input: ExecuteMeshTransfers;
};


export type MutationRemoveBrandFromAliasArgs = {
  input: RemoveBrandFromAliasInput;
};


export type MutationRemoveDestinationFromMeshTransferArgs = {
  input: RemoveDestinationFromMeshTransferInput;
};


export type MutationRemoveMeshConsumptionsFromMeshArgs = {
  input: RemoveMeshConsumptionsFromMeshInput;
};


export type MutationRemoveMeshFromMeshTransferArgs = {
  input: RemoveMeshFromMeshTransferInput;
};


export type MutationRemoveMeshLocationsFromMeshArgs = {
  input: RemoveMeshLocationsFromMeshInput;
};


export type MutationRemoveSampleMeshesFromMeshArgs = {
  input: RemoveSampleMeshesFromMeshInput;
};


export type MutationRemoveSupplierAliasesFromSupplierArgs = {
  input: RemoveSupplierAliasesFromSupplierInput;
};


export type MutationRemoveSupplierFromSupplierAliasArgs = {
  input: RemoveSupplierFromSupplierAliasInput;
};


export type MutationSendRequestAccessEmailArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetAliasesOnBrandArgs = {
  input: SetAliasesOnBrandInput;
};


export type MutationSetBrandOnAliasArgs = {
  input: SetBrandOnAliasInput;
};


export type MutationSetBrandOnMeshArgs = {
  input: SetBrandOnMeshInput;
};


export type MutationSetBrandOnSampleArgs = {
  input: SetBrandOnSampleInput;
};


export type MutationSetCheckinSampleStatesOnUserArgs = {
  input: SetCheckinSampleStatesOnUserInput;
};


export type MutationSetCheckinUserOnSampleStateArgs = {
  input: SetCheckinUserOnSampleStateInput;
};


export type MutationSetChecklistItemsOnChecklistArgs = {
  input: SetChecklistItemsOnChecklistInput;
};


export type MutationSetCommercialOnBrandArgs = {
  input: SetCommercialOnBrandInput;
};


export type MutationSetConfectionOverseerOnSampleArgs = {
  input: SetConfectionOverseerOnSampleInput;
};


export type MutationSetCurrentStateOnSampleArgs = {
  input: SetCurrentStateOnSampleInput;
};


export type MutationSetDestinationOnMeshTransferArgs = {
  input: SetDestinationOnMeshTransferInput;
};


export type MutationSetInspectedByOnMeshArgs = {
  input: SetInspectedByOnMeshInput;
};


export type MutationSetLocationOnMeshLocationArgs = {
  input: SetLocationOnMeshLocationInput;
};


export type MutationSetMeshConsumptionsOnMeshArgs = {
  input: SetMeshConsumptionsOnMeshInput;
};


export type MutationSetMeshLocationsOnLocationArgs = {
  input: SetMeshLocationsOnLocationInput;
};


export type MutationSetMeshLocationsOnMeshArgs = {
  input: SetMeshLocationsOnMeshInput;
};


export type MutationSetMeshOnMeshConsumptionArgs = {
  input: SetMeshOnMeshConsumptionInput;
};


export type MutationSetMeshOnMeshLocationArgs = {
  input: SetMeshOnMeshLocationInput;
};


export type MutationSetMeshOnMeshTransferArgs = {
  input: SetMeshOnMeshTransferInput;
};


export type MutationSetMeshOnSampleMeshArgs = {
  input: SetMeshOnSampleMeshInput;
};


export type MutationSetMeshSupplierOnMeshArgs = {
  input: SetMeshSupplierOnMeshInput;
};


export type MutationSetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSetPurchaseOrderTypeOnMeshArgs = {
  input: SetPurchaseOrderTypeOnMeshInput;
};


export type MutationSetSampleMeshesOnMeshArgs = {
  input: SetSampleMeshesOnMeshInput;
};


export type MutationSetSampleMeshesOnSampleArgs = {
  input: SetSampleMeshesOnSampleInput;
};


export type MutationSetSampleOnSampleMeshArgs = {
  input: SetSampleOnSampleMeshInput;
};


export type MutationSetSampleOnSamplePhotoArgs = {
  input: SetSampleOnSamplePhotoInput;
};


export type MutationSetSamplePhotosOnSampleArgs = {
  input: SetSamplePhotosOnSampleInput;
};


export type MutationSetSampleStateTypeOnSampleStateArgs = {
  input: SetSampleStateTypeOnSampleStateInput;
};


export type MutationSetSampleTypeOnSampleArgs = {
  input: SetSampleTypeOnSampleInput;
};


export type MutationSetStylistOnBrandArgs = {
  input: SetStylistOnBrandInput;
};


export type MutationSetSupplierAliasesOnSupplierArgs = {
  input: SetSupplierAliasesOnSupplierInput;
};


export type MutationSetSupplierOnMeshArgs = {
  input: SetSupplierOnMeshInput;
};


export type MutationSetSupplierOnSupplierAliasArgs = {
  input: SetSupplierOnSupplierAliasInput;
};


export type MutationSetTicketCommentsOnTicketArgs = {
  input: SetTicketCommentsOnTicketInput;
};


export type MutationSetTicketFilesOnTicketArgs = {
  input: SetTicketFilesOnTicketInput;
};


export type MutationSetTicketFilesOnTicketCommentArgs = {
  input: SetTicketFilesOnTicketCommentInput;
};


export type MutationSetTypeOnUserArgs = {
  input: SetTypeOnUserInput;
};


export type MutationSetUserOnMeshConsumptionArgs = {
  input: SetUserOnMeshConsumptionInput;
};


export type MutationSetUserOnSampleMeshArgs = {
  input: SetUserOnSampleMeshInput;
};


export type MutationSetUserOnTicketArgs = {
  input: SetUserOnTicketInput;
};


export type MutationSetUserOnTicketCommentArgs = {
  input: SetUserOnTicketCommentInput;
};


export type MutationSetUserOnUserEventArgs = {
  input: SetUserOnUserEventInput;
};


export type MutationSetUserTypeOnChecklistArgs = {
  input: SetUserTypeOnChecklistInput;
};


export type MutationSetUsersOnUserTypeArgs = {
  input: SetUsersOnUserTypeInput;
};


export type MutationSetWashTypeOnMeshArgs = {
  input: SetWashTypeOnMeshInput;
};


export type MutationUpdateManyAliasesArgs = {
  input: UpdateManyAliasesInput;
};


export type MutationUpdateManyBrandsArgs = {
  input: UpdateManyBrandsInput;
};


export type MutationUpdateManyMeshConsumptionsArgs = {
  input: UpdateManyMeshConsumptionsInput;
};


export type MutationUpdateManyMeshLocationsArgs = {
  input: UpdateManyMeshLocationsInput;
};


export type MutationUpdateManyMeshTransfersArgs = {
  input: UpdateManyMeshTransfersInput;
};


export type MutationUpdateManyMeshesArgs = {
  input: UpdateManyMeshesInput;
};


export type MutationUpdateManyNotificationsArgs = {
  input: UpdateManyNotificationsInput;
};


export type MutationUpdateManyPosterCompositionsArgs = {
  input: UpdateManyPosterCompositionsInput;
};


export type MutationUpdateManyPosterFabricTypesArgs = {
  input: UpdateManyPosterFabricTypesInput;
};


export type MutationUpdateManyPosterSeasonsArgs = {
  input: UpdateManyPosterSeasonsInput;
};


export type MutationUpdateManyPostersArgs = {
  input: UpdateManyPostersInput;
};


export type MutationUpdateManyPurchaseOrderTypesArgs = {
  input: UpdateManyPurchaseOrderTypesInput;
};


export type MutationUpdateManySampleMeshesArgs = {
  input: UpdateManySampleMeshesInput;
};


export type MutationUpdateManySampleStatesArgs = {
  input: UpdateManySampleStatesInput;
};


export type MutationUpdateManySampleTypesArgs = {
  input: UpdateManySampleTypesInput;
};


export type MutationUpdateManySamplesArgs = {
  input: UpdateManySamplesInput;
};


export type MutationUpdateManySuppliersArgs = {
  input: UpdateManySuppliersInput;
};


export type MutationUpdateManyUserEventsArgs = {
  input: UpdateManyUserEventsInput;
};


export type MutationUpdateManyUserTypesArgs = {
  input: UpdateManyUserTypesInput;
};


export type MutationUpdateManyWashTypesArgs = {
  input: UpdateManyWashTypesInput;
};


export type MutationUpdateOneAliasArgs = {
  input: UpdateOneAliasInput;
};


export type MutationUpdateOneBrandArgs = {
  input: UpdateOneBrandInput;
};


export type MutationUpdateOneMeshArgs = {
  input: UpdateOneMeshInput;
};


export type MutationUpdateOneMeshConsumptionArgs = {
  input: UpdateOneMeshConsumptionInput;
};


export type MutationUpdateOneMeshLocationArgs = {
  input: UpdateOneMeshLocationInput;
};


export type MutationUpdateOneMeshTransferArgs = {
  input: UpdateOneMeshTransferInput;
};


export type MutationUpdateOneNotificationArgs = {
  input: UpdateOneNotificationInput;
};


export type MutationUpdateOnePosterArgs = {
  input: UpdateOnePosterInput;
};


export type MutationUpdateOnePosterCompositionArgs = {
  input: UpdateOnePosterCompositionInput;
};


export type MutationUpdateOnePosterFabricTypeArgs = {
  input: UpdateOnePosterFabricTypeInput;
};


export type MutationUpdateOnePosterSeasonArgs = {
  input: UpdateOnePosterSeasonInput;
};


export type MutationUpdateOnePurchaseOrderTypeArgs = {
  input: UpdateOnePurchaseOrderTypeInput;
};


export type MutationUpdateOneSampleArgs = {
  input: UpdateOneSampleInput;
};


export type MutationUpdateOneSampleMeshArgs = {
  input: UpdateOneSampleMeshInput;
};


export type MutationUpdateOneSampleStateArgs = {
  input: UpdateOneSampleStateInput;
};


export type MutationUpdateOneSampleTypeArgs = {
  input: UpdateOneSampleTypeInput;
};


export type MutationUpdateOneSupplierArgs = {
  input: UpdateOneSupplierInput;
};


export type MutationUpdateOneUserEventArgs = {
  input: UpdateOneUserEventInput;
};


export type MutationUpdateOneUserTypeArgs = {
  input: UpdateOneUserTypeInput;
};


export type MutationUpdateOneWashTypeArgs = {
  input: UpdateOneWashTypeInput;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isOpen: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  kind: NotificationKinds;
  message: Scalars['String'];
  priority: NotificationPriorities;
  tab: Scalars['String'];
  templateId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type NotificationAggregateGroupBy = {
  __typename?: 'NotificationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type NotificationAvgAggregate = {
  __typename?: 'NotificationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** Array of nodes. */
  nodes: Array<Notification>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type NotificationCountAggregate = {
  __typename?: 'NotificationCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type NotificationDeleteFilter = {
  and?: InputMaybe<Array<NotificationDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<NotificationDeleteFilter>>;
};

export type NotificationDeleteResponse = {
  __typename?: 'NotificationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  isRead?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<NotificationKinds>;
  message?: Maybe<Scalars['String']>;
  priority?: Maybe<NotificationPriorities>;
  tab?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationFilter = {
  and?: InputMaybe<Array<NotificationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<NotificationFilter>>;
};

export enum NotificationKinds {
  Regular = 'REGULAR',
  StickyBottomLeft = 'STICKY_BOTTOM_LEFT'
}

export type NotificationMaxAggregate = {
  __typename?: 'NotificationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type NotificationMinAggregate = {
  __typename?: 'NotificationMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export enum NotificationPriorities {
  High = 'HIGH',
  Low = 'LOW'
}

export type NotificationSort = {
  direction: SortDirection;
  field: NotificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum NotificationSortFields {
  Id = 'id'
}

export type NotificationSumAggregate = {
  __typename?: 'NotificationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type NotificationUpdateFilter = {
  and?: InputMaybe<Array<NotificationUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<NotificationUpdateFilter>>;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type Poster = {
  __typename?: 'Poster';
  composition: PosterComposition;
  cover: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fabricType: PosterFabricType;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  photo: Scalars['String'];
  reference: Scalars['String'];
  season: PosterSeason;
  specifications: Scalars['String'];
  story: Scalars['String'];
  type: PosterTypes;
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Float'];
};

export type PosterAggregateGroupBy = {
  __typename?: 'PosterAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<PosterTypes>;
  weight?: Maybe<Scalars['Float']>;
};

export type PosterAvgAggregate = {
  __typename?: 'PosterAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PosterComposition = {
  __typename?: 'PosterComposition';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PosterCompositionAggregateGroupBy = {
  __typename?: 'PosterCompositionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterCompositionAvgAggregate = {
  __typename?: 'PosterCompositionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterCompositionConnection = {
  __typename?: 'PosterCompositionConnection';
  /** Array of nodes. */
  nodes: Array<PosterComposition>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PosterCompositionCountAggregate = {
  __typename?: 'PosterCompositionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type PosterCompositionDeleteFilter = {
  and?: InputMaybe<Array<PosterCompositionDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterCompositionDeleteFilter>>;
};

export type PosterCompositionDeleteResponse = {
  __typename?: 'PosterCompositionDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PosterCompositionFilter = {
  and?: InputMaybe<Array<PosterCompositionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterCompositionFilter>>;
};

export type PosterCompositionMaxAggregate = {
  __typename?: 'PosterCompositionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterCompositionMinAggregate = {
  __typename?: 'PosterCompositionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterCompositionSort = {
  direction: SortDirection;
  field: PosterCompositionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PosterCompositionSortFields {
  Id = 'id',
  Name = 'name'
}

export type PosterCompositionSumAggregate = {
  __typename?: 'PosterCompositionSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterCompositionUpdateFilter = {
  and?: InputMaybe<Array<PosterCompositionUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterCompositionUpdateFilter>>;
};

export type PosterConnection = {
  __typename?: 'PosterConnection';
  /** Array of nodes. */
  nodes: Array<Poster>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PosterCountAggregate = {
  __typename?: 'PosterCountAggregate';
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

export type PosterDeleteFilter = {
  and?: InputMaybe<Array<PosterDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PosterDeleteFilter>>;
  reference?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<PosterTypesFilterComparison>;
  weight?: InputMaybe<NumberFieldComparison>;
};

export type PosterDeleteResponse = {
  __typename?: 'PosterDeleteResponse';
  cover?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  specifications?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<PosterTypes>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PosterFabricType = {
  __typename?: 'PosterFabricType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  posterType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PosterFabricTypeAggregateGroupBy = {
  __typename?: 'PosterFabricTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
};

export type PosterFabricTypeAvgAggregate = {
  __typename?: 'PosterFabricTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterFabricTypeConnection = {
  __typename?: 'PosterFabricTypeConnection';
  /** Array of nodes. */
  nodes: Array<PosterFabricType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PosterFabricTypeCountAggregate = {
  __typename?: 'PosterFabricTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  posterType?: Maybe<Scalars['Int']>;
};

export type PosterFabricTypeDeleteFilter = {
  and?: InputMaybe<Array<PosterFabricTypeDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterFabricTypeDeleteFilter>>;
  posterType?: InputMaybe<StringFieldComparison>;
};

export type PosterFabricTypeDeleteResponse = {
  __typename?: 'PosterFabricTypeDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PosterFabricTypeFilter = {
  and?: InputMaybe<Array<PosterFabricTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterFabricTypeFilter>>;
  posterType?: InputMaybe<StringFieldComparison>;
};

export type PosterFabricTypeMaxAggregate = {
  __typename?: 'PosterFabricTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
};

export type PosterFabricTypeMinAggregate = {
  __typename?: 'PosterFabricTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
};

export type PosterFabricTypeSort = {
  direction: SortDirection;
  field: PosterFabricTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PosterFabricTypeSortFields {
  Id = 'id',
  Name = 'name',
  PosterType = 'posterType'
}

export type PosterFabricTypeSumAggregate = {
  __typename?: 'PosterFabricTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterFabricTypeUpdateFilter = {
  and?: InputMaybe<Array<PosterFabricTypeUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterFabricTypeUpdateFilter>>;
  posterType?: InputMaybe<StringFieldComparison>;
};

export type PosterFilter = {
  and?: InputMaybe<Array<PosterFilter>>;
  composition?: InputMaybe<PosterFilterPosterCompositionFilter>;
  fabricType?: InputMaybe<PosterFilterPosterFabricTypeFilter>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PosterFilter>>;
  reference?: InputMaybe<StringFieldComparison>;
  season?: InputMaybe<PosterFilterPosterSeasonFilter>;
  type?: InputMaybe<PosterTypesFilterComparison>;
  weight?: InputMaybe<NumberFieldComparison>;
};

export type PosterFilterPosterCompositionFilter = {
  and?: InputMaybe<Array<PosterFilterPosterCompositionFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterFilterPosterCompositionFilter>>;
};

export type PosterFilterPosterFabricTypeFilter = {
  and?: InputMaybe<Array<PosterFilterPosterFabricTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterFilterPosterFabricTypeFilter>>;
  posterType?: InputMaybe<StringFieldComparison>;
};

export type PosterFilterPosterSeasonFilter = {
  and?: InputMaybe<Array<PosterFilterPosterSeasonFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterFilterPosterSeasonFilter>>;
};

export type PosterMaxAggregate = {
  __typename?: 'PosterMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<PosterTypes>;
  weight?: Maybe<Scalars['Float']>;
};

export type PosterMinAggregate = {
  __typename?: 'PosterMinAggregate';
  id?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<PosterTypes>;
  weight?: Maybe<Scalars['Float']>;
};

export type PosterSeason = {
  __typename?: 'PosterSeason';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PosterSeasonAggregateGroupBy = {
  __typename?: 'PosterSeasonAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterSeasonAvgAggregate = {
  __typename?: 'PosterSeasonAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterSeasonConnection = {
  __typename?: 'PosterSeasonConnection';
  /** Array of nodes. */
  nodes: Array<PosterSeason>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PosterSeasonCountAggregate = {
  __typename?: 'PosterSeasonCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type PosterSeasonDeleteFilter = {
  and?: InputMaybe<Array<PosterSeasonDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterSeasonDeleteFilter>>;
};

export type PosterSeasonDeleteResponse = {
  __typename?: 'PosterSeasonDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PosterSeasonFilter = {
  and?: InputMaybe<Array<PosterSeasonFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterSeasonFilter>>;
};

export type PosterSeasonMaxAggregate = {
  __typename?: 'PosterSeasonMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterSeasonMinAggregate = {
  __typename?: 'PosterSeasonMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterSeasonSort = {
  direction: SortDirection;
  field: PosterSeasonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PosterSeasonSortFields {
  Id = 'id',
  Name = 'name'
}

export type PosterSeasonSumAggregate = {
  __typename?: 'PosterSeasonSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterSeasonUpdateFilter = {
  and?: InputMaybe<Array<PosterSeasonUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PosterSeasonUpdateFilter>>;
};

export type PosterSort = {
  direction: SortDirection;
  field: PosterSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PosterSortFields {
  Id = 'id',
  Reference = 'reference',
  Type = 'type',
  Weight = 'weight'
}

export type PosterSumAggregate = {
  __typename?: 'PosterSumAggregate';
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export enum PosterTypes {
  Finishing = 'FINISHING',
  Mesh = 'MESH'
}

export type PosterTypesFilterComparison = {
  eq?: InputMaybe<PosterTypes>;
  gt?: InputMaybe<PosterTypes>;
  gte?: InputMaybe<PosterTypes>;
  iLike?: InputMaybe<PosterTypes>;
  in?: InputMaybe<Array<PosterTypes>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<PosterTypes>;
  lt?: InputMaybe<PosterTypes>;
  lte?: InputMaybe<PosterTypes>;
  neq?: InputMaybe<PosterTypes>;
  notILike?: InputMaybe<PosterTypes>;
  notIn?: InputMaybe<Array<PosterTypes>>;
  notLike?: InputMaybe<PosterTypes>;
};

export type PosterUpdateFilter = {
  and?: InputMaybe<Array<PosterUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PosterUpdateFilter>>;
  reference?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<PosterTypesFilterComparison>;
  weight?: InputMaybe<NumberFieldComparison>;
};

export type PurchaseOrderType = {
  __typename?: 'PurchaseOrderType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PurchaseOrderTypeAggregateGroupBy = {
  __typename?: 'PurchaseOrderTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseOrderTypeAvgAggregate = {
  __typename?: 'PurchaseOrderTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PurchaseOrderTypeConnection = {
  __typename?: 'PurchaseOrderTypeConnection';
  /** Array of edges. */
  edges: Array<PurchaseOrderTypeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type PurchaseOrderTypeCountAggregate = {
  __typename?: 'PurchaseOrderTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderTypeDeleteFilter = {
  and?: InputMaybe<Array<PurchaseOrderTypeDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PurchaseOrderTypeDeleteFilter>>;
};

export type PurchaseOrderTypeDeleteResponse = {
  __typename?: 'PurchaseOrderTypeDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchaseOrderTypeEdge = {
  __typename?: 'PurchaseOrderTypeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the PurchaseOrderType */
  node: PurchaseOrderType;
};

export type PurchaseOrderTypeFilter = {
  and?: InputMaybe<Array<PurchaseOrderTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PurchaseOrderTypeFilter>>;
};

export type PurchaseOrderTypeMaxAggregate = {
  __typename?: 'PurchaseOrderTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseOrderTypeMinAggregate = {
  __typename?: 'PurchaseOrderTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseOrderTypeSort = {
  direction: SortDirection;
  field: PurchaseOrderTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PurchaseOrderTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type PurchaseOrderTypeSumAggregate = {
  __typename?: 'PurchaseOrderTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PurchaseOrderTypeUpdateFilter = {
  and?: InputMaybe<Array<PurchaseOrderTypeUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PurchaseOrderTypeUpdateFilter>>;
};

export type Query = {
  __typename?: 'Query';
  alias?: Maybe<Alias>;
  aliases: AliasConnection;
  autocompleteMeshes: Array<AutocompleteMesh>;
  brand?: Maybe<Brand>;
  brands: BrandConnection;
  checklist?: Maybe<Checklist>;
  checklistItem?: Maybe<ChecklistItem>;
  checklistItems: ChecklistItemConnection;
  checklists: ChecklistConnection;
  commonIssues: CommonIssueConnection;
  configuration?: Maybe<Configuration>;
  configurations: ConfigurationConnection;
  location?: Maybe<Location>;
  locations: LocationConnection;
  me: User;
  mesh?: Maybe<Mesh>;
  meshConsumption?: Maybe<MeshConsumption>;
  meshConsumptions: MeshConsumptionConnection;
  meshLocation?: Maybe<MeshLocation>;
  meshLocations: MeshLocationConnection;
  meshTransfer?: Maybe<MeshTransfer>;
  meshTransfers: MeshTransferConnection;
  meshes: MeshConnection;
  notification?: Maybe<Notification>;
  notifications: NotificationConnection;
  poster?: Maybe<Poster>;
  posterComposition?: Maybe<PosterComposition>;
  posterCompositions: PosterCompositionConnection;
  posterFabricType?: Maybe<PosterFabricType>;
  posterFabricTypes: PosterFabricTypeConnection;
  posterSeason?: Maybe<PosterSeason>;
  posterSeasons: PosterSeasonConnection;
  posters: PosterConnection;
  purchaseOrderType?: Maybe<PurchaseOrderType>;
  purchaseOrderTypes: PurchaseOrderTypeConnection;
  sample?: Maybe<Sample>;
  sampleMesh?: Maybe<SampleMesh>;
  sampleMeshes: SampleMeshConnection;
  sampleState?: Maybe<SampleState>;
  sampleStates: SampleStateConnection;
  sampleType?: Maybe<SampleType>;
  sampleTypes: SampleTypeConnection;
  samples: SampleConnection;
  status: Scalars['String'];
  supplier?: Maybe<Supplier>;
  supplierAlias?: Maybe<SupplierAlias>;
  supplierAliases: SupplierAliasConnection;
  suppliers: SupplierConnection;
  supportTopic?: Maybe<SupportTopic>;
  supportTopics: SupportTopicConnection;
  ticket?: Maybe<Ticket>;
  ticketComment?: Maybe<TicketComment>;
  ticketComments: TicketCommentConnection;
  tickets: TicketConnection;
  user?: Maybe<User>;
  userEvent?: Maybe<UserEvent>;
  userEvents: UserEventConnection;
  userType?: Maybe<UserType>;
  userTypes: UserTypeConnection;
  users: UserConnection;
  washType?: Maybe<WashType>;
  washTypes: WashTypeConnection;
};


export type QueryAliasArgs = {
  id: Scalars['ID'];
};


export type QueryAliasesArgs = {
  filter?: InputMaybe<AliasFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<AliasSort>>;
};


export type QueryAutocompleteMeshesArgs = {
  fullPurchaseOrder: Scalars['String'];
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryBrandsArgs = {
  filter?: InputMaybe<BrandFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<BrandSort>>;
};


export type QueryChecklistArgs = {
  id: Scalars['ID'];
};


export type QueryChecklistItemArgs = {
  id: Scalars['ID'];
};


export type QueryChecklistItemsArgs = {
  filter?: InputMaybe<ChecklistItemFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ChecklistItemSort>>;
};


export type QueryChecklistsArgs = {
  filter?: InputMaybe<ChecklistFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ChecklistSort>>;
};


export type QueryCommonIssuesArgs = {
  filter?: InputMaybe<CommonIssueFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CommonIssueSort>>;
};


export type QueryConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryConfigurationsArgs = {
  filter?: InputMaybe<ConfigurationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ConfigurationSort>>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  filter?: InputMaybe<LocationFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<LocationSort>>;
};


export type QueryMeshArgs = {
  id: Scalars['ID'];
};


export type QueryMeshConsumptionArgs = {
  id: Scalars['ID'];
};


export type QueryMeshConsumptionsArgs = {
  filter?: InputMaybe<MeshConsumptionFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<MeshConsumptionSort>>;
};


export type QueryMeshLocationArgs = {
  id: Scalars['ID'];
};


export type QueryMeshLocationsArgs = {
  filter?: InputMaybe<MeshLocationFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<MeshLocationSort>>;
};


export type QueryMeshTransferArgs = {
  id: Scalars['ID'];
};


export type QueryMeshTransfersArgs = {
  filter?: InputMaybe<MeshTransferFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<MeshTransferSort>>;
};


export type QueryMeshesArgs = {
  filter?: InputMaybe<MeshFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<MeshSort>>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<NotificationSort>>;
};


export type QueryPosterArgs = {
  id: Scalars['ID'];
};


export type QueryPosterCompositionArgs = {
  id: Scalars['ID'];
};


export type QueryPosterCompositionsArgs = {
  filter?: InputMaybe<PosterCompositionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PosterCompositionSort>>;
};


export type QueryPosterFabricTypeArgs = {
  id: Scalars['ID'];
};


export type QueryPosterFabricTypesArgs = {
  filter?: InputMaybe<PosterFabricTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PosterFabricTypeSort>>;
};


export type QueryPosterSeasonArgs = {
  id: Scalars['ID'];
};


export type QueryPosterSeasonsArgs = {
  filter?: InputMaybe<PosterSeasonFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PosterSeasonSort>>;
};


export type QueryPostersArgs = {
  filter?: InputMaybe<PosterFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PosterSort>>;
};


export type QueryPurchaseOrderTypeArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderTypesArgs = {
  filter?: InputMaybe<PurchaseOrderTypeFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PurchaseOrderTypeSort>>;
};


export type QuerySampleArgs = {
  id: Scalars['ID'];
};


export type QuerySampleMeshArgs = {
  id: Scalars['ID'];
};


export type QuerySampleMeshesArgs = {
  filter?: InputMaybe<SampleMeshFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SampleMeshSort>>;
};


export type QuerySampleStateArgs = {
  id: Scalars['ID'];
};


export type QuerySampleStatesArgs = {
  filter?: InputMaybe<SampleStateFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SampleStateSort>>;
};


export type QuerySampleTypeArgs = {
  id: Scalars['ID'];
};


export type QuerySampleTypesArgs = {
  filter?: InputMaybe<SampleTypeFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SampleTypeSort>>;
};


export type QuerySamplesArgs = {
  filter?: InputMaybe<SampleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SampleSort>>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierAliasArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierAliasesArgs = {
  filter?: InputMaybe<SupplierAliasFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SupplierAliasSort>>;
};


export type QuerySuppliersArgs = {
  filter?: InputMaybe<SupplierFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SupplierSort>>;
};


export type QuerySupportTopicArgs = {
  id: Scalars['ID'];
};


export type QuerySupportTopicsArgs = {
  filter?: InputMaybe<SupportTopicFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<SupportTopicSort>>;
};


export type QueryTicketArgs = {
  id: Scalars['ID'];
};


export type QueryTicketCommentArgs = {
  id: Scalars['ID'];
};


export type QueryTicketCommentsArgs = {
  filter?: InputMaybe<TicketCommentFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<TicketCommentSort>>;
};


export type QueryTicketsArgs = {
  filter?: InputMaybe<TicketFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<TicketSort>>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserEventArgs = {
  id: Scalars['ID'];
};


export type QueryUserEventsArgs = {
  filter?: InputMaybe<UserEventFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserEventSort>>;
};


export type QueryUserTypeArgs = {
  id: Scalars['ID'];
};


export type QueryUserTypesArgs = {
  filter?: InputMaybe<UserTypeFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<UserTypeSort>>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryWashTypeArgs = {
  id: Scalars['ID'];
};


export type QueryWashTypesArgs = {
  filter?: InputMaybe<WashTypeFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<WashTypeSort>>;
};

export type Quote = {
  __typename?: 'Quote';
  address: Scalars['String'];
  attachments?: Maybe<Array<Scalars['Upload']>>;
  brand: Scalars['String'];
  categories: Array<Scalars['String']>;
  city: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  estimatedQuantityYear?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  name: Scalars['String'];
  orderQuantityColor?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  productGroup: Array<Scalars['String']>;
  requestType: Array<Scalars['String']>;
  specification: Scalars['String'];
  zipCode: Scalars['String'];
};

export type RemoveBrandFromAliasInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveDestinationFromMeshTransferInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMeshConsumptionsFromMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMeshFromMeshTransferInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMeshLocationsFromMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSampleMeshesFromMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSupplierAliasesFromSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSupplierFromSupplierAliasInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type Sample = {
  __typename?: 'Sample';
  brand: Brand;
  clientReference?: Maybe<Scalars['String']>;
  confectionOverseer?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  currentState: SampleState;
  decorationsReceivedAt: Scalars['String'];
  fastTrack: Scalars['Boolean'];
  foreseenDate?: Maybe<Scalars['DateTime']>;
  foreseenDateExceededReason: Scalars['String'];
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt: Scalars['String'];
  hasMesh: Scalars['Boolean'];
  hasMeshUpdatedAt: Scalars['String'];
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt: Scalars['String'];
  id: Scalars['ID'];
  intendedDate: Scalars['DateTime'];
  isArchived: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  observations?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  sampleMeshes: Array<SampleMesh>;
  samplePhotos: Array<SamplePhoto>;
  sampleType: SampleType;
  shippingDate: Scalars['String'];
  sketch: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type SampleSampleMeshesArgs = {
  filter?: InputMaybe<SampleMeshFilter>;
  sorting?: InputMaybe<Array<SampleMeshSort>>;
};


export type SampleSamplePhotosArgs = {
  filter?: InputMaybe<SamplePhotoFilter>;
  sorting?: InputMaybe<Array<SamplePhotoSort>>;
};

export type SampleAggregateGroupBy = {
  __typename?: 'SampleAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleAvgAggregate = {
  __typename?: 'SampleAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleConnection = {
  __typename?: 'SampleConnection';
  /** Array of nodes. */
  nodes: Array<Sample>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SampleCountAggregate = {
  __typename?: 'SampleCountAggregate';
  id?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Int']>;
  isVirtual?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  orderReference?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type SampleDeleteFilter = {
  and?: InputMaybe<Array<SampleDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isArchived?: InputMaybe<BooleanFieldComparison>;
  isVirtual?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<SampleDeleteFilter>>;
  order?: InputMaybe<StringFieldComparison>;
  orderReference?: InputMaybe<NumberFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
};

export type SampleDeleteResponse = {
  __typename?: 'SampleDeleteResponse';
  clientReference?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  decorationsReceivedAt?: Maybe<Scalars['String']>;
  fastTrack?: Maybe<Scalars['Boolean']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: Maybe<Scalars['String']>;
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  hasMesh?: Maybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: Maybe<Scalars['String']>;
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  intendedDate?: Maybe<Scalars['DateTime']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  observations?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  shippingDate?: Maybe<Scalars['String']>;
  sketch?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SampleFilter = {
  and?: InputMaybe<Array<SampleFilter>>;
  brand?: InputMaybe<SampleFilterBrandFilter>;
  currentState?: InputMaybe<SampleFilterSampleStateFilter>;
  id?: InputMaybe<IdFilterComparison>;
  isArchived?: InputMaybe<BooleanFieldComparison>;
  isVirtual?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<SampleFilter>>;
  order?: InputMaybe<StringFieldComparison>;
  orderReference?: InputMaybe<NumberFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  sampleType?: InputMaybe<SampleFilterSampleTypeFilter>;
};

export type SampleFilterBrandFilter = {
  and?: InputMaybe<Array<SampleFilterBrandFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isActive?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SampleFilterBrandFilter>>;
};

export type SampleFilterSampleStateFilter = {
  and?: InputMaybe<Array<SampleFilterSampleStateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SampleFilterSampleStateFilter>>;
  sampleStateTypeId?: InputMaybe<NumberFieldComparison>;
};

export type SampleFilterSampleTypeFilter = {
  and?: InputMaybe<Array<SampleFilterSampleTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isVirtual?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SampleFilterSampleTypeFilter>>;
};

export type SampleInputType = {
  clientReference?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  decorationsReceivedAt: Scalars['String'];
  fastTrack: Scalars['Boolean'];
  foreseenDate?: InputMaybe<Scalars['DateTime']>;
  foreseenDateExceededReason: Scalars['String'];
  hasConfectionAccessories?: InputMaybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt: Scalars['String'];
  hasMesh: Scalars['Boolean'];
  hasMeshUpdatedAt: Scalars['String'];
  hasPackingAccessories?: InputMaybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt: Scalars['String'];
  id: Scalars['ID'];
  intendedDate: Scalars['DateTime'];
  isArchived: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  observations?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  orderReference?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  shippingDate: Scalars['String'];
  sketch: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SampleMaxAggregate = {
  __typename?: 'SampleMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleMesh = {
  __typename?: 'SampleMesh';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  mesh: Mesh;
  sample: Sample;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type SampleMeshAggregateGroupBy = {
  __typename?: 'SampleMeshAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshAvgAggregate = {
  __typename?: 'SampleMeshAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshConnection = {
  __typename?: 'SampleMeshConnection';
  /** Array of edges. */
  edges: Array<SampleMeshEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SampleMeshCountAggregate = {
  __typename?: 'SampleMeshCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type SampleMeshDeleteFilter = {
  and?: InputMaybe<Array<SampleMeshDeleteFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<SampleMeshDeleteFilter>>;
};

export type SampleMeshDeleteResponse = {
  __typename?: 'SampleMeshDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SampleMeshEdge = {
  __typename?: 'SampleMeshEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the SampleMesh */
  node: SampleMesh;
};

export type SampleMeshFilter = {
  and?: InputMaybe<Array<SampleMeshFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<SampleMeshFilter>>;
};

export type SampleMeshMaxAggregate = {
  __typename?: 'SampleMeshMaxAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshMinAggregate = {
  __typename?: 'SampleMeshMinAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshSort = {
  direction: SortDirection;
  field: SampleMeshSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SampleMeshSortFields {
  Id = 'id'
}

export type SampleMeshSumAggregate = {
  __typename?: 'SampleMeshSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshUpdateFilter = {
  and?: InputMaybe<Array<SampleMeshUpdateFilter>>;
  id?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<SampleMeshUpdateFilter>>;
};

export type SampleMinAggregate = {
  __typename?: 'SampleMinAggregate';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SamplePhoto = {
  __typename?: 'SamplePhoto';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  photo: Scalars['String'];
  sample: Sample;
  updatedAt: Scalars['DateTime'];
};

export type SamplePhotoAggregateGroupBy = {
  __typename?: 'SamplePhotoAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SamplePhotoAvgAggregate = {
  __typename?: 'SamplePhotoAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SamplePhotoCountAggregate = {
  __typename?: 'SamplePhotoCountAggregate';
  id?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['Int']>;
};

export type SamplePhotoEdge = {
  __typename?: 'SamplePhotoEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the SamplePhoto */
  node: SamplePhoto;
};

export type SamplePhotoFilter = {
  and?: InputMaybe<Array<SamplePhotoFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SamplePhotoFilter>>;
  photo?: InputMaybe<StringFieldComparison>;
};

export type SamplePhotoMaxAggregate = {
  __typename?: 'SamplePhotoMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SamplePhotoMinAggregate = {
  __typename?: 'SamplePhotoMinAggregate';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SamplePhotoSort = {
  direction: SortDirection;
  field: SamplePhotoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SamplePhotoSortFields {
  Id = 'id',
  Photo = 'photo'
}

export type SamplePhotoSumAggregate = {
  __typename?: 'SamplePhotoSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleSort = {
  direction: SortDirection;
  field: SampleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SampleSortFields {
  Id = 'id',
  IsArchived = 'isArchived',
  IsVirtual = 'isVirtual',
  Order = 'order',
  OrderReference = 'orderReference',
  Quantity = 'quantity'
}

export type SampleState = {
  __typename?: 'SampleState';
  checkinUser?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  sampleStateType: SampleStateType;
  sampleStateTypeId: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type SampleStateAggregateGroupBy = {
  __typename?: 'SampleStateAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateAvgAggregate = {
  __typename?: 'SampleStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateConnection = {
  __typename?: 'SampleStateConnection';
  /** Array of edges. */
  edges: Array<SampleStateEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SampleStateCountAggregate = {
  __typename?: 'SampleStateCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sampleStateTypeId?: Maybe<Scalars['Int']>;
};

export type SampleStateDeleteFilter = {
  and?: InputMaybe<Array<SampleStateDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SampleStateDeleteFilter>>;
  sampleStateTypeId?: InputMaybe<NumberFieldComparison>;
};

export type SampleStateDeleteResponse = {
  __typename?: 'SampleStateDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SampleStateEdge = {
  __typename?: 'SampleStateEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the SampleState */
  node: SampleState;
};

export type SampleStateFilter = {
  and?: InputMaybe<Array<SampleStateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SampleStateFilter>>;
  sampleStateType?: InputMaybe<SampleStateFilterSampleStateTypeFilter>;
  sampleStateTypeId?: InputMaybe<NumberFieldComparison>;
};

export type SampleStateFilterSampleStateTypeFilter = {
  and?: InputMaybe<Array<SampleStateFilterSampleStateTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SampleStateFilterSampleStateTypeFilter>>;
};

export type SampleStateMaxAggregate = {
  __typename?: 'SampleStateMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateMinAggregate = {
  __typename?: 'SampleStateMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateSort = {
  direction: SortDirection;
  field: SampleStateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SampleStateSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  SampleStateTypeId = 'sampleStateTypeId'
}

export type SampleStateSumAggregate = {
  __typename?: 'SampleStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateType = {
  __typename?: 'SampleStateType';
  color: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type SampleStateUpdateFilter = {
  and?: InputMaybe<Array<SampleStateUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SampleStateUpdateFilter>>;
  sampleStateTypeId?: InputMaybe<NumberFieldComparison>;
};

export type SampleSumAggregate = {
  __typename?: 'SampleSumAggregate';
  id?: Maybe<Scalars['Float']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleType = {
  __typename?: 'SampleType';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  isVirtual: Scalars['Boolean'];
  name: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type SampleTypeAggregateGroupBy = {
  __typename?: 'SampleTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type SampleTypeAvgAggregate = {
  __typename?: 'SampleTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleTypeConnection = {
  __typename?: 'SampleTypeConnection';
  /** Array of edges. */
  edges: Array<SampleTypeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SampleTypeCountAggregate = {
  __typename?: 'SampleTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  isVirtual?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type SampleTypeDeleteFilter = {
  and?: InputMaybe<Array<SampleTypeDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isVirtual?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SampleTypeDeleteFilter>>;
};

export type SampleTypeDeleteResponse = {
  __typename?: 'SampleTypeDeleteResponse';
  created?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type SampleTypeEdge = {
  __typename?: 'SampleTypeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the SampleType */
  node: SampleType;
};

export type SampleTypeFilter = {
  and?: InputMaybe<Array<SampleTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isVirtual?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SampleTypeFilter>>;
};

export type SampleTypeInput = {
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  isVirtual: Scalars['Boolean'];
  name: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type SampleTypeMaxAggregate = {
  __typename?: 'SampleTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SampleTypeMinAggregate = {
  __typename?: 'SampleTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SampleTypeSort = {
  direction: SortDirection;
  field: SampleTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SampleTypeSortFields {
  Id = 'id',
  IsVirtual = 'isVirtual',
  Name = 'name'
}

export type SampleTypeSumAggregate = {
  __typename?: 'SampleTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleTypeUpdateFilter = {
  and?: InputMaybe<Array<SampleTypeUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isVirtual?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SampleTypeUpdateFilter>>;
};

export type SampleUpdateFilter = {
  and?: InputMaybe<Array<SampleUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isArchived?: InputMaybe<BooleanFieldComparison>;
  isVirtual?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<SampleUpdateFilter>>;
  order?: InputMaybe<StringFieldComparison>;
  orderReference?: InputMaybe<NumberFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
};

export type SetAliasesOnBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetBrandOnAliasInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetBrandOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetBrandOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCheckinSampleStatesOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetCheckinUserOnSampleStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetChecklistItemsOnChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetCommercialOnBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConfectionOverseerOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCurrentStateOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDestinationOnMeshTransferInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetInspectedByOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetLocationOnMeshLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshConsumptionsOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMeshLocationsOnLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMeshLocationsOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMeshOnMeshConsumptionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshOnMeshLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshOnMeshTransferInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshOnSampleMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshSupplierOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetPurchaseOrderTypeOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSampleMeshesOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSampleMeshesOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSampleOnSampleMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSampleOnSamplePhotoInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSamplePhotosOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSampleStateTypeOnSampleStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSampleTypeOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetStylistOnBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSupplierAliasesOnSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSupplierOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSupplierOnSupplierAliasInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetTicketCommentsOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTicketFilesOnTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTicketFilesOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTypeOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnMeshConsumptionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnSampleMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnUserEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserTypeOnChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUsersOnUserTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWashTypeOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  notILike?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  notLike?: InputMaybe<Scalars['String']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isMeshSupplier: Scalars['Boolean'];
  isSupplier: Scalars['Boolean'];
  name: Scalars['String'];
  supplierAliases: Array<SupplierAlias>;
  updatedAt: Scalars['DateTime'];
};


export type SupplierSupplierAliasesArgs = {
  filter?: InputMaybe<SupplierAliasFilter>;
  sorting?: InputMaybe<Array<SupplierAliasSort>>;
};

export type SupplierAggregateGroupBy = {
  __typename?: 'SupplierAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isMeshSupplier?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierAlias = {
  __typename?: 'SupplierAlias';
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['ID'];
  supplier: Supplier;
  updatedAt: Scalars['DateTime'];
};

export type SupplierAliasAggregateGroupBy = {
  __typename?: 'SupplierAliasAggregateGroupBy';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierAliasAvgAggregate = {
  __typename?: 'SupplierAliasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierAliasConnection = {
  __typename?: 'SupplierAliasConnection';
  /** Array of nodes. */
  nodes: Array<SupplierAlias>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SupplierAliasCountAggregate = {
  __typename?: 'SupplierAliasCountAggregate';
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type SupplierAliasDeleteFilter = {
  and?: InputMaybe<Array<SupplierAliasDeleteFilter>>;
  externalId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SupplierAliasDeleteFilter>>;
};

export type SupplierAliasDeleteResponse = {
  __typename?: 'SupplierAliasDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierAliasFilter = {
  and?: InputMaybe<Array<SupplierAliasFilter>>;
  externalId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SupplierAliasFilter>>;
  supplier?: InputMaybe<SupplierAliasFilterSupplierFilter>;
};

export type SupplierAliasFilterSupplierFilter = {
  and?: InputMaybe<Array<SupplierAliasFilterSupplierFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isMeshSupplier?: InputMaybe<BooleanFieldComparison>;
  isSupplier?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SupplierAliasFilterSupplierFilter>>;
};

export type SupplierAliasMaxAggregate = {
  __typename?: 'SupplierAliasMaxAggregate';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierAliasMinAggregate = {
  __typename?: 'SupplierAliasMinAggregate';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierAliasSort = {
  direction: SortDirection;
  field: SupplierAliasSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SupplierAliasSortFields {
  ExternalId = 'externalId',
  Id = 'id'
}

export type SupplierAliasSumAggregate = {
  __typename?: 'SupplierAliasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierAvgAggregate = {
  __typename?: 'SupplierAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  /** Array of edges. */
  edges: Array<SupplierEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SupplierCountAggregate = {
  __typename?: 'SupplierCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isMeshSupplier?: Maybe<Scalars['Int']>;
  isSupplier?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Supplier */
  node: Supplier;
};

export type SupplierFilter = {
  and?: InputMaybe<Array<SupplierFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isMeshSupplier?: InputMaybe<BooleanFieldComparison>;
  isSupplier?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SupplierFilter>>;
};

export type SupplierInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isMeshSupplier: Scalars['Boolean'];
  isSupplier: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SupplierMaxAggregate = {
  __typename?: 'SupplierMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierMinAggregate = {
  __typename?: 'SupplierMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierSort = {
  direction: SortDirection;
  field: SupplierSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SupplierSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsMeshSupplier = 'isMeshSupplier',
  IsSupplier = 'isSupplier',
  Name = 'name'
}

export type SupplierSumAggregate = {
  __typename?: 'SupplierSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierUpdateFilter = {
  and?: InputMaybe<Array<SupplierUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isMeshSupplier?: InputMaybe<BooleanFieldComparison>;
  isSupplier?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SupplierUpdateFilter>>;
};

export type SupportTopic = {
  __typename?: 'SupportTopic';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  loom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SupportTopicAggregateGroupBy = {
  __typename?: 'SupportTopicAggregateGroupBy';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SupportTopicConnection = {
  __typename?: 'SupportTopicConnection';
  /** Array of edges. */
  edges: Array<SupportTopicEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SupportTopicCountAggregate = {
  __typename?: 'SupportTopicCountAggregate';
  description?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type SupportTopicEdge = {
  __typename?: 'SupportTopicEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the SupportTopic */
  node: SupportTopic;
};

export type SupportTopicFilter = {
  and?: InputMaybe<Array<SupportTopicFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SupportTopicFilter>>;
  state?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
};

export type SupportTopicMaxAggregate = {
  __typename?: 'SupportTopicMaxAggregate';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SupportTopicMinAggregate = {
  __typename?: 'SupportTopicMinAggregate';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SupportTopicSort = {
  direction: SortDirection;
  field: SupportTopicSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SupportTopicSortFields {
  Description = 'description',
  Name = 'name',
  State = 'state',
  Type = 'type'
}

export type Ticket = {
  __typename?: 'Ticket';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  state: Scalars['String'];
  ticketComments?: Maybe<Array<TicketComment>>;
  ticketFiles?: Maybe<Array<TicketFile>>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};


export type TicketTicketCommentsArgs = {
  filter?: InputMaybe<TicketCommentFilter>;
  sorting?: InputMaybe<Array<TicketCommentSort>>;
};


export type TicketTicketFilesArgs = {
  filter?: InputMaybe<TicketFileFilter>;
  sorting?: InputMaybe<Array<TicketFileSort>>;
};

export type TicketAggregateGroupBy = {
  __typename?: 'TicketAggregateGroupBy';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TicketAvgAggregate = {
  __typename?: 'TicketAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type TicketComment = {
  __typename?: 'TicketComment';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  ticketFiles?: Maybe<Array<TicketFile>>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};


export type TicketCommentTicketFilesArgs = {
  filter?: InputMaybe<TicketFileFilter>;
  sorting?: InputMaybe<Array<TicketFileSort>>;
};

export type TicketCommentAggregateGroupBy = {
  __typename?: 'TicketCommentAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketCommentConnection = {
  __typename?: 'TicketCommentConnection';
  /** Array of edges. */
  edges: Array<TicketCommentEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type TicketCommentCountAggregate = {
  __typename?: 'TicketCommentCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
};

export type TicketCommentEdge = {
  __typename?: 'TicketCommentEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the TicketComment */
  node: TicketComment;
};

export type TicketCommentFilter = {
  and?: InputMaybe<Array<TicketCommentFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<TicketCommentFilter>>;
};

export type TicketCommentMaxAggregate = {
  __typename?: 'TicketCommentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketCommentMinAggregate = {
  __typename?: 'TicketCommentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketCommentSort = {
  direction: SortDirection;
  field: TicketCommentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TicketCommentSortFields {
  CreatedAt = 'createdAt'
}

export type TicketConnection = {
  __typename?: 'TicketConnection';
  /** Array of edges. */
  edges: Array<TicketEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type TicketCountAggregate = {
  __typename?: 'TicketCountAggregate';
  description?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
};

export type TicketEdge = {
  __typename?: 'TicketEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Ticket */
  node: Ticket;
};

export type TicketFile = {
  __typename?: 'TicketFile';
  createdAt: Scalars['DateTime'];
  file: Scalars['String'];
  id: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type TicketFileFilter = {
  and?: InputMaybe<Array<TicketFileFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<TicketFileFilter>>;
};

export type TicketFileSort = {
  direction: SortDirection;
  field: TicketFileSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TicketFileSortFields {
  CreatedAt = 'createdAt'
}

export type TicketFilter = {
  and?: InputMaybe<Array<TicketFilter>>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<NumberFieldComparison>;
  isPublic?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TicketFilter>>;
  state?: InputMaybe<StringFieldComparison>;
  user?: InputMaybe<TicketFilterUserFilter>;
};

export type TicketFilterUserFilter = {
  and?: InputMaybe<Array<TicketFilterUserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TicketFilterUserFilter>>;
};

export type TicketMaxAggregate = {
  __typename?: 'TicketMaxAggregate';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TicketMinAggregate = {
  __typename?: 'TicketMinAggregate';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TicketSort = {
  direction: SortDirection;
  field: TicketSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TicketSortFields {
  Description = 'description',
  Id = 'id',
  IsPublic = 'isPublic',
  Name = 'name',
  State = 'state'
}

export type TicketSumAggregate = {
  __typename?: 'TicketSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UpdateAlias = {
  alias?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateBrand = {
  avatar?: InputMaybe<Scalars['String']>;
  commercial?: InputMaybe<UserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  stylist?: InputMaybe<UserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateManyAliasesInput = {
  /** Filter used to find fields to update */
  filter: AliasUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateAlias;
};

export type UpdateManyBrandsInput = {
  /** Filter used to find fields to update */
  filter: BrandUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateBrand;
};

export type UpdateManyMeshConsumptionsInput = {
  /** Filter used to find fields to update */
  filter: MeshConsumptionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMeshConsumption;
};

export type UpdateManyMeshLocationsInput = {
  /** Filter used to find fields to update */
  filter: MeshLocationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMeshLocation;
};

export type UpdateManyMeshTransfersInput = {
  /** Filter used to find fields to update */
  filter: MeshTransferUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMeshTransfer;
};

export type UpdateManyMeshesInput = {
  /** Filter used to find fields to update */
  filter: MeshUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMesh;
};

export type UpdateManyNotificationsInput = {
  /** Filter used to find fields to update */
  filter: NotificationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateNotification;
};

export type UpdateManyPosterCompositionsInput = {
  /** Filter used to find fields to update */
  filter: PosterCompositionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePosterComposition;
};

export type UpdateManyPosterFabricTypesInput = {
  /** Filter used to find fields to update */
  filter: PosterFabricTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePosterFabricType;
};

export type UpdateManyPosterSeasonsInput = {
  /** Filter used to find fields to update */
  filter: PosterSeasonUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePosterSeason;
};

export type UpdateManyPostersInput = {
  /** Filter used to find fields to update */
  filter: PosterUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePoster;
};

export type UpdateManyPurchaseOrderTypesInput = {
  /** Filter used to find fields to update */
  filter: PurchaseOrderTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePurchaseOrderType;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateManySampleMeshesInput = {
  /** Filter used to find fields to update */
  filter: SampleMeshUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleMesh;
};

export type UpdateManySampleStatesInput = {
  /** Filter used to find fields to update */
  filter: SampleStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleState;
};

export type UpdateManySampleTypesInput = {
  /** Filter used to find fields to update */
  filter: SampleTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleType;
};

export type UpdateManySamplesInput = {
  /** Filter used to find fields to update */
  filter: SampleUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSample;
};

export type UpdateManySuppliersInput = {
  /** Filter used to find fields to update */
  filter: SupplierUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSupplier;
};

export type UpdateManyUserEventsInput = {
  /** Filter used to find fields to update */
  filter: UserEventUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUserEvent;
};

export type UpdateManyUserTypesInput = {
  /** Filter used to find fields to update */
  filter: UserTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUserType;
};

export type UpdateManyWashTypesInput = {
  /** Filter used to find fields to update */
  filter: WashTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateWashType;
};

export type UpdateMesh = {
  brand?: InputMaybe<Scalars['ID']>;
  color?: InputMaybe<Scalars['String']>;
  colorObservations?: InputMaybe<Scalars['String']>;
  composition?: InputMaybe<Scalars['String']>;
  debitedToClient?: InputMaybe<Scalars['Boolean']>;
  inspectionDate?: InputMaybe<Scalars['DateTime']>;
  lengthAfterWash?: InputMaybe<Scalars['Float']>;
  lengthBeforeWash?: InputMaybe<Scalars['Float']>;
  lengthShrinkageObservations?: InputMaybe<Scalars['String']>;
  meshKind?: InputMaybe<Scalars['String']>;
  meshSupplier?: InputMaybe<Scalars['ID']>;
  meshSupplierDocument?: InputMaybe<Scalars['String']>;
  purchaseOrder?: InputMaybe<Scalars['String']>;
  purchaseOrderType: Scalars['ID'];
  quantityReceived?: InputMaybe<Scalars['Float']>;
  quantitySent?: InputMaybe<Scalars['Float']>;
  quantityUnit?: InputMaybe<Scalars['String']>;
  receptionDate?: InputMaybe<Scalars['DateTime']>;
  spiralityDeviation?: InputMaybe<Scalars['Float']>;
  spiralityObservations?: InputMaybe<Scalars['String']>;
  spoolCount?: InputMaybe<Scalars['Int']>;
  supplier?: InputMaybe<Scalars['ID']>;
  supplierDocument?: InputMaybe<Scalars['String']>;
  washType?: InputMaybe<Scalars['ID']>;
  weightReceived?: InputMaybe<Scalars['Float']>;
  weightRequired?: InputMaybe<Scalars['Float']>;
  widthAfterWash?: InputMaybe<Scalars['Float']>;
  widthBeforeWash?: InputMaybe<Scalars['Float']>;
  widthReceived?: InputMaybe<Scalars['Float']>;
  widthRequired?: InputMaybe<Scalars['Float']>;
  widthShrinkageObservations?: InputMaybe<Scalars['String']>;
};

export type UpdateMeshConsumption = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Float']>;
  purpose?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateMeshLocation = {
  id?: InputMaybe<Scalars['Float']>;
};

export type UpdateMeshTransfer = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<MeshTransferStates>;
  type?: InputMaybe<MeshTransferTypes>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateNotification = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<NotificationKinds>;
  message?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<NotificationPriorities>;
  tab?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateOneAliasInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateAlias;
};

export type UpdateOneBrandInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateBrand;
};

export type UpdateOneMeshConsumptionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMeshConsumption;
};

export type UpdateOneMeshInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMesh;
};

export type UpdateOneMeshLocationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMeshLocation;
};

export type UpdateOneMeshTransferInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMeshTransfer;
};

export type UpdateOneNotificationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateNotification;
};

export type UpdateOnePosterCompositionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePosterComposition;
};

export type UpdateOnePosterFabricTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePosterFabricType;
};

export type UpdateOnePosterInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePoster;
};

export type UpdateOnePosterSeasonInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePosterSeason;
};

export type UpdateOnePurchaseOrderTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePurchaseOrderType;
};

export type UpdateOneSampleInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSample;
};

export type UpdateOneSampleMeshInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleMesh;
};

export type UpdateOneSampleStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleState;
};

export type UpdateOneSampleTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleType;
};

export type UpdateOneSupplierInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSupplier;
};

export type UpdateOneUserEventInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUserEvent;
};

export type UpdateOneUserTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUserType;
};

export type UpdateOneWashTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateWashType;
};

export type UpdatePoster = {
  cover?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  specifications?: InputMaybe<Scalars['String']>;
  story?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PosterTypes>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdatePosterComposition = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdatePosterFabricType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  posterType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdatePosterSeason = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdatePurchaseOrderType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateSample = {
  clientReference?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  decorationsReceivedAt?: InputMaybe<Scalars['String']>;
  fastTrack?: InputMaybe<Scalars['Boolean']>;
  foreseenDate?: InputMaybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: InputMaybe<Scalars['String']>;
  hasConfectionAccessories?: InputMaybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: InputMaybe<Scalars['String']>;
  hasMesh?: InputMaybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: InputMaybe<Scalars['String']>;
  hasPackingAccessories?: InputMaybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  intendedDate?: InputMaybe<Scalars['DateTime']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  observations?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  orderReference?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  shippingDate?: InputMaybe<Scalars['String']>;
  sketch?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateSampleMesh = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateSampleState = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  sampleStateTypeId?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateSampleType = {
  created?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateSupplier = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isMeshSupplier?: InputMaybe<Scalars['Boolean']>;
  isSupplier?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateUserEvent = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['JSONObject']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateUserType = {
  created?: InputMaybe<Scalars['DateTime']>;
  doing?: InputMaybe<Array<SampleInputType>>;
  doingCount?: InputMaybe<Scalars['Int']>;
  done?: InputMaybe<Array<SampleInputType>>;
  doneCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  todo?: InputMaybe<Array<SampleInputType>>;
  todoCount?: InputMaybe<Scalars['Int']>;
  updated?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateWashType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  checkinSampleStates: Array<SampleState>;
  color?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isSupervisor: Scalars['Boolean'];
  name: Scalars['String'];
  stats: DashUserStat;
  type: UserType;
  updated: Scalars['DateTime'];
};


export type UserCheckinSampleStatesArgs = {
  filter?: InputMaybe<SampleStateFilter>;
  sorting?: InputMaybe<Array<SampleStateSort>>;
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the User */
  node: User;
};

export type UserEvent = {
  __typename?: 'UserEvent';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type UserEventAggregateGroupBy = {
  __typename?: 'UserEventAggregateGroupBy';
  data?: Maybe<Scalars['JSONObject']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserEventAvgAggregate = {
  __typename?: 'UserEventAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserEventConnection = {
  __typename?: 'UserEventConnection';
  /** Array of nodes. */
  nodes: Array<UserEvent>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserEventCountAggregate = {
  __typename?: 'UserEventCountAggregate';
  data?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserEventDeleteFilter = {
  and?: InputMaybe<Array<UserEventDeleteFilter>>;
  data?: InputMaybe<JsonObjectFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserEventDeleteFilter>>;
};

export type UserEventDeleteResponse = {
  __typename?: 'UserEventDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSONObject']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserEventFilter = {
  and?: InputMaybe<Array<UserEventFilter>>;
  data?: InputMaybe<JsonObjectFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserEventFilter>>;
};

export type UserEventMaxAggregate = {
  __typename?: 'UserEventMaxAggregate';
  data?: Maybe<Scalars['JSONObject']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserEventMinAggregate = {
  __typename?: 'UserEventMinAggregate';
  data?: Maybe<Scalars['JSONObject']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserEventSort = {
  direction: SortDirection;
  field: UserEventSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserEventSortFields {
  Data = 'data',
  Id = 'id',
  Name = 'name'
}

export type UserEventSumAggregate = {
  __typename?: 'UserEventSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserEventUpdateFilter = {
  and?: InputMaybe<Array<UserEventUpdateFilter>>;
  data?: InputMaybe<JsonObjectFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserEventUpdateFilter>>;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilter>>;
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isSupervisor: Scalars['Boolean'];
  name: Scalars['String'];
  stats: DashUserStatInput;
  type?: InputMaybe<UserTypeInput>;
  updated: Scalars['DateTime'];
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  Id = 'id',
  Name = 'name'
}

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserType = {
  __typename?: 'UserType';
  created: Scalars['DateTime'];
  doing: Array<Sample>;
  doingCount: Scalars['Int'];
  done: Array<Sample>;
  doneCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  todo: Array<Sample>;
  todoCount: Scalars['Int'];
  updated: Scalars['DateTime'];
  users: Array<User>;
};


export type UserTypeUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<Array<UserSort>>;
};

export type UserTypeAggregateGroupBy = {
  __typename?: 'UserTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Array of edges. */
  edges: Array<UserTypeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type UserTypeCountAggregate = {
  __typename?: 'UserTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserTypeDeleteFilter = {
  and?: InputMaybe<Array<UserTypeDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserTypeDeleteFilter>>;
};

export type UserTypeDeleteResponse = {
  __typename?: 'UserTypeDeleteResponse';
  created?: Maybe<Scalars['DateTime']>;
  doing?: Maybe<Array<Sample>>;
  doingCount?: Maybe<Scalars['Int']>;
  done?: Maybe<Array<Sample>>;
  doneCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  todo?: Maybe<Array<Sample>>;
  todoCount?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type UserTypeEdge = {
  __typename?: 'UserTypeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the UserType */
  node: UserType;
};

export type UserTypeFilter = {
  and?: InputMaybe<Array<UserTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserTypeFilter>>;
};

export type UserTypeInput = {
  created: Scalars['DateTime'];
  doing: Array<SampleInputType>;
  doingCount: Scalars['Int'];
  done: Array<SampleInputType>;
  doneCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  todo: Array<SampleInputType>;
  todoCount: Scalars['Int'];
  updated: Scalars['DateTime'];
};

export type UserTypeMaxAggregate = {
  __typename?: 'UserTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeMinAggregate = {
  __typename?: 'UserTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeSort = {
  direction: SortDirection;
  field: UserTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type UserTypeUpdateFilter = {
  and?: InputMaybe<Array<UserTypeUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserTypeUpdateFilter>>;
};

export type WashType = {
  __typename?: 'WashType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type WashTypeAggregateGroupBy = {
  __typename?: 'WashTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WashTypeAvgAggregate = {
  __typename?: 'WashTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WashTypeConnection = {
  __typename?: 'WashTypeConnection';
  /** Array of edges. */
  edges: Array<WashTypeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type WashTypeCountAggregate = {
  __typename?: 'WashTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type WashTypeDeleteFilter = {
  and?: InputMaybe<Array<WashTypeDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WashTypeDeleteFilter>>;
};

export type WashTypeDeleteResponse = {
  __typename?: 'WashTypeDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WashTypeEdge = {
  __typename?: 'WashTypeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the WashType */
  node: WashType;
};

export type WashTypeFilter = {
  and?: InputMaybe<Array<WashTypeFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WashTypeFilter>>;
};

export type WashTypeMaxAggregate = {
  __typename?: 'WashTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WashTypeMinAggregate = {
  __typename?: 'WashTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WashTypeSort = {
  direction: SortDirection;
  field: WashTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WashTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type WashTypeSumAggregate = {
  __typename?: 'WashTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WashTypeUpdateFilter = {
  and?: InputMaybe<Array<WashTypeUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WashTypeUpdateFilter>>;
};

export type CreateOneUserEventMutationVariables = Exact<{
  input: CreateOneUserEventInput;
}>;


export type CreateOneUserEventMutation = { __typename?: 'Mutation', createOneUserEvent: { __typename?: 'UserEvent', id: string } };

export type StatusQueryVariables = Exact<{ [key: string]: never; }>;


export type StatusQuery = { __typename?: 'Query', status: string };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, name: string, email: string, type: { __typename?: 'UserType', id: string } } };

export type PostersQueryVariables = Exact<{
  filter?: InputMaybe<PosterFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PosterSort> | PosterSort>;
}>;


export type PostersQuery = { __typename?: 'Query', posters: { __typename?: 'PosterConnection', totalCount: number, nodes: Array<{ __typename?: 'Poster', id: string, name?: string | null, reference: string, photo: string, cover: string, story: string, specifications: string, weight: number, type: PosterTypes, fabricType: { __typename?: 'PosterFabricType', id: string, name: string }, composition: { __typename?: 'PosterComposition', id: string, name: string }, season: { __typename?: 'PosterSeason', id: string, name: string } }> } };

export type PosterCompositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PosterCompositionsQuery = { __typename?: 'Query', posterCompositions: { __typename?: 'PosterCompositionConnection', nodes: Array<{ __typename?: 'PosterComposition', id: string, name: string, createdAt: any, updatedAt: any }> } };

export type PosterFabricTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type PosterFabricTypesQuery = { __typename?: 'Query', posterFabricTypes: { __typename?: 'PosterFabricTypeConnection', nodes: Array<{ __typename?: 'PosterFabricType', id: string, name: string, createdAt: any, updatedAt: any }> } };

export type PosterSeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type PosterSeasonsQuery = { __typename?: 'Query', posterSeasons: { __typename?: 'PosterSeasonConnection', nodes: Array<{ __typename?: 'PosterSeason', id: string, name: string, createdAt: any, updatedAt: any }> } };

export type FabricFilterForFinishingsQueryVariables = Exact<{ [key: string]: never; }>;


export type FabricFilterForFinishingsQuery = { __typename?: 'Query', posterFabricTypes: { __typename?: 'PosterFabricTypeConnection', nodes: Array<{ __typename?: 'PosterFabricType', id: string, name: string, createdAt: any, updatedAt: any }> } };

export type FabricFilterForMeshesQueryVariables = Exact<{ [key: string]: never; }>;


export type FabricFilterForMeshesQuery = { __typename?: 'Query', posterFabricTypes: { __typename?: 'PosterFabricTypeConnection', nodes: Array<{ __typename?: 'PosterFabricType', id: string, name: string, createdAt: any, updatedAt: any }> } };

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordEmailMutation = { __typename?: 'Mutation', sendResetPasswordEmail: boolean };

export type SetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword: string };

export type SendRequestAccessEmailMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
}>;


export type SendRequestAccessEmailMutation = { __typename?: 'Mutation', sendRequestAccessEmail: boolean };

export type CreateOneQuoteMutationVariables = Exact<{
  input: CreateOneQuoteInput;
}>;


export type CreateOneQuoteMutation = { __typename?: 'Mutation', createOneQuote: { __typename?: 'Quote', id?: string | null } };

export type CreateOneUserMutationVariables = Exact<{
  input: CreateOneUserArgs;
}>;


export type CreateOneUserMutation = { __typename?: 'Mutation', createOneUser: { __typename?: 'User', id: string, name: string, email: string } };


export const CreateOneUserEventDocument = gql`
    mutation createOneUserEvent($input: CreateOneUserEventInput!) {
  createOneUserEvent(input: $input) {
    id
  }
}
    `;
export type CreateOneUserEventMutationFn = Apollo.MutationFunction<CreateOneUserEventMutation, CreateOneUserEventMutationVariables>;

/**
 * __useCreateOneUserEventMutation__
 *
 * To run a mutation, you first call `useCreateOneUserEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneUserEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneUserEventMutation, { data, loading, error }] = useCreateOneUserEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneUserEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneUserEventMutation, CreateOneUserEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneUserEventMutation, CreateOneUserEventMutationVariables>(CreateOneUserEventDocument, options);
      }
export type CreateOneUserEventMutationHookResult = ReturnType<typeof useCreateOneUserEventMutation>;
export type CreateOneUserEventMutationResult = Apollo.MutationResult<CreateOneUserEventMutation>;
export type CreateOneUserEventMutationOptions = Apollo.BaseMutationOptions<CreateOneUserEventMutation, CreateOneUserEventMutationVariables>;
export const StatusDocument = gql`
    query status {
  status
}
    `;

/**
 * __useStatusQuery__
 *
 * To run a query within a React component, call `useStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatusQuery(baseOptions?: Apollo.QueryHookOptions<StatusQuery, StatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatusQuery, StatusQueryVariables>(StatusDocument, options);
      }
export function useStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatusQuery, StatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatusQuery, StatusQueryVariables>(StatusDocument, options);
        }
export type StatusQueryHookResult = ReturnType<typeof useStatusQuery>;
export type StatusLazyQueryHookResult = ReturnType<typeof useStatusLazyQuery>;
export type StatusQueryResult = Apollo.QueryResult<StatusQuery, StatusQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
    type {
      id
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PostersDocument = gql`
    query posters($filter: PosterFilter, $paging: OffsetPaging, $sorting: [PosterSort!]) {
  posters(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      name
      reference
      photo
      cover
      story
      specifications
      weight
      type
      fabricType {
        id
        name
      }
      composition {
        id
        name
      }
      season {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePostersQuery__
 *
 * To run a query within a React component, call `usePostersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function usePostersQuery(baseOptions?: Apollo.QueryHookOptions<PostersQuery, PostersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostersQuery, PostersQueryVariables>(PostersDocument, options);
      }
export function usePostersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostersQuery, PostersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostersQuery, PostersQueryVariables>(PostersDocument, options);
        }
export type PostersQueryHookResult = ReturnType<typeof usePostersQuery>;
export type PostersLazyQueryHookResult = ReturnType<typeof usePostersLazyQuery>;
export type PostersQueryResult = Apollo.QueryResult<PostersQuery, PostersQueryVariables>;
export const PosterCompositionsDocument = gql`
    query posterCompositions {
  posterCompositions(paging: {limit: 999}) {
    nodes {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __usePosterCompositionsQuery__
 *
 * To run a query within a React component, call `usePosterCompositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePosterCompositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePosterCompositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePosterCompositionsQuery(baseOptions?: Apollo.QueryHookOptions<PosterCompositionsQuery, PosterCompositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PosterCompositionsQuery, PosterCompositionsQueryVariables>(PosterCompositionsDocument, options);
      }
export function usePosterCompositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PosterCompositionsQuery, PosterCompositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PosterCompositionsQuery, PosterCompositionsQueryVariables>(PosterCompositionsDocument, options);
        }
export type PosterCompositionsQueryHookResult = ReturnType<typeof usePosterCompositionsQuery>;
export type PosterCompositionsLazyQueryHookResult = ReturnType<typeof usePosterCompositionsLazyQuery>;
export type PosterCompositionsQueryResult = Apollo.QueryResult<PosterCompositionsQuery, PosterCompositionsQueryVariables>;
export const PosterFabricTypesDocument = gql`
    query posterFabricTypes {
  posterFabricTypes {
    nodes {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __usePosterFabricTypesQuery__
 *
 * To run a query within a React component, call `usePosterFabricTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePosterFabricTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePosterFabricTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePosterFabricTypesQuery(baseOptions?: Apollo.QueryHookOptions<PosterFabricTypesQuery, PosterFabricTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PosterFabricTypesQuery, PosterFabricTypesQueryVariables>(PosterFabricTypesDocument, options);
      }
export function usePosterFabricTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PosterFabricTypesQuery, PosterFabricTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PosterFabricTypesQuery, PosterFabricTypesQueryVariables>(PosterFabricTypesDocument, options);
        }
export type PosterFabricTypesQueryHookResult = ReturnType<typeof usePosterFabricTypesQuery>;
export type PosterFabricTypesLazyQueryHookResult = ReturnType<typeof usePosterFabricTypesLazyQuery>;
export type PosterFabricTypesQueryResult = Apollo.QueryResult<PosterFabricTypesQuery, PosterFabricTypesQueryVariables>;
export const PosterSeasonsDocument = gql`
    query posterSeasons {
  posterSeasons {
    nodes {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __usePosterSeasonsQuery__
 *
 * To run a query within a React component, call `usePosterSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePosterSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePosterSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePosterSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<PosterSeasonsQuery, PosterSeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PosterSeasonsQuery, PosterSeasonsQueryVariables>(PosterSeasonsDocument, options);
      }
export function usePosterSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PosterSeasonsQuery, PosterSeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PosterSeasonsQuery, PosterSeasonsQueryVariables>(PosterSeasonsDocument, options);
        }
export type PosterSeasonsQueryHookResult = ReturnType<typeof usePosterSeasonsQuery>;
export type PosterSeasonsLazyQueryHookResult = ReturnType<typeof usePosterSeasonsLazyQuery>;
export type PosterSeasonsQueryResult = Apollo.QueryResult<PosterSeasonsQuery, PosterSeasonsQueryVariables>;
export const FabricFilterForFinishingsDocument = gql`
    query fabricFilterForFinishings {
  posterFabricTypes(filter: {posterType: {eq: "FINISHING"}}, paging: {limit: 999}) {
    nodes {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useFabricFilterForFinishingsQuery__
 *
 * To run a query within a React component, call `useFabricFilterForFinishingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFabricFilterForFinishingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFabricFilterForFinishingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFabricFilterForFinishingsQuery(baseOptions?: Apollo.QueryHookOptions<FabricFilterForFinishingsQuery, FabricFilterForFinishingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FabricFilterForFinishingsQuery, FabricFilterForFinishingsQueryVariables>(FabricFilterForFinishingsDocument, options);
      }
export function useFabricFilterForFinishingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FabricFilterForFinishingsQuery, FabricFilterForFinishingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FabricFilterForFinishingsQuery, FabricFilterForFinishingsQueryVariables>(FabricFilterForFinishingsDocument, options);
        }
export type FabricFilterForFinishingsQueryHookResult = ReturnType<typeof useFabricFilterForFinishingsQuery>;
export type FabricFilterForFinishingsLazyQueryHookResult = ReturnType<typeof useFabricFilterForFinishingsLazyQuery>;
export type FabricFilterForFinishingsQueryResult = Apollo.QueryResult<FabricFilterForFinishingsQuery, FabricFilterForFinishingsQueryVariables>;
export const FabricFilterForMeshesDocument = gql`
    query fabricFilterForMeshes {
  posterFabricTypes(
    filter: {posterType: {eq: "MESH"}}
    paging: {limit: 999}
    sorting: [{field: name, direction: ASC}]
  ) {
    nodes {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useFabricFilterForMeshesQuery__
 *
 * To run a query within a React component, call `useFabricFilterForMeshesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFabricFilterForMeshesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFabricFilterForMeshesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFabricFilterForMeshesQuery(baseOptions?: Apollo.QueryHookOptions<FabricFilterForMeshesQuery, FabricFilterForMeshesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FabricFilterForMeshesQuery, FabricFilterForMeshesQueryVariables>(FabricFilterForMeshesDocument, options);
      }
export function useFabricFilterForMeshesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FabricFilterForMeshesQuery, FabricFilterForMeshesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FabricFilterForMeshesQuery, FabricFilterForMeshesQueryVariables>(FabricFilterForMeshesDocument, options);
        }
export type FabricFilterForMeshesQueryHookResult = ReturnType<typeof useFabricFilterForMeshesQuery>;
export type FabricFilterForMeshesLazyQueryHookResult = ReturnType<typeof useFabricFilterForMeshesLazyQuery>;
export type FabricFilterForMeshesQueryResult = Apollo.QueryResult<FabricFilterForMeshesQuery, FabricFilterForMeshesQueryVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation sendResetPasswordEmail($email: String!) {
  sendResetPasswordEmail(email: $email)
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const SetPasswordDocument = gql`
    mutation setPassword($token: String!, $password: String!, $email: String!) {
  setPassword(token: $token, password: $password, email: $email)
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const SendRequestAccessEmailDocument = gql`
    mutation sendRequestAccessEmail($name: String!, $email: String!) {
  sendRequestAccessEmail(name: $name, email: $email)
}
    `;
export type SendRequestAccessEmailMutationFn = Apollo.MutationFunction<SendRequestAccessEmailMutation, SendRequestAccessEmailMutationVariables>;

/**
 * __useSendRequestAccessEmailMutation__
 *
 * To run a mutation, you first call `useSendRequestAccessEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRequestAccessEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRequestAccessEmailMutation, { data, loading, error }] = useSendRequestAccessEmailMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendRequestAccessEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendRequestAccessEmailMutation, SendRequestAccessEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRequestAccessEmailMutation, SendRequestAccessEmailMutationVariables>(SendRequestAccessEmailDocument, options);
      }
export type SendRequestAccessEmailMutationHookResult = ReturnType<typeof useSendRequestAccessEmailMutation>;
export type SendRequestAccessEmailMutationResult = Apollo.MutationResult<SendRequestAccessEmailMutation>;
export type SendRequestAccessEmailMutationOptions = Apollo.BaseMutationOptions<SendRequestAccessEmailMutation, SendRequestAccessEmailMutationVariables>;
export const CreateOneQuoteDocument = gql`
    mutation createOneQuote($input: CreateOneQuoteInput!) {
  createOneQuote(input: $input) {
    id
  }
}
    `;
export type CreateOneQuoteMutationFn = Apollo.MutationFunction<CreateOneQuoteMutation, CreateOneQuoteMutationVariables>;

/**
 * __useCreateOneQuoteMutation__
 *
 * To run a mutation, you first call `useCreateOneQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneQuoteMutation, { data, loading, error }] = useCreateOneQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneQuoteMutation, CreateOneQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneQuoteMutation, CreateOneQuoteMutationVariables>(CreateOneQuoteDocument, options);
      }
export type CreateOneQuoteMutationHookResult = ReturnType<typeof useCreateOneQuoteMutation>;
export type CreateOneQuoteMutationResult = Apollo.MutationResult<CreateOneQuoteMutation>;
export type CreateOneQuoteMutationOptions = Apollo.BaseMutationOptions<CreateOneQuoteMutation, CreateOneQuoteMutationVariables>;
export const CreateOneUserDocument = gql`
    mutation createOneUser($input: CreateOneUserArgs!) {
  createOneUser(input: $input) {
    id
    name
    email
  }
}
    `;
export type CreateOneUserMutationFn = Apollo.MutationFunction<CreateOneUserMutation, CreateOneUserMutationVariables>;

/**
 * __useCreateOneUserMutation__
 *
 * To run a mutation, you first call `useCreateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneUserMutation, { data, loading, error }] = useCreateOneUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneUserMutation, CreateOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneUserMutation, CreateOneUserMutationVariables>(CreateOneUserDocument, options);
      }
export type CreateOneUserMutationHookResult = ReturnType<typeof useCreateOneUserMutation>;
export type CreateOneUserMutationResult = Apollo.MutationResult<CreateOneUserMutation>;
export type CreateOneUserMutationOptions = Apollo.BaseMutationOptions<CreateOneUserMutation, CreateOneUserMutationVariables>;