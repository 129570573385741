import { FastField } from "formik"
import { FC } from "react"
import FieldText from "../FieldText/FieldText"

interface InputTextFieldProps {
    name: string
    label?: string
    autoFocus?: boolean
    variant?: "standard" | "outlined" | "contained"
    multiline?: boolean
    size?: "small" | "medium"
    placeholder?: string
    fullWidth?: boolean
    rows?: number
    sx?: {}
}

const InputTextField: FC<InputTextFieldProps> = props => {
    const { name,
        label = name,
        autoFocus = false,
        multiline = false,
        variant = "outlined",
        size,
        placeholder,
        fullWidth,
        rows,
        sx
    } = props

    return (
        <FastField
            component={FieldText}
            name={name}
            label={label}
            type="text"
            autoFocus={autoFocus}
            variant={variant}
            multiline={multiline}
            size={size}
            placeholder={placeholder}
            fullWidth={fullWidth}
            rows={rows}
            sx={sx}
        />
    )
}

export default InputTextField
