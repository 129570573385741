import { Grid, Typography } from "@mui/material"
import { Field, useFormikContext } from "formik"
import { CheckboxWithLabel } from "formik-mui"
import { FC } from "react"
import { Quote } from "../../../generated/graphql"
import { defaultInputSx } from "../../../services/config"
import InputFileField from "../../InputFileField/InputFileField"
import InputNumberField from "../../InputNumberField/InputNumberField"
import InputTextField from "../../InputTextField/InputTextField"
import formStyles from '../CreateQuote.module.scss'
import styles from './RequestInformation.module.scss'

interface RequiredFieldProps {
    name: keyof Quote
}
// component para usar em componentes custom
// onde precisamos de ser nós a mostrar a mensagem
// de erro required field, não existe outra
// regra de validação, não pode existir outro errro
const RequiredField: FC<RequiredFieldProps> = props => {
    const { name } = props
    const { errors } = useFormikContext<Quote>()

    if (!errors?.[name]) return null

    return (
        <small style={{ color: "#d32f2f" }}>Required Field</small>
    )
}

const RequestType = () => {
    return (
        <div className={styles.RequestType}>
            <Typography variant="h5" color="primary" gutterBottom={false}>Request type</Typography>
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="requestType"
                value="research"
                Label={{ label: 'Research and Design' }}
            /><br />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="requestType"
                value="price"
                Label={{ label: 'Price' }}
            /><br />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="requestType"
                value="Prototype"
                Label={{ label: 'Prototype' }}
            /><br />
            <RequiredField name="requestType" />
        </div >
    )
}


const Categories = () => {
    return (
        <div className={styles.Categories}>
            <Typography variant="h5" color="primary" gutterBottom={false}>Categories</Typography>
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="categories"
                value="womens"
                Label={{ label: 'Womens' }}
            /><br />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="categories"
                value="mens"
                Label={{ label: 'Mens' }}
            /><br />
            <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="categories"
                value="children"
                Label={{ label: 'Children & Baby' }}
            /><br />
            <RequiredField name="categories" />
        </div >
    )
}

const ProductGroup = () => {
    return (
        <div className={styles.ProductGroup}>
            <Typography variant="h5" color="primary">Product Group</Typography>
            <Grid container direction="row" >

                <Grid item xs={3}>
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="productGroup"
                        value="shirts"
                        Label={{ label: 'Shirts & Blouses' }}
                    /><br />
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="productGroup"
                        value="dresses"
                        Label={{ label: 'Dresses & Skirts' }}
                    />
                </Grid>
                <br />

                <Grid item xs={3}>
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="productGroup"
                        value="sweatshirts"
                        Label={{ label: 'Sweatshirts & Hoodies' }}
                    /><br />
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="productGroup"
                        value="tshirts"
                        Label={{ label: 'Tshirts & Polos' }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="productGroup"
                        value="trousers"
                        Label={{ label: 'Trousers & Shorts' }}
                    /><br />
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="productGroup"
                        value="jackets"
                        Label={{ label: 'Jackets & Coats' }}
                    />
                </Grid>

            </Grid>
            <RequiredField name="productGroup" />
        </div>
    )
}




// reintroduzir disabled
// https://stackworx.github.io/formik-mui/docs/api/mui#radiogroup
const RequestInformation = () => {

    const sx = defaultInputSx()

    return (
        <div className={formStyles.FormStep}>
            <Typography variant="h3" color="primary">
                request information
            </Typography>
            <div className={formStyles.Form}>

                <Grid container direction="row">
                    <Grid item xs={3}>
                        <RequestType />
                    </Grid>
                    <Grid item xs={3}>
                        <Categories />
                    </Grid>
                </Grid>

                <div>
                    <ProductGroup />
                </div>

                <div className={formStyles.FormLine}>
                    <InputNumberField name="estimatedQuantityYear" label="estimated quantity per year" size="small" sx={sx} />
                    <InputNumberField name="orderQuantityColor" label="order quantity per color" size="small" sx={sx} />
                </div>


                <div style={{ maxWidth: 873, marginBottom: 15 }}>
                    <InputTextField name="specification" placeholder="materials, trims, techniques, details..." multiline={true} fullWidth rows={2} />
                </div>

                <div className={formStyles.FormLine}>
                    <InputFileField name="attachments" />
                </div>
            </div>
        </div>
    )
}


export default RequestInformation
