import { createContext, useContext, useState } from "react";

export const LayoutContext = createContext<any>(undefined)

const LayoutProvider = ({ children }: { children: any }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isSidemenuOpen, setIsSidemenuOpen] = useState<boolean>(false)
    const [filterCount, setFilterCount] = useState<number>(0)
    return (
        <LayoutContext.Provider value={{ isOpen, setIsOpen, isSidemenuOpen, setIsSidemenuOpen, filterCount, setFilterCount }}>
            {children}
        </LayoutContext.Provider>
    )
}

export const useFiltersDrawer = () => useContext(LayoutContext)
export const useSidemenu = () => useContext(LayoutContext)
export const useFilterCount = () => useContext(LayoutContext)


export default LayoutProvider
