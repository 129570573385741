import TuneIcon from '@mui/icons-material/Tune';
import { Badge, IconButton } from "@mui/material";
import { useAuth } from '../../services/auth';
import { useFiltersDrawer } from '../Layout/LayoutProvider';

// Mobile only component
const FiltersMenu = () => {
    const context = useFiltersDrawer()
    const { user } = useAuth()

    // se o contexto não está definido estamos
    // em desktop
    if (!context || !user) return null

    const handle = () => {
        context.setIsOpen(true)
    }

    return (
        <>
            <IconButton color="inherit" aria-label="user menu" onClick={handle}>
                <Badge badgeContent={context.filterCount} color="success">
                    <TuneIcon />
                </Badge>
            </IconButton>
        </>

    )
}

export default FiltersMenu;
