import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styles from './FoursourceThanks.module.scss'

const FoursourceThanks = () => {
    const navigate = useNavigate()

    const handleBackHome = () => {
        navigate("/")
    }

    const handleMakeRequest = () => {
        navigate("/create-quote")
    }

    return (
        <Grid container justifyContent="center" alignContent="center" className={styles.Thanks}>
            <Card>
                <CardContent style={{ padding: 75 }}>
                    <div style={{ marginBottom: 50 }}>
                        <Typography variant="h3" align="center">
                            Thanks. We'll reply shortly.
                        </Typography>
                    </div>
                    <Grid container justifyContent="center" style={{ marginBottom: 25 }}>
                        <Button onClick={handleBackHome} color="primary" variant="outlined">
                            Back to Homepage
                        </Button>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button onClick={handleMakeRequest} color="primary" variant="contained">
                            Make Another Request
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default FoursourceThanks
