import { createTheme } from "@mui/material";

export const circloTheme = createTheme({
    palette: {
        primary: {
            main: "#44BDA4",
            contrastText: "#fff"
        }
    },
    typography: {
        fontFamily: "Lato, sans-serif"
    }
});

export const selectivaTheme = createTheme({
    palette: {
        primary: {
            main: "#8B0000",
        }
    },
    typography: {
        fontFamily: "Lato, sans-serif",
        h3: {
            fontSize: 28,
            fontWeight: 600
        },
        h5: {
            fontSize: 18,
            fontWeight: 600,
        }
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                },
                input: {
                    backgroundColor: "#ffffff"
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 7
                }
            }
        }
    }

})
