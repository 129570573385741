import { Button, Card, Grid } from '@mui/material';
import { Field, Form, Formik, FormikValues } from 'formik';
import { TextField } from 'formik-mui';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import { useSendRequestAccessEmailMutation } from '../../generated/graphql';
import { useGtXs } from '../../hooks/useGtXs';
import AuthLayout from '../AuthLayout/AuthLayout';
import LinkToSite from '../LinkToSite/LinkToSite';
import MobileLayout from '../MobileLayout/MobileLayout';
import styles from './Registration.module.scss';

const schema = Yup.object().shape({
    name: Yup.string().required().default(""),
    email: Yup.string().email().required().default(""),
})

const RegistrationFeedback = () => {
    return (
        <div className={styles.RegistrationFeedback}>
            <p>
                Thank you for reaching out.<br />
                We'll respond to your request shortly!
            </p>

            <p>In the meantime, you can check our corporate website</p>

            <p>
                <LinkToSite>
                    <span>www.pedrosapt.com</span>
                </LinkToSite>
            </p>
        </div>
    )
}

const RegistrationFeedbackDesktop = () => {
    return (
        <Card className={styles.RegistrationFeedbackCard}>
            <Grid container direction="column" justifyContent="center" style={{ height: "100%" }}>
                <RegistrationFeedback />
            </Grid>
        </Card>
    )
}


const Disclaimer = () => {
    return (
        <div className={styles.Disclaimer}>
            Please fill the form below to request access. Subject to confirmation.
        </div>
    )
}



const RegistrationMobile: FC<any> = props => {
    const { handleSubmit, initialValues, errorMessage, submitted } = props
    return (
        <MobileLayout>
            <Grid container direction="column" justifyContent="center" className={styles.MobileRegistration}>

                {!submitted ? <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema}>
                    {({ isSubmitting }) => (
                        <Form>

                            <Grid container direction="column" justifyContent="space-around" style={{ height: "100%", padding: 20 }}>
                                <Disclaimer />
                                <div style={{ color: "red", marginBottom: "15px" }}>{errorMessage}</div>
                                <div style={{ marginBottom: "20px" }}>
                                    <Field
                                        component={TextField}
                                        label="Name"
                                        name="name"
                                        required
                                        fullWidth
                                        autoFocus
                                        autoComplete="name"
                                    />
                                </div>

                                <Grid container direction="column" alignItems="space-around">
                                    <div style={{ marginBottom: "20px" }}>
                                        <Field
                                            component={TextField}
                                            label="Email"
                                            name="email"
                                            required
                                            fullWidth
                                            autoComplete="email"
                                        />

                                    </div>
                                </Grid>

                                <Grid container direction="column" alignItems="space-around" style={{ marginBottom: "20px" }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        request access
                                    </Button>
                                    <Grid container justifyContent="center" className={styles.Registration}>
                                        <p><Link to="/">Back to Homepage</Link></p>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Form>
                    )}
                </Formik> : <RegistrationFeedback />}

            </Grid>
        </MobileLayout>
    )
}

const RegistrationDesktop: FC<any> = props => {
    const { handleSubmit, initialValues, errorMessage, submitted } = props
    return (
        <AuthLayout>
            {!submitted ? <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema}>
                {({ isSubmitting }) => (
                    <Form>
                        <Card className={styles.RegistrationCard}>
                            <Grid container direction="column" justifyContent="space-around" style={{ height: "100%", padding: 50 }}>
                                <Disclaimer />
                                <div style={{ color: "red", marginBottom: "15px" }}>{errorMessage}</div>
                                <div>
                                    <Field
                                        component={TextField}
                                        label="Name"
                                        name="name"
                                        required
                                        fullWidth
                                        autoFocus
                                        autoComplete="name"
                                    />
                                </div>

                                <Grid container direction="column" alignItems="space-around">
                                    <div>
                                        <Field
                                            component={TextField}
                                            label="Email"
                                            name="email"
                                            required
                                            fullWidth
                                            autoComplete="email"
                                        />

                                    </div>
                                </Grid>

                                <Grid container direction="column" alignItems="space-around">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        request access
                                    </Button>
                                    <Grid container justifyContent="center" className={styles.Registration}>
                                        <p><Link to="/">Back to Homepage</Link></p>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Card>
                    </Form>
                )}
            </Formik> : <RegistrationFeedbackDesktop />}
        </AuthLayout>
    )
}



const Registration = () => {
    const gtXs = useGtXs()
    const initialValues: any = { email: "", name: "" }
    const [sendRequestAccessEmail] = useSendRequestAccessEmailMutation()
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [submitted, setSubmitted] = useState<boolean>(false)

    const handleSubmit = async (values: FormikValues) => {
        const { name, email } = values
        const response = await sendRequestAccessEmail({ variables: { name, email } })
        if (!response?.errors?.length) {
            setSubmitted(true)
        } else {
            setErrorMessage("Error while sending request. Please try again.")
        }
    }

    return gtXs ? <RegistrationDesktop handleSubmit={handleSubmit} initialValues={initialValues} errorMessage={errorMessage} submitted={submitted} /> : <RegistrationMobile handleSubmit={handleSubmit} initialValues={initialValues} errorMessage={errorMessage} submitted={submitted} />
}

export default Registration;
