import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';

interface FabricTypeSelectProps {
    value: string[]
    onChange: any
    func: any
    showLabel?: boolean
    width?: number | string
    inputProps?: any
    label?: string
}

const FabricTypeSelect: FC<FabricTypeSelectProps> = props => {

    const { value,
        onChange,
        func,
        inputProps = { disableUnderline: true },
        showLabel = true,
        width = 200,
        label = 'Type'
    } = props

    const { data } = func()

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value }, } = event;
        onChange("fabricType", value)
    };

    const findById = (id: string) => {
        return data?.posterFabricTypes.nodes.find((postFabricType: any) => postFabricType?.id === id)
    }

    const render = (selected: string[]) => {
        const first = selected[0]
        const fabricType = findById(first)
        return selected.length === 1 ? fabricType?.name : fabricType?.name + '...'
    }

    return (
        <FormControl sx={{ m: 1, width }}>
            {showLabel && <InputLabel>{label}</InputLabel>}
            <Select
                multiple
                value={value}
                onChange={handleChange}
                input={<Input {...inputProps} />}
                renderValue={render}
            >
                {data?.posterFabricTypes?.nodes.map((node: any) => (
                    <MenuItem key={node?.id} value={node?.id}>
                        <Checkbox checked={value.includes(node?.id)} />
                        <ListItemText primary={node?.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default FabricTypeSelect;
