import { StringParam, useQueryParams, withDefault } from 'use-query-params';

type FilterConfig = string | { name: string, variant: any, defaultValue: any }

export const useFiltersBuilder = (filters: FilterConfig[]) => {
    const filters_ = {}
    filters.forEach(filter => {
        if (typeof filter === 'string') {
            // @ts-ignore
            filters_[filter] = withDefault(StringParam, "")
        } else {
            // @ts-ignore
            filters_[filter.name] = withDefault(filter.variant, filter.defaultValue)
        }
    })
    return filters_
}

export const useFilters = (filters: FilterConfig[]) => {

    const filters_ = useFiltersBuilder(filters)

    const [query, setQuery] = useQueryParams(filters_)

    const setFilter = (name: string, value: string | number | string[] | number[]) => {
        if (name === "page") {
            setQuery({ ...query, [name]: value })
        } else {
            setQuery({ ...query, [name]: value, page: 1 })
        }
    }
    return [query, setFilter]
}
