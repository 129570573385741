import { CloudDownload } from '@mui/icons-material';
import { Button, Card, Grid } from '@mui/material';
import { FC } from 'react';
import { useTracking } from 'react-tracking';
import { backgroundImage, downloadRequest, extractExtension, staticFile } from '../../services/utils';
import styles from './PosterTile.module.scss';

interface PosterTileProps {
    poster: any,
}

const PosterTile: FC<PosterTileProps> = props => {

    const { trackEvent } = useTracking()

    const { poster } = props
    const imgStyle = { backgroundImage: backgroundImage(poster.cover) }

    const handleDownloadSpec = () => {
        trackEvent({ action: "downloadPdf", data: { "poster": poster.id } })
        downloadRequest(staticFile(poster.specifications), `fabric_${poster.reference}_specs.pdf`)
    }

    const handleDownloadPhoto = () => {
        trackEvent({ action: "downloadPhoto", data: { "poster": poster.id } })
        const extension = extractExtension(poster.photo)
        const url = `fabric_${poster.reference}_specs.${extension}`
        downloadRequest(staticFile(poster.photo), url)
    }

    return (
        <Card className={styles.PosterTile}>
            <div className={styles.PosterTilePhoto} style={imgStyle}></div>

            <Grid container direction="column" justifyContent="space-between" className={styles.ContentWrapper}>

                <div className={styles.FabricType}>
                    {poster?.type === 'MESH' ? <p>{poster?.fabricType?.name}</p> : <p>{poster?.name}</p>}
                    {poster?.type === 'MESH' ? <p>{poster?.composition?.name}</p> : <p>{poster?.fabricType?.name}</p>}
                    {poster?.type === 'MESH' ? <p>{poster?.weight} gr/m²</p> : <p>&nbsp;</p>}
                    <p className={styles.Story}>{poster.story}</p>
                </div>

                <Grid container className={styles.Footer} justifyContent="space-between" alignItems="center">
                    <p className={styles.Reference}>
                        {poster.reference}
                    </p>
                    <p>
                        <Button variant="text" startIcon={<CloudDownload />} onClick={handleDownloadSpec}>
                            PDF
                        </Button>

                        <Button variant="text" startIcon={<CloudDownload />} onClick={handleDownloadPhoto}>
                            IMG
                        </Button>
                    </p>
                </Grid>


            </Grid>
        </Card>
    )
}

export default PosterTile;
