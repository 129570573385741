import * as Yup from "yup";

const getSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required().default(""),
        email: Yup.string().email().required().default(""),
        password: Yup.string().required().default(""),

    })
}

// ver E#915
export const getInitialValues = (schema: Yup.ObjectSchema<any>) => {
    return schema.cast({});
}

const formInit = () => {
    const schema = getSchema()
    const initial = getInitialValues(schema)

    Object.assign(initial, {})

    return { schema, initial }
}

export default formInit
