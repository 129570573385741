import { CircularProgress, Grid } from '@mui/material';
import { FC } from 'react';

interface LoaderProps {
    loading: boolean,
}

const Loader: FC<LoaderProps> = props => {
    const { loading } = props

    if (!loading) return null

    return (
        <Grid container justifyContent="center" style={{ marginTop: 200 }}>
            <CircularProgress />
        </Grid>
    )
}

export default Loader;
