import { Search as SearchIcon } from '@mui/icons-material';
import { FC } from 'react';
import AutoTextField from '../AutoTextField/AutoTextField';

interface SearchProps {
    search: any
    setSearch: any
}
const Search: FC<SearchProps> = props => {
    const { search, setSearch } = props

    const handle = (data: any) => {
        // @ts-ignore
        setSearch("search", data.search)
    }

    return (
        <AutoTextField
            style={{ marginLeft: "30px" }}
            name="search"
            onSave={handle}
            placeholder=" Search (reference)"
            value={search}
            interval={500}
            fullWidth={false}
            clearable={true}
            variant="standard"
            InputProps={{
                autoComplete: "off",
                autoFocus: true,
                startAdornment: <SearchIcon style={{ color: "#cccccc" }} />,
                disableUnderline: true,
            }}
        />

    )
}

export default Search;
