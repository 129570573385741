import { useTracking } from 'react-tracking';
import { useCreateOneUserEventMutation } from '../../generated/graphql';

const EventTracking = (props: any) => {

    const [create] = useCreateOneUserEventMutation()

    // @ts-ignore
    const { Track } = useTracking({}, {
        // @ts-ignore
        dispatch: data => create({ variables: { input: { userEvent: { name: data.action, data: data.data } } } })
    });

    return (
        <Track>
            {props.children}
        </Track>
    )
}

export default EventTracking;
