import Config from "../../services/config"

export const filterBuilder = (search?: string, posterType?: string, fabricType?: string[], composition?: string[], season?: string[]) => {
    const filterQuery = {}

    if (search) {
        // @ts-ignore
        filterQuery["reference"] = { iLike: `%${search}%` }
    }

    if (posterType) {
        // @ts-ignore
        filterQuery["type"] = { eq: posterType }
    }

    if (fabricType && fabricType.length) {
        // @ts-ignore
        filterQuery["fabricType"] = { id: { in: fabricType } }
    }

    if (composition && composition.length) {
        // @ts-ignore
        filterQuery["composition"] = { id: { in: composition } }
    }

    if (season && season.length) {
        // @ts-ignore
        filterQuery["season"] = { id: { in: season } }
    }

    return filterQuery
}

export const pagingBuilder = (page: number) => {
    const pageSize = Config.PAGE_SIZE
    const offset = (page - 1) * pageSize
    return { limit: pageSize, offset }
}

export const sortingBuilder = () => {
    return [{ field: "id", direction: "DESC" }]
}

export const postersQuery = (search: string, page: number, fabricType: string[], composition: string[], posterType: string, season: string[]) => {

    let variables: any = {
        filter: filterBuilder(search, posterType, fabricType, composition, season),
        sorting: sortingBuilder(),
        paging: pagingBuilder(page)
    }

    return { variables }
}
