import { Grid } from '@mui/material';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useFabricFilterForFinishingsQuery, useFabricFilterForMeshesQuery } from '../../generated/graphql';
import { useGtXs } from '../../hooks/useGtXs';
import CompositionSelect from '../CompositionSelect/CompositionSelect';
import FabricTypeSelect from '../FabricTypeSelect/FabricTypeSelect';
import FiltersDrawer from '../FiltersDrawer/FiltersDrawer';
import Search from '../Search/Search';
import SeasonSelect from '../SeasonSelect/SeasonSelect';
import SecondBar from '../SecondBar/SecondBar';

interface FiltersBarProps {
    filters: any
    setFilter: any
    count?: number
}

const FiltersBar: FC<FiltersBarProps> = props => {
    const { filters, setFilter, count } = props
    const { composition, fabricType, search, season } = filters
    const location = useLocation()
    const gtXs = useGtXs()
    const finishings = location.pathname === '/finishes'
    const filterFunc = finishings ? useFabricFilterForFinishingsQuery : useFabricFilterForMeshesQuery

    if (!gtXs) return (
        <SecondBar>
            <Search search={search} setSearch={setFilter} />
            <FiltersDrawer filters={filters} setFilter={setFilter} func={filterFunc} count={count} viewMeshes={!finishings} />
        </SecondBar>
    )

    return (
        <SecondBar>
            <Grid container justifyContent="space-between" alignItems="center" >
                <Grid item xs={2}>
                    <Search search={search} setSearch={setFilter} />
                </Grid>
                <Grid item xs={8} container justifyContent="flex-end" alignItems="center">
                    <FabricTypeSelect value={fabricType} onChange={setFilter} func={filterFunc} label={finishings ? 'Finish Type' : 'Fabric Type'} />
                    {!finishings ? <CompositionSelect value={composition} onChange={setFilter} /> : null}
                    <SeasonSelect value={season} onChange={setFilter} />
                </Grid>

            </Grid >
        </SecondBar >

    )
};

export default FiltersBar;
