import { Typography } from '@mui/material';
import { defaultInputSx } from '../../../services/config';
import InputTextField from '../../InputTextField/InputTextField';
import formStyles from '../CreateQuote.module.scss'

const CompanyInformation = () => {

    const sx = defaultInputSx()

    return (
        <div className={formStyles.FormStep}>
            <Typography variant="h3" color="primary">
                company information
            </Typography>
            <div className={formStyles.Form}>
                <div className={formStyles.FormLine}>
                    <InputTextField name="company" size="small" sx={sx} />
                    <InputTextField name="brand" size="small" sx={sx} />
                </div>
                <div className={formStyles.FormLine}>
                    <InputTextField name="address" size="small" sx={sx} />
                </div>

                <div className={formStyles.FormLine}>
                    <InputTextField name="zipCode" label="zip code" size="small" sx={sx} />
                    <InputTextField name="city" size="small" sx={sx} />
                </div>

                <div className={formStyles.FormLine}>
                    <InputTextField name="country" size="small" sx={sx} />
                </div>

            </div>
        </div >
    )
}

export default CompanyInformation
