import { Grid } from '@mui/material';
import { FC, ReactNode } from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { CircloHtmlHead } from '../HtmlHead/HtmlHead';
import LayoutProvider from '../Layout/LayoutProvider';
import Sidemenu from '../Sidemenu/Sidemenu';
import styles from './Layout.module.scss';

export interface LayoutProps {
    children: ReactNode
}

// este é o layout do circlo, temos que criar
// o layout para a selectiva
const Layout: FC<LayoutProps> = props => {

    const { children } = props

    return (
        <LayoutProvider>
            <Grid container direction="column" alignItems="space-between" className={styles.Layout}>
                <CircloHtmlHead />
                <Header />
                <Sidemenu />
                {children}
                <Footer />
            </Grid >
        </LayoutProvider>
    )
}


export default Layout;
