import Config from "./config";

/** Returns the absolute path to a file in the api */
export const staticFile = (relative: string): string => {
    return Config.MEDIA_URL + relative
}
/** returns a string that can be used to set an image as background image */
export const backgroundImage = (relative: string): string => {
    const image = staticFile(relative);
    return `url(${image})`;
}

/**
* starts download of file from the frontend
* @param url - the url of file to download
* @param filename - the name of the file
*
* @example downloadRequest(url, 'folhasala.pdf')
*/
export const downloadRequest = (url: string, filename: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function() {
        const type = xhr.getResponseHeader("content-type");
        const arrayBufferView = new Uint8Array(this.response);
        // @ts-ignore
        const blob = new Blob([arrayBufferView], { type });
        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.click();
    };
    xhr.send();
}


// https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript/680982

/** get filename extesion */
export const extractExtension = (file: string) => {
    const re = /(?:\.([^.]+))?$/;
    // @ts-ignore
    return re.exec(file)[1];
}
