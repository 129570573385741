import { useFormikContext } from 'formik';
import { FC } from 'react';
import { non_field_error } from '../../utils/errors';
import styles from './NonFieldErrors.module.scss';

interface NonFieldErrorsProps {
    message?: string
}

/**
 * verifica se existe um non field error, retorna true se encontrar
 */
const nfeExists = (errors: { [key: string]: any }) => {
    if (!errors) return false
    return Object.keys(errors).includes(non_field_error)
}

/**
 * componente para mostrar mensagens de erro que
 * não dizem respeito a um campo em particular
 * (ex: unique_together), as mensagens são geradas
 * pelo errorResponseparser, tem que estar dentro
 * do contexto de uma formik form
 */
const NonFieldErrors: FC<NonFieldErrorsProps> = props => {
    const { errors } = useFormikContext()
    // @ts-ignore
    const { message = errors[non_field_error] } = props

    if (!nfeExists(errors)) return null

    return (
        <div className={styles.NonFieldErrors}>
            {message}
        </div>
    )
}

interface NonFieldErrorsDisplayProps {
    errors: { [key: string]: string[] | string }
}

/**
 * esta versão não precisa de ter o contexto de um formik form
 */
export const NonFieldErrorsDisplay: FC<NonFieldErrorsDisplayProps> = props => {
    const { errors } = props

    if (!nfeExists(errors)) return null

    // @ts-ignore
    const { message = errors[non_field_error] } = props


    return (
        <div className={styles.NonFieldErrors}>
            {message}
        </div>
    )
}


export default NonFieldErrors
