import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Card, Grid, IconButton } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { FC, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { useGtXs } from '../../hooks/useGtXs';
import { useAuth } from '../../services/auth';
import Config from '../../services/config';
import AuthLayout from '../AuthLayout/AuthLayout';
import MobileLayout from '../MobileLayout/MobileLayout';
import styles from './Login.module.scss';

interface Credentials {
    email: string,
    password: string
}

interface PasswordVisibilityProps {
    fieldType: any
    setFieldType: any
}

const PasswordVisibility: FC<PasswordVisibilityProps> = props => {
    const { fieldType, setFieldType } = props

    const toogle = () => {
        if (fieldType === "password") {
            setFieldType("test")
        } else {
            setFieldType("password")
        }
    }

    return (
        <IconButton onClick={toogle}>
            {fieldType === "password" ? <VisibilityOff /> : <Visibility />}
        </IconButton>
    )
}

const MobileLogin: FC<any> = props => {
    const { handleSubmit, initialValues, errorMessage } = props
    const [fieldType, setFieldType] = useState<string>("password")
    return (
        <MobileLayout>
            <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {({ isSubmitting }) => (
                    <Form style={{ height: "100%" }}>
                        <Grid container direction="column" justifyContent="center" className={styles.MobileLogin}>
                            <div style={{ color: "red", marginBottom: "15px" }}>{errorMessage}</div>
                            <div className={styles.UsernameWrapper}>
                                <Field
                                    component={TextField}
                                    label="Email"
                                    name="email"
                                    required
                                    fullWidth
                                    autoFocus
                                    autoComplete="current-username"
                                />
                            </div>

                            <Grid container direction="column" alignItems="space-around" className={styles.PasswordWrapper}>
                                <div>
                                    <Field
                                        component={TextField}
                                        label="Password"
                                        name="password"
                                        type={fieldType}
                                        required
                                        fullWidth
                                        autoComplete="current-password"
                                        InputProps={{
                                            endAdornment: <PasswordVisibility fieldType={fieldType} setFieldType={setFieldType} />
                                        }}

                                    />
                                </div>
                                <Grid container justifyContent="flex-end" className={styles.ForgotPassword}>
                                    <p><Link to="/forgot-password">Forgot Password?</Link></p>
                                </Grid>
                            </Grid>

                            <Grid container direction="column" alignItems="space-around">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    login
                                </Button>
                                <Grid container justifyContent="center" className={styles.Registration}>
                                    <p>Need an account? <Link to="/registration">Sign Up</Link></p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

        </MobileLayout>
    )
}

const DesktopLogin: FC<any> = props => {
    const { handleSubmit, initialValues, errorMessage } = props
    const [fieldType, setFieldType] = useState<string>("password")
    return (
        <AuthLayout>
            <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                {({ isSubmitting }) => (
                    <Form>
                        <Card className={styles.LoginCard}>
                            <Grid container direction="column" justifyContent="space-around" style={{ height: "100%", padding: 50 }}>
                                <div style={{ color: "red", marginBottom: "15px" }}>{errorMessage}</div>
                                <div>
                                    <Field
                                        component={TextField}
                                        label="Email"
                                        name="email"
                                        required
                                        fullWidth
                                        autoFocus
                                        autoComplete="current-username"
                                    />
                                </div>

                                <Grid container direction="column" alignItems="space-around">
                                    <div>
                                        <Field
                                            component={TextField}
                                            label="Password"
                                            name="password"
                                            type={fieldType}
                                            required
                                            fullWidth
                                            autoComplete="current-password"
                                            InputProps={{
                                                endAdornment: <PasswordVisibility fieldType={fieldType} setFieldType={setFieldType} />
                                            }}
                                        />
                                    </div>
                                    <Grid container justifyContent="flex-end" className={styles.ForgotPassword}>
                                        <p><Link to="/forgot-password">Forgot Password?</Link></p>
                                    </Grid>
                                </Grid>

                                <Grid container direction="column" alignItems="space-around">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        login
                                    </Button>
                                    <Grid container justifyContent="center" className={styles.Registration}>
                                        <p>Need an account? <Link to="/registration">Sign Up</Link></p>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Card>
                    </Form>
                )}
            </Formik>

        </AuthLayout >
    );
}



const Login = () => {

    const { login } = useAuth()
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate()
    const location = useLocation()
    const initialValues: Credentials = { email: "", password: "" }
    const { trackEvent } = useTracking();
    const gtXs = useGtXs()

    const normalizeEmail = (email: string): string => {
        return email.trim().toLowerCase()
    }

    const handleSubmit = (values: Credentials) => {
        const email = normalizeEmail(values?.email)
        return login(email, values.password.trim()).then((isSuccess: boolean) => {
            if (isSuccess) {
                trackEvent({ action: "login" })
                // @ts-ignore
                const redirectTo = location?.state?.redirect
                const route = redirectTo ? redirectTo : Config.START_ROUTE
                navigate(route)
            } else {
                setErrorMessage("Could not login! Please check your credentials.")
            }
        })
    }

    if (gtXs) {
        return <DesktopLogin handleSubmit={handleSubmit} initialValues={initialValues} errorMessage={errorMessage} />
    }

    return <MobileLogin handleSubmit={handleSubmit} initialValues={initialValues} errorMessage={errorMessage} />

};

export default Login;
