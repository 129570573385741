import { Grid } from '@mui/material';
import logo from '../../assets/imgs/foursource/foursource.svg';
import styles from './FoursourceFooter.module.scss';

const FoursourceFooter = () => {
    return (
        <Grid container justifyContent="flex-end" alignItems="center" className={styles.Footer}>
            <img src={logo} className={styles.Logo} alt="supporters logos" />
        </Grid>
    )
}

export default FoursourceFooter;
