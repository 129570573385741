import { Grid } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateOneQuoteMutation } from '../../generated/graphql';
import useToast from '../../hooks/useToast';
import StepContent from '../StepContent/StepContent';
import StepControls from '../StepControls/StepControls';
import StepIndicator from '../StepIndicator/StepIndicator';
import styles from './CreateQuote.module.scss';
import getSchema from './CreateQuoteSchema';

const CreateQuote = () => {
    const [createQuote] = useCreateOneQuoteMutation()
    const [activeStep, setActiveStep] = useState<number>(0);

    const navigate = useNavigate()
    const { errorToast } = useToast()
    const stepCount = 3

    const schema = getSchema()
    const initial = schema.cast({})

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handlePrevious = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const notifyError = () => {
        errorToast("Error while creating the quote, please check the form and try again!")
    }

    const handleSubmit = async (values: FormikValues) => {
        try {
            // @ts-ignore
            const response = await createQuote({ variables: { input: { quote: { ...values } } } })
            if (response?.data?.createOneQuote.id) {
                navigate('/thanks')
            } else {
                notifyError()
            }

            return response
        } catch (e) {
            notifyError()
        }
    }

    return (
        <div style={{ height: "100%" }}>
            <Formik initialValues={initial} validationSchema={schema} onSubmit={handleSubmit}>
                {() => {
                    return (
                        <Form style={{ height: "100%" }}>
                            <Grid container alignContent="space-between" className={styles.CreateQuote}>
                                <div className={styles.Wrapper}>
                                    <StepIndicator active={activeStep} />
                                    <StepContent active={activeStep} />
                                </div>
                                <StepControls active={activeStep} count={stepCount} previous={handlePrevious} next={handleNext} />
                            </Grid>
                        </Form >
                    )
                }}
            </Formik>
        </div>
    )
}

export default CreateQuote;
