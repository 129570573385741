import { ExitToApp } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../services/auth';
import Config from "../../services/config";
import styles from './UserMenu.module.scss';

const UserMenu = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate()
    const { user, logout } = useAuth()


    if (!user) {
        return (<Button>Login</Button>)
    }

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleLogout = () => {
        setIsMenuOpen(false);
        logout().then(() =>
            navigate(Config.START_ROUTE)
        )
    };

    const handleProfileMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };


    return (
        <>
            <IconButton color="inherit" aria-label="user menu" onClick={handleProfileMenuOpen}>
                <AccountCircleIcon />


            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >

                <div className={styles.UserInfoWrapper}>
                    <p>
                        <b>{user?.name}</b>
                    </p>
                    <p>
                        <small>{user?.email}</small>
                    </p>
                </div>
                <Divider />

                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToApp fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        Logout
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default UserMenu
