import * as Yup from "yup";

const getSchema = () => {

    return Yup.object().shape({
        name: Yup.string().required().default(""),
        email: Yup.string().email().required().default(""),
        phone: Yup.string().default(""),
        jobTitle: Yup.string().required().default(""),
        company: Yup.string().required().default(""),
        brand: Yup.string().required().default(""),
        address: Yup.string().required().default(""),
        zipCode: Yup.string().required().default(""),
        city: Yup.string().required().default(""),
        country: Yup.string().required().default(""),
        requestType: Yup.array().required().min(1),
        categories: Yup.array().required().min(1),
        productGroup: Yup.array().required().min(1),
        estimatedQuantityYear: Yup.number(),
        orderQuantityColor: Yup.number(),
        specification: Yup.string().required(),
        attachments: Yup.array().default([])
    })
}


export default getSchema
