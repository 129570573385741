import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import Config from '../../services/config';
import styles from './FieldText.module.scss';

const showError = (form: any, field: any) => {
    const fieldname = field.name
    const hasError = form.errors[fieldname]
    const isActive = field.value ? true : false
    const isTouched = form.touched[fieldname]

    if (Config.FORM_VALIDATION_POLICY === "ONLY_IF_TOUCHED") {
        return (hasError && (isActive || isTouched)) ? true : false
    }

    return hasError
}

const FieldText = ({ field, form, ...props }: { field: any, form: any, props: any }) => {

    const {
        // @ts-ignore
        disabled = false, inputProps = {}
    } = props

    const formikContext = useFormikContext()
    // @ts-ignore
    const { label } = props
    const error = showError(form, field)
    const helperText = error && form.errors[field.name]
    // @ts-ignore
    const _type = props.type ? props.type : "text"
    // @ts-ignore
    const autoComplete = props.autoComplete ? props.autoComplete : "off"
    // @ts-ignore
    const autoFocus = props.autoFocus ? props.autoFocus : false

    // @ts-ignore
    const fullWidth = props.fullWidth ? props.fullWidth : false

    // @ts-ignore
    const multiline = props.multiline ? props.multiline : false

    // @ts-ignore
    const size = props.size ? props.size : "medium"

    // @ts-ignore
    const rows = props.rows ? props.rows : 1


    // @ts-ignore
    const placeholder = props.placeholder ? props.placeholder : ""


    const className = formikContext.isSubmitting ? styles.Error : styles.Normal

    // @ts-ignore
    const sx = props.sx ? props.sx : {}

    return (
        <TextField
            {...field}
            label={label}
            error={error}
            helperText={helperText}
            type={_type}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            InputProps={{ className }}
            fullWidth={fullWidth}
            disabled={disabled}
            inputProps={inputProps}
            size={size}
            placeholder={placeholder}
            rows={rows}
            sx={sx}
            multiline={multiline}
        />
    )
}

export default FieldText
