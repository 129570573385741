import { Button, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import styles from './NotFound.module.scss'

const NotFound = () => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate("/")
    }

    return (
        <Grid container direction="column" justifyContent="center" alignContent="center" className={styles.NotFound}>
            <Typography variant="h3" color="text">Page Not Found</Typography>
            <Button onClick={handleClick}>
                Home
            </Button>
        </Grid >
    )
}

export default NotFound
