import { Button, Card, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { FC, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSendResetPasswordEmailMutation } from "../../generated/graphql";
import { useGtXs } from "../../hooks/useGtXs";
import AuthLayout from "../AuthLayout/AuthLayout";
import MobileLayout from "../MobileLayout/MobileLayout";
import styles from './ForgotPassword.module.scss';

interface ForgotLayoutProps {
    children: ReactNode
    gtXs: boolean
}

const ForgotLayout: FC<ForgotLayoutProps> = props => {
    const { children, gtXs } = props
    if (gtXs) {
        return (
            <AuthLayout>
                <Card className={styles.ForgotPasswordCard}>
                    {children}
                </Card>
            </AuthLayout>
        )
    }
    return (
        <MobileLayout>
            {children}
        </MobileLayout>
    )
}


const ForgotPassword = () => {


    const [sendResetPasswordEmail] = useSendResetPasswordEmailMutation()
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate()
    const gtXs = useGtXs()

    const justify = gtXs ? 'space-around' : 'center'

    const handleSubmit = async (values: any) => {
        const response = await sendResetPasswordEmail({ variables: { email: values.email } });
        if (!response?.data?.sendResetPasswordEmail) {
            setErrorMessage("Could not send email. Please try again later.")
        } else {
            navigate("/feedback")
        }
    }

    return (
        <ForgotLayout gtXs={gtXs}>
            <Formik onSubmit={handleSubmit} initialValues={{ email: "" }}>
                {({ isSubmitting }) => (
                    <Form style={{ height: "100%" }}>
                        <Grid container direction="column" justifyContent={justify} className={styles.ForgotPasswordWrapper}>
                            <div>{errorMessage}</div>
                            <div>
                                <p>Forgot your password? Please enter your email address.
                                    You will receive a link to reset your password via email.</p>
                            </div>
                            <div>
                                <Field
                                    component={TextField}
                                    label="Email"
                                    name="email"
                                    required
                                    fullWidth
                                    autoFocus
                                    autoComplete="email"
                                />
                            </div>

                            <Grid container direction="column" alignItems="space-around">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </ForgotLayout >
    );
};

export default ForgotPassword;
