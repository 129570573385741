import { FC, ReactNode } from "react"
import Config from "../../services/config"

interface LinkToSiteProps {
    children: ReactNode
}

const LinkToSite: FC<LinkToSiteProps> = props => {
    const { children } = props
    return (
        <a href={Config.PEDROSA_SITE} target="_blank" rel="noreferrer" >
            {children}
        </a>
    )
}

export default LinkToSite
